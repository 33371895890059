<template>
	<div>
		<div class="container">
			<div>
				<el-select size="small" v-model="query.type" filterable clearable placeholder="请选择"
					class="handle-select mr10" style="width: 200px;" @change="typeChange">
					<el-option label="A号码" value="1"></el-option>
					<el-option label="X号码" value="2"></el-option>
				</el-select>
				<el-input size="small" v-model="query.tel" clearable maxlength="12" placeholder="请输入号码"
					style="width: 160px;margin-right: 10px;"></el-input>
				<el-button size="small" type="primary" :class="[theme]" class="m_l_10"
					@click="getBingding">查询</el-button>
				<el-button size="small" type="primary" :class="[theme]" class="m_l_10" @click="showBingdingVisible"
					v-if="isAdmin">AX绑定</el-button>
				<el-button size="small" type="primary" :class="[theme]" class="m_l_10" @click="showrechargeVisible"
					v-if="isAdmin">购买套餐</el-button>
			</div>
			<div class="table-box" v-if="bingDingData">
				<div class="user-info-box" style="width: 600px;">
					<div class="user-info-tit">账户信息</div>
					<div class="info-box">
						<div class="info-name"><span v-if="query.type==1">A</span><span v-if="query.type==2">X</span>号码：
						</div>
						<div class="info">{{ query.tel }}</div>
					</div>
				</div>
				<div class="user-info-box" style="width: 600px;">
					<div class="info-box">
						<div class="info-name">号码类型：</div>
						<div class="info" v-if="query.type==1">A号码</div>
						<div class="info" v-if="query.type==2">X号码</div>
					</div>
				</div>
				<div class="user-info-box" style="width: 600px;">
					<div class="info-box">
						<div class="info-name">绑定<span v-if="query.type==1">X</span><span
								v-if="query.type==2">A</span>号码：</div>
						<div class="info" v-for="item in bingDingData" :key="item">{{ item}}</div>
					</div>
				</div>
			</div>

			<el-dialog title="AX绑定" v-model="BingdingVisible" width="520px" @close="closeDialog">
				<el-form label-width="120px" :model="BingdingForm" ref="BingdingForm">
					<el-form-item label="A号码" prop="telA">
						<el-input size="small" v-model="BingdingForm.telA" placeholder="请输入A号码"></el-input>
					</el-form-item>
					<el-form-item label="X号码" prop="telX">
						<el-input size="small" v-model="BingdingForm.telX" placeholder="请输入telX号码"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getnumberInit">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>

			<el-dialog title="购买套餐" v-model="rechargeVisible" width="520px" @close="closeDialog">
				<el-form label-width="120px" :model="rechargeForm" ref="rechargeForm">
					<el-form-item label="A号码" prop="telA">
						<el-input size="small" v-model="rechargeForm.telA" placeholder="请输入A号码"></el-input>
					</el-form-item>
					<el-form-item label="套餐" prop="telX">
						<el-select size="small" v-model="rechargeForm.setMealId" filterable clearable
							placeholder="请选择套餐" class="handle-select mr10" style="width: 100%;">
							<el-option v-for="item in setMealList" :key="item.id" :label="item.minutes"
								:value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getrecharge">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>

		</div>
	</div>
</template>

<script>
	import { Bingding, numberInit, recharge, setMealListX } from '../api/setMeal.js';
	export default {
		name: 'bangd',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					tel: '', //号码
					userId: localStorage.getItem('user'),
					type: ''
				},
				bingDingData: '',
				BingdingVisible: false,
				BingdingForm: {
					telA: "",
					telX: "",
					userId: localStorage.getItem('user'),
				},
				rechargeVisible: false,
				rechargeForm: {
					telA: "",
					setMealId: "",
					userId: localStorage.getItem('user'),
				},
				setMealList: [],
				isAdmin: false,
				isAgent: false,
				role: ""
			};
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			if (this.role == 'admin') {
				this.isAdmin = true;
			}
			if (this.role == 'agent') {
				this.isAgent = true;
			}

		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getBingding() {
				this.bingDingData = '';
				Bingding(this.query).then(res => {
					if (res.code == 200) {
						// if (res.data) {
						this.bingDingData = res.data;
						// } else {
						// this.$message.error('查询错误');
						// }
					} else {
						this.$message.error(res.message);
					}
				});
			},
			typeChange(e) {
				// this.query.tel = '';
				this.bingDingData = '';
			},
			showBingdingVisible() {
				this.BingdingVisible = true;
				this.BingdingForm.telA = '';
				this.BingdingForm.telX = '';
			},
			getnumberInit() {
				numberInit(this.BingdingForm).then(res => {
					if (res.code == 200) {
						this.BingdingVisible = false;
						this.$message.success('绑定成功');
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showrechargeVisible() {
				this.rechargeForm.telA = '';
				this.getsetMealListX()
				this.rechargeVisible = true;
			},
			getsetMealListX() {
				setMealListX().then(res => {
					if (res.code == 200) {
						this.setMealList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getrecharge() {
				recharge(this.rechargeForm).then(res => {
					if (res.code == 200) {
						this.rechargeVisible = false;
						this.$message.success('购买成功');
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog() {
				this.BingdingVisible = false;
				this.BingdingForm.telA = '';
				this.BingdingForm.telX = '';
				this.rechargeVisible = false;
				this.rechargeForm.telA = '';
				this.rechargeForm.setMealId = '';

			},
		}
	};
</script>

<style scoped>
	.handle-select {
		width: 120px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.recharge-box .el-radio__input {
		display: none;
	}

	.recharge-box .el-radio.is-bordered {
		padding: 8px 14px 0 5px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: 30px;
	}

	.table-box {
		width: 1400px;
		border: 1px solid #ebeef5;
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;
		margin-top: 20px;
	}

	.user-info-box {
		/* border: 1px solid #ebeef5; */

		width: 600px;
	}

	.user-info-tit {
		background: #fafafa;
		color: #000;
		font-weight: 600;
		text-align: center;
		height: 34px;
		line-height: 34px;
		border-right: 1px solid #ebeef5;
		border-bottom: 1px solid #ebeef5;
	}

	.info-box {
		display: flex;
		flex-direction: row;
		height: 34px;
		line-height: 34px;
		border-bottom: 1px solid #ebeef5;
		border-right: 1px solid #ebeef5;
	}

	.info-name {
		background: #fafafa;
		border-right: 1px solid #ebeef5;
		width: 120px;
		padding-left: 10px;
		font-weight: 600;
	}

	.user-info-box .info-box .info {
		padding-left: 10px;
		color: #226003;
	}
</style>