<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-if="isAgent" v-model="query.companyId"
					@change="companyChange" filterable clearable v-show="isNormal" placeholder="企业"
					class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
			</div>

			<el-form label-width="120">
				<div class="form-items-wrapper">
					<el-form-item label="当前进度" v-if="customerScheduleList.length > 0">
						<el-select size="small" v-model="query.schedule" filterable clearable placeholder="请选择进度"
							class="mr10">
							<el-option v-for="item in customerScheduleList" :key="item.index" :label="item.str"
								:value="item.index"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="客户来源" v-if="customerScheduleList.length > 0">
						<el-select size="small" v-model="query.source" filterable clearable placeholder="请选择客户来源"
							class="mr10">
							<el-option v-for="item in ustomerSourceList" :key="item" :label="item"
								:value="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="跟进状态" v-if="customerScheduleList.length > 0">
						<el-select size="small" v-model="query.notUpdated" filterable clearable placeholder="请选择跟进状态"
							class=" mr10">
							<el-option label="超3天未跟" value="3"></el-option>
							<el-option label="超7天未跟" value="7"></el-option>
							<el-option label="超10天未跟" value="10"></el-option>
							<el-option label="超15天未跟" value="15"></el-option>
							<el-option label="超30天未跟" value="30"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="性别" v-if="customerScheduleList.length > 0">
						<el-select size="small" v-model="query.gender" filterable clearable placeholder="请选择性别"
							class=" mr10">
							<el-option label="男性" value="1"></el-option>
							<el-option label="女性" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="查询内容" v-if="customerScheduleList.length > 0">
						<el-select size="small" v-model="query.type" filterable clearable placeholder="请选择进度"
							class=" mr10">
							<el-option label="手机号码" value="1"></el-option>
							<el-option label="客户姓名" value="2"></el-option>
							<el-option label="客户概况" value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="输入内容" v-if="customerScheduleList.length > 0">
						<el-input size="small" v-model="query.conditions" clearable placeholder="请输入内容"></el-input>
					</el-form-item>
					<el-form-item label="创建时间" v-if="customerScheduleList.length > 0">
						<el-date-picker v-model="timeArr" type="daterange" unlink-panels clearable range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime()" size="small"
							style="width: 240px;"></el-date-picker>
					</el-form-item>
					<div v-for="(item, index) in customList" :key="index">
						<el-form-item :label="item.label" v-if="item.type == 'multiline-text'">
							<el-input size="small" v-model="item.textVal" type="text" placeholder="请输入内容"></el-input>
						</el-form-item>
						<el-form-item :label="item.label" v-if="item.type == 'date'">
							<el-date-picker size="small" v-model="item.textVal" value-format="yyyy-MM-dd"
								placeholder="选择日期" clearable></el-date-picker>
						</el-form-item>
						<el-form-item :label="item.label" v-if="item.type == 'time'">
							<el-time-select size="small" type="date" v-model="item.textVal" placeholder="选择时间"
								clearable></el-time-select>
						</el-form-item>
						<el-form-item :label="item.label" v-if="item.type == 'radio-drop-down-box'">
							<el-select v-model="item.multiSelectDropDownBox" size="small" clearable>
								<el-option :label="items" v-for="items in item.options" :key="items"
									:value="items"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="item.label" v-if="item.type == 'multi-select-drop-down-box'">
							<el-select v-model="item.multiSelectDropDownBox" size="small" multiple>
								<el-option :label="items" v-for="items in item.options" :key="items.index"
									:value="items"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="item.label" v-if="item.type == 'region'">
							<el-cascader v-model="addressSelections" :options="addressData" @change="handleAddress"
								clearable placeholder="请选择省市区" filterable />
							<el-input size="small" v-model="detail" placeholder="请输入地址"
								@change="handleChangedetail"></el-input>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
				style="margin:20px 10px 20px 0;" @click="handleSearch">查询</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-circle-plus-outline"
				style="margin:0 10px 20px 0;" @click="addData" v-if="upBtn">手动添加</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2" style="margin:0 10px 20px 0;"
				v-if="upBtn" @click="handleDownload">导出</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-delete" style="margin:0 10px 20px 0;"
				@click="getRemoveCrm2" v-show="isNormal">批量删除</el-button>

			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-download" class="handle-del ml10"
				v-if="upBtn" style="margin:0 10px 20px 0;" @click="getdownloadIntentionTemplate">
				下载模板
			</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-s-promotion"
				style="margin:0 10px 20px 0;" @click="getPublicData">领取客户</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload" @click="uploadExcel"
				v-if="upBtn" style="margin:0 10px 20px 0;">导入</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-s-fold" style="margin:0 10px 20px 0;"
				@click="getColumnSetting">
				自定义列
			</el-button>
			<div class="data-pool-operation">
				<p>
					已选
					<span>{{ multipleSelectionlen||0}}</span>
					条线索
				</p>
			</div>
			<el-row>
				<el-col :span="24">
					<el-table :data="tableData" :border='true' class="table" ref="multipleTable" v-loading="loading"
						header-cell-class-name="table-header" @row-click="showData"
						:cell-style="{ cursor: 'pointer', padding: '6px' }" :row-style="{ height: '20px' }"
						:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
						@selection-change="handleSelectionChange" style="width: 100%;" height="500">
						<el-table-column type="selection" width="55" align="center"
							v-if="customData.length>0"></el-table-column>

						<el-table-column v-for="item in customData" :key="item.value" :prop="item.value"
							:label="item.label" align="center" :resizable='true'>
							<template #default="scope" v-if="item.value=='schedule'">
								<div v-if="scope.row.scheduleIndex == '1'"
									style="color: #fff;background-color: #f56c6c;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '2'"
									style="color: #fff;background-color: #00a0ea;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '3'"
									style="color: #fff;background-color: #F39801;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '4'"
									style="color: #fff;background-color: #eb6100;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '5'"
									style="color: #fff;background-color: #8fc320;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '6'"
									style="color: #fff;background-color: #4CB8A6;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '7'"
									style="color: #fff;background-color: #63C9EE;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '8'"
									style="color: #fff;background-color: #68F25C;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '9'"
									style="color: #fff;background-color: #BBF25C;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else-if="scope.row.scheduleIndex == '10'"
									style="color: #fff;background-color: #9D5CF2;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
								<div v-else
									style="color: #fff;background-color: #000;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
							</template>
							<template #default="scope" v-if="item.value=='profile'">
								<div v-if="scope.row.journal">
									<span style="background-color: yellowgreen;padding: 4px 6px;border-radius: 4px;">
										{{ scope.row.journal[scope.row.journal.length - 1].value }}
									</span>
									<span
										style="background-color: yellowgreen;padding: 4px 6px;border-radius: 4px;margin-left: 10px;">
										{{ scope.row.journal[scope.row.journal.length - 1].label.slice(0, 10) }}
									</span>
								</div>
							</template>

						</el-table-column>

						<el-table-column label="操作" width="190" align="center" fixed="right" v-if="customData.length>0">
							<template #default="scope">
								<div style="display: inline-block;margin-right: 16px;" v-if="deviceName !== null">
									<el-button type="primary" :class="[theme]" size="mini"
										@click="handleSocketSend(scope.row)" :disabled="bodadisabled"
										v-if="scope.row.status == '未拨打'">
										拨打
									</el-button>
									<el-button type="primary" :class="[theme]" size="mini" disabled
										v-else>拨打</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>


			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 300, 500, 1000]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<div class="drawer-box" :modal-append-to-body="false" append-to-body>
			<el-drawer title="客户详情" v-model="drawer" direction="rtl" size="65%" :before-close="handleClose"
				modal="false" :with-header="false">
				<div class="details-box">
					<div class="header">
						<div class="user-box">
							<div class="user-name">
								<div v-if="customerDetails.gender == '男' || customerDetails.gender == ''"><img
										src="../assets/img/man.png" alt="" /></div>
								<div v-if="customerDetails.gender == '女'"><img src="../assets/img/girl.png" alt="" />
								</div>
								<div class="name">
									<div>客户姓名</div>
									<el-input size="small" v-model="customerDetails.customerName" type="text"
										style="width: 200px;" :disabled="drawerInputDis"
										:value="customerDetails.customerName || '无'"
										:class="[{ bordis: drawerInputDis == true }]"></el-input>
								</div>
							</div>
							<div class="user-btn">
								<i class="el-icon-refresh-right"
									style="font-size: 26px;position: relative;top: 6px;margin-left: 10px;color: #8e8e8e;"
									@click="Nodrawer"></i>
								<i class="el-icon-close"
									style="font-size: 26px;position: relative;top: 1px;margin-left: 10px;color: #8e8e8e;"
									@click="drawer = false"></i>
							</div>
						</div>
						<div class="user-info">
							<div class="list">
								<div class="name-label">电话号码</div>
								<div class="name-data">{{ customerDetails.phoneNumber }}</div>
							</div>
							<div class="list">
								<div class="name-label">来源</div>
								<div class="name-data">
									<i
										style="width: 6px;height: 6px;background-color: #3f8AFD;border-radius: 50%;display: inline-block;"></i>
									{{ customerDetails.source || '无' }}
								</div>
							</div>
							<div class="list">
								<div class="name-label">当前跟进人</div>
								<div class="name-data">{{ customerDetails.normalName }}</div>
							</div>
							<div class="list">
								<div class="name-label">添加时间</div>
								<div class="name-data">{{ customerDetails.createdAt }}</div>
							</div>
						</div>
					</div>
					<div class="nav-tab">
						<div class="ul-box">
							<div v-for="item in customerScheduleList" :key="item.index"
								:class="customerDetails.scheduleIndex >= item.index ? 'active' : ''">
								<!-- @click="tapNav(item.index)" -->
								{{ item.str }}
							</div>
						</div>
					</div>
					<div class="drawer-content">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="联系人信息" name="contactInformation">
								<div class="title">
									<span></span>
									基本信息
								</div>
								<div class="call-record-box">
									<div class="call-list">
										<div class="item-info">
											<span class="lat">客户姓名:</span>
											<el-input size="small" v-model="form.customerName" type="text"
												style="width: 200px;" :disabled="drawerInputDis"
												:value="customerDetails.customerName"
												:class="[{ bordis: drawerInputDis == true }]"></el-input>
										</div>
										<div class="item-info">
											<span class="lat">电话号码:</span>
											<el-input size="small" v-model="form.phoneNumber" type="text"
												style="width: 200px;" disabled :value="customerDetails.phoneNumber"
												:class="[{ bordis: drawerInputDis == true }]"></el-input>
										</div>
										<div class="item-info">
											<span class="lat">客户性别:</span>
											<el-select size="small" v-model="form.gender" filterable clearable
												placeholder="请选择性别" style="width: 200px;" :disabled="drawerInputDis"
												:class="[{ bordis: drawerInputDis == true }]">
												<el-option key="1" label="男" value="男"></el-option>
												<el-option key="2" label="女" value="女"></el-option>
											</el-select>
										</div>
										<div class="item-info">
											<span class="lat">添加时间:</span>
											<el-input size="small" style="width: 200px;" v-model="form.createdAt"
												type="text" disabled :value="customerDetails.createdAt"
												:class="[{ bordis: drawerInputDis == true }]"></el-input>
										</div>
										<div class="item-info">
											<span class="lat">最后跟进时间:</span>
											<el-input size="small" v-model="form.updatedAt" type="text" disabled
												style="width: 200px;" :value="customerDetails.updatedAt"
												:class="[{ bordis: drawerInputDis == true }]"></el-input>
										</div>
										<div class="item-info" v-if="isCompany || isAgent">
											<span class="lat">部门:</span>
											<el-select size="small" v-model="query.departmentId"
												@change="departmentChange" filterable clearable v-show="isNormal"
												placeholder="部门" class="handle-select mr10" style="width: 180px"
												:disabled="drawerInputDis">
												<el-option v-for="item in departments" :key="item.value"
													:label="item.label" :value="item.value"></el-option>
											</el-select>
										</div>

										<div class="item-info" v-if="isCompany || isAgent">
											<span class="lat">跟进人:</span>
											<el-select size="small" v-model="customerDetails.normalName" filterable
												clearable placeholder="请选择跟进员工" v-if="isCompany || isAgent"
												:disabled="drawerInputDis">
												<el-option v-for="item in users" :key="item.value" :label="item.label"
													:value="item.value"></el-option>
											</el-select>
											<span v-else style="font-size: 12px;color: #ff0000;">需要选择部门才能更换跟进人</span>
										</div>
										<div class="item-info" v-for="item in customerDetails.customizeVo" :key="item">
											<span class="lat">{{ item.label }}:</span>
											<el-input size="small" v-if="item.type == 'multiline-text'"
												v-model="item.textVal" type="text" placeholder="请输入内容"
												style="width: 200px;" :disabled="drawerInputDis"></el-input>
											<el-date-picker class="placeholder-style" v-if="item.type == 'date'"
												v-model="item.textVal" value-format="yyyy-MM-dd" placeholder="选择日期"
												style="width: 200px;" size="small"
												:disabled="drawerInputDis"></el-date-picker>
											<el-time-select class="placeholder-style" v-if="item.type == 'time'"
												type="date" v-model="item.textVal" placeholder="选择时间"
												style="width: 200px;" size="small"
												:disabled="drawerInputDis"></el-time-select>
											<el-radio-group v-model="item.textVal"
												v-if="item.type == 'radio-drop-down-box'" @change="radioChange">
												<el-radio v-for="items in item.options" :label="items.str"
													:key="items.index" v-model="item.textVal"
													:disabled="drawerInputDis">
													{{ items.str }}
												</el-radio>
											</el-radio-group>
											<div v-if="item.type == 'multi-select-drop-down-box'">
												<el-checkbox-group v-model="item.multiSelectDropDownBox">
													<el-checkbox v-for="items in item.options" :key="items.index"
														:label="items.str" v-model="items.checked"
														:disabled="drawerInputDis">
														{{ items.str }}
													</el-checkbox>
												</el-checkbox-group>
											</div>
											<div v-if="item.type == 'region'"
												style="display: flex;flex-direction: row;">
												<el-cascader v-model="addressSelections" :options="addressData"
													@change="handleAddress" style="width: 230px" placeholder="请选择省市区"
													filterable :disabled="drawerInputDis" />
												<el-input size="small" v-model="detail" placeholder="请输入地址"
													@change="handleChangedetail" :disabled="drawerInputDis"
													style="width: 300px;margin-left: 10px;"></el-input>
											</div>
										</div>
										<div class="item-info">
											<span class="lat">备注：</span>
											<el-input size="small" style="width: 200px;" v-model="form.remark"
												type="textarea" :disabled="drawerInputDis"
												:class="[{ bordis: drawerInputDis == true }]"
												:value="customerDetails.remark"></el-input>
										</div>
									</div>
								</div>

								<div v-if="drawerInputDis == false"
									style="margin-top: 50px;display: flex;flex-direction: row;justify-content: flex-end;">
									<el-button size="small" @click="getNoupdateIntentionCustomer">取消</el-button>
									<el-button size="small" type="primary" :class="[theme]"
										@click="updateIntentionCustomer()">确定</el-button>
								</div>
							</el-tab-pane>
							<el-tab-pane label="通话记录" name="callLog">
								<el-row>
									<el-col :span="24">
										<el-table :data="followUpRecordData" border class="table" ref="multipleTable"
											v-loading="loading" header-cell-class-name="table-header"
											@row-click="showData2" :header-cell-style="{ 'text-align': 'center' }"
											:key="Math.random()">
											<el-table-column prop="phoneNumber" label="客户姓名"
												align="center"></el-table-column>
											<el-table-column prop="phoneNumber" label="客户手机号"
												align="center"></el-table-column>
											<el-table-column prop="phoneNumber" label="员工姓名"
												align="center"></el-table-column>
											<el-table-column prop="phoneNumber" label="员工号码"
												align="center"></el-table-column>
											<el-table-column prop="callDuration" label="通话时长(秒)"
												align="center"></el-table-column>
											<el-table-column label="通话录音" prop="recordPath" width="350" align="center">
												<template #default="scope">
													<audio-player ref="audio" class="audio-box"
														:fileurl="scope.row.recordPath" :row="scope.row"></audio-player>
												</template>
											</el-table-column>
											<el-table-column prop="createdAt" label="拨打时间" align="center"
												width="190"></el-table-column>
										</el-table>
									</el-col>
								</el-row>
							</el-tab-pane>
							<el-tab-pane :label="callRecordName+'（'+journallenght+'）'" name="callRecord">
								<el-row>
									<el-col :span="16">
										<div style="position: relative;top: 0;left: 0;min-height: 500px;">
											<el-timeline :reverse="true" style="width: 400px;"
												v-if="customerDetails.journal.length>0">
												<el-timeline-item v-for="(item, index) in customerDetails.journal"
													:key="index" :timestamp="item.label">
													{{ item.value }}
												</el-timeline-item>
											</el-timeline>
										</div>
									</el-col>
								</el-row>
							</el-tab-pane>
							<el-tab-pane :label="problemRecordName+'（'+operationLoglenght+'）'" name="problemRecord">
								<el-timeline-item v-for="(item, index) in customerDetails.operationLog" :key="index"
									:timestamp="item.label">{{ item.value }}</el-timeline-item>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</el-drawer>
		</div>
		<el-dialog title="批注记录" v-model="callRecordBox" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="内容" prop="customerName">
					<el-input size="small" style="padding: 2px 6px;" v-model="journal" type="textarea"
						placeholder="请输入拜访内容" rows="5"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="hidecallRecordBox">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="GetIntentionCustomeraddJournals">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="新建公海客户" v-model="addVisible" width="750px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="客户姓名" prop="customerName"><el-input size="small"
						v-model="form.customerName"></el-input></el-form-item>
				<el-form-item label="电话号码" prop="phoneNumber"><el-input size="small"
						v-model="form.phoneNumber"></el-input></el-form-item>
				<el-form-item label="性别" prop="gender">
					<el-select size="small" v-model="form.gender" filterable clearable placeholder="请选择性别"
						style="width: 100%;" :disabled="roleDis">
						<el-option key="1" label="男" value="男"></el-option>
						<el-option key="2" label="女" value="女"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="数据来源" prop="source">
					<el-radio :label="item" v-for="(item, index) in ustomerSourceList" :key="index"
						v-model="form.source" @click.enter.prevent="getSource2(item)">
						{{ item }}
					</el-radio>
				</el-form-item>

				<div v-for="(item, index) in customList" :key="index">
					<el-form-item :label="item.label">
						<div v-if="item.type == 'multiline-text'"><el-input size="small" v-model="item.textVal"
								type="text" placeholder="请输入文本"></el-input></div>
						<div v-if="item.type == 'date'"><el-date-picker size="small" v-model="item.textVal"
								value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 100%"></el-date-picker></div>
						<div v-if="item.type == 'time'">
							<el-time-select size="small" type="date" v-model="item.textVal" placeholder="选择时间"
								style="width: 100%"></el-time-select>
						</div>
						<div v-if="item.type == 'radio-drop-down-box'">
							<el-radio :label="items" v-for="items in item.options" :key="items.index"
								v-model="item.textVal"></el-radio>
						</div>
						<div v-if="item.type == 'multi-select-drop-down-box'">
							<el-checkbox-group v-model="item.multiSelectDropDownBox">
								<el-checkbox :label="items" v-for="items in item.options"
									:key="items.index"></el-checkbox>
							</el-checkbox-group>
						</div>
						<div v-if="item.type == 'region'">
							<el-cascader v-model="addressSelections" :options="addressData" @change="handleAddress"
								placeholder="请选择省市区" filterable />
							<el-input size="small" v-model="detail" placeholder="请输入地址"
								@change="handleChangedetail"></el-input>
						</div>
					</el-form-item>
				</div>
				<el-form-item label="备注"><el-input size="small" type="textarea"
						v-model="form.remark"></el-input></el-form-item>

				<el-form-item label="部门" v-show="isNormal">
					<el-select size="small" v-model="query.departmentId" @change="departmentChange" filterable clearable
						placeholder="部门" style="width: 100%">
						<el-option v-for="item in departments" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>


				<el-form-item>
					<el-button size="small" style="margin-left: 75%;" @click="addVisible = false">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="getAddIntentionCustomer('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="释放原因" v-model="shifang" width="500px" @close="closeshifangDialog"
			:close-on-click-modal="false">
			<el-form :model="shifangbox" :rules="rules" ref="form" label-width="100px" class="import-visible">
				<el-form-item label="原因" prop="shifangVal">
					<el-radio-group v-model="shifangbox.shifangVal" @change="shifangRadioChange"
						style="border: 1px solid #EBEEF5;padding: 8px;">
						<el-radio style="width: 100%;margin-bottom: 10px;" v-for="(item, index) in shifangList"
							:key="index" :label="item.str">{{ item.str }}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span class="dialog-footer" style="display: flex;flex-direction: row;justify-content: flex-end;">
				<el-button size="small" @click="closeshifangDialog" style="width: 100px;height: 40px;">取消</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="deleteIntentionCustomers"
					style="width: 100px;height: 40px;">确定</el-button>
			</span>
		</el-dialog>

		<el-dialog v-model="transferVisible2" title="领取数据" width="450px" :close-on-click-modal="false">
			<el-form :model="form" ref="form" label-width="100px">
				<el-form-item label="跟进员工" prop="userId">
					<el-select size="small" v-model="form.userId" placeholder="请选择跟进员工" :disabled="userDis"
						style="width:100%">
						<el-option v-for="item in users" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;margin-bottom: 0;">
					<el-button size="small" @click="transferVisible2 = false">取消</el-button>
					<el-button type="primary" :class="[theme]" size="small" @click="getPublicData2">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="user-box">
			<el-dialog title="导入文件" v-model="upload" width="500px" @close="closeUpload()" :close-on-click-modal="false">
				<el-upload ref="upload" action="/api/intentionCustomer/uploadIntention" :headers="token" :limit="1"
					:on-preview="handlePreview" :on-remove="handleRemove" :on-success="uploadSuccess"
					:auto-upload="false" :data="uploadform" :before-upload="beforeAvatarUpload">
					<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
					<div class="el-upload__tip">只能上传excel文件</div>
				</el-upload>

				<el-button size="small" type="primary" :class="[theme]" style="margin-left: 80%;margin-top: 20px;"
					@click="submitUpload">确定</el-button>
			</el-dialog>

			<el-dialog v-model="filterBox" width="250px" title="重复数据">
				<div class="filter-box" style="width: 200px;height: 400px;overflow: scroll;">
					<div v-for="item in filterListData" :key="item">{{ item }}</div>
				</div>
			</el-dialog>
		</div>
		<el-dialog v-model="customVisible" title="自定义列" width="600px" @close="closCustomVisibleDialog">
			<div id="aaa-box">
				<el-table :data="customTableData" border class="table" ref="multipleTable"
					header-cell-class-name="table-header" :cell-style="{ padding: '6px' }"
					:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName" row-key="id"
					:class="{ 'not-sort': inputs }">
					<el-table-column prop="index" align="center" width="50" label="顺序"></el-table-column>
					<el-table-column prop="label" label="表头名称" align="center"></el-table-column>
					<el-table-column prop="enable" label="启用" align="center">
						<template #default="scope">
							<el-checkbox label="启用" v-model="scope.row.enable"
								@change="getcustomerEnable(scope.row)"></el-checkbox>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		download,
		intentionCustomergetDetails,
		addIntentionCustomer,
		updateIntentionCustomer,
		updateSchedule,
		getfindCallRecord,
		deleteIntentionCustomer,
		GetIntentionCustomeraddJournal,
		downloadIntentionCustomerList,
		turnaroundCustomer,
		CRMpublicData,
		getPublicData,
		removeCrm
	} from '../api/recordIndex';
	import {
		fetchDepartment,
		fetchUsersByDepartmentId,
		fetchDefaultUsers
	} from '../api/index.js';
	import {
		fetchCompanyByAgentId,
		fetchDefaultCompanies
	} from '../api/companyIndex';
	import {
		fetchAgent,
		getColumn,
		downloadIntentionTemplate
	} from '../api/agentIndex';
	import {
		getwebListCustomize,
		getCustomerSchedule,
		getCustomerSource,
		getCustomerRelease,
		getColumnSetting,
		updateColumnSetting
	} from '../api/tag.js';

	import {
		provinceAndCityData,
		regionData,
		provinceAndCityDataPlus,
		regionDataPlus,
		CodeToText,
		TextToCode
	} from 'element-china-area-data';
	import AudioPlayer from '../components/audioPlayer.vue';
	import {
		querySubAgent
	} from '../api/serialIndex.js';
	import Sortable from 'sortablejs';
	export default {
		components: {
			AudioPlayer
		},
		data() {
			return {
				customData: [],
				customVisible: false,
				customTableData: [],
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					departmentId: '',
					userId: '',
					schedule: '',
					type: '',
					conditions: '',
					normalId: '',
					gender: '',
					startTime: '',
					endTime: '',
					pageIndex: 1,
					pageSize: 30,
					source: '',
					subAgentId: ''
				},
				ressderRegionList: [],
				showForm: true,
				fileList: [],
				tableData: [],
				agents: [],
				companys: [],
				departments: [],
				users: [],
				multipleSelection: [],
				multipleSelectionlen: 0,
				delList: [],
				followUpRecordData: [], //通话记录
				returnVisitData: [], //拜访记录
				input3: '',
				combinationSelect: '',

				editVisible: false,
				addVisible: false,
				addButton: false,
				updateButton: false,
				isNormal: true,
				isNotAdmin: true,
				isAdmin: false,
				drawer: false,
				isAgent: false,
				isSubAgent: false,
				isCompany: false,
				desensitization: false,
				pageTotal: 0,
				form: {
					normalName: '',
					phoneNumber: '',
					gender: '',
					customerCompany: '',
					mailbox: '',
					customerIdentity: '',
					remark: '',
					customerName: '',
					source: '',
					userId: localStorage.getItem('user'),
					multilineText: '',
					customDate: '',
					customTime: '',
					radioDropDownBox: '',
					multiSelectDropDownBox: [],
					customize: []
				},
				uploadform: {
					userId: localStorage.getItem('user'),
					type: 2
				},
				rules: {
					phoneNumber: [{
						required: true,
						message: '请输入电话号码',
						trigger: 'change'
					}],
					normalName: [{
						required: true,
						message: '请选择跟进员工',
						trigger: 'change'
					}]
				},
				form2: {
					customerName: '',
					phoneNumber: '',
					intention: '',
					remark: ''
				},

				loading: false,
				activeName: 'contactInformation',
				customerDetails: '',
				idArr: '',
				rowIndex: '',
				lastId: '',
				nextId: '',
				prevDis: false,
				nextDis: false,
				drawerInputDis: true, //抽屉里面的input是否可以编辑
				tapnav: 1, //抽屉里的初防
				callRecordBox: false,
				scheduleKey: false,
				journal: '',
				timeArr: [],
				customList: [],
				customerScheduleList: [], //进度列表
				ustomerSourceList: [], //来源列表
				customize: {},
				shifang: false,
				shifangList: [],
				shifangbox: {
					shifangVal: ''
				},
				shifangId: '',
				// 选中数组
				ids: [],
				//地址信息
				addressData: regionData,
				addressSelections: [],
				warehouse: 'warehouse',
				province: '',
				city: '',
				town: '',
				detail: '',
				addressStr: '',

				transferVisible2: false,
				deviceName: null, //是否显示拨号
				path: process.env.BASE_URL,
				upload: false,
				token: {
					Authorization: localStorage.getItem('token'),
					UserRole: localStorage.getItem('ms_role')
				},
				filterBox: false, //导入后的弹出
				filterListData: [],
				subAgent: '',
				problemRecordName: '操作日志',
				operationLoglenght: 0,
				callRecordName: "批注记录",
				journallenght: 0,
				upBtn: true

			};
		},
		computed: {
			collapse() {
				return this.$store.state.collapse;
			}
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (this.role === 'company') {
				this.isCompany = true;
				this.query.companyId = localStorage.getItem('company');
				this.getData();
				this.getwebListCustomize();
				this.getCustomerSchedule();
				this.getCustomerSource();
			}
			if (this.role === 'normal') {
				this.isNormal = false;
				this.upBtn = false;
				this.query.companyId = localStorage.getItem('company');
				this.getData();
				this.getwebListCustomize();
				this.getCustomerSchedule();
				this.getCustomerSource();
			}

			if (this.role === 'agent') {
				this.isNotAdmin = false;
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (this.role === 'admin') {
				this.isNotAdmin = false;
				this.isAdmin = true;
				this.isAgent = true;
			}

			if (this.role === 'subAgent') {
				this.isAgent = true;
			}
			if (this.role === 'department') {
				this.upBtn = false;
				this.query.companyId = localStorage.getItem('company');
				this.getData();
				this.getwebListCustomize();
				this.getCustomerSchedule();
				this.getCustomerSource();
			}
			this.getColumn();

			this.init();
			setInterval(() => {
				this.deviceName = localStorage.getItem('deviceName');
			}, 1000);
		},
		methods: {

			closCustomVisibleDialog() {
				this.customVisible = false;
				this.tableData = [];
				this.customData = [];
				this.getColumn();
			},
			//获取表头设置
			getColumnSetting() {
				this.customVisible = true;
				this.customTableData = [];
				getColumnSetting(localStorage.getItem('user')).then(res => {
					if (res.code == 200) {
						this.customTableData = res.data;
						this.rowDrop();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			rowDrop() {
				let tbody = document.querySelector('#aaa-box .el-table__body-wrapper tbody');
				let _this = this;
				_this.so = Sortable.create(tbody, {
					group: {
						name: 'str',
						pull: true,
						put: true
					},
					animation: 150, // 动画参数
					sort: true,
					filter: '.not-sort', // 过滤.not-sort的元素
					onEnd(evt) {
						console.log(evt, 'sdddd')
						let data = {
							userId: localStorage.getItem('user'),
							value: evt.item.cells[1].innerText,
							index: evt.oldIndex + 1,
							nextIndex: evt.newIndex + 1,
						};
						updateColumnSetting(data).then(res => {
							if (res.code == 200) {
								_this.so.destroy();
								setTimeout(function() {
									_this.getColumnSetting();
									_this.$message.success(res.message);
								}, 500);
							} else {
								_this.$message.error(res.message);
							}
						});
					}
				});
			},

			getcustomerEnable(row) {
				let data = {
					userId: localStorage.getItem('user'),
					value: row.value,
					index: row.index,
					nextIndex: row.index,
					enable: row.enable
				};
				updateColumnSetting(data).then(res => {
					if (res.code == 200) {
						this.getColumnSetting();
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
						this.getColumnSetting();
					}
				});
			},



			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			handleAddress(e) {
				for (let i = 0; i < this.addressSelections.length; i++) {
					if (i == 0) {
						this.province = CodeToText[this.addressSelections[i]];
						this.addressStr = this.province;
					}
					if (i == 1) {
						this.city = CodeToText[this.addressSelections[i]];
						this.addressStr = this.addressStr + '-' + this.city;
					}
					if (i == 2) {
						this.town = CodeToText[this.addressSelections[i]];
						this.addressStr = this.addressStr + '-' + this.town;
					}
				}
			},
			handleChangedetail(e) {
				this.detail = e;
				this.addressStr = this.addressStr + '-' + e;
			},
			addData() {
				this.addVisible = true;
				this.form.normalName = '';
				this.form.phoneNumber = '';
				this.form.gender = '';
				this.form.customerCompany = '';
				this.form.mailbox = '';
				this.form.customerIdentity = '';
				this.form.remark = '';
				this.form.customerName = '';
				this.getCustomerSource();
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
				fetchDefaultUsers(localStorage.getItem('user')).then(res => {
					this.users = res.data;
				});
			},
			getwebListCustomize() {
				let data = {
					companyId: this.query.companyId
				};
				getwebListCustomize(data).then(res => {
					if (res.code == 200) {
						this.customList = res.data;
						this.customList.forEach((i, index) => {
							this.customize[index] = '';
							i.multiSelectDropDownBox = []; //后台获取的时候 就加一个地方存选中的值的  多选的
							i.textVal = ''; //后台获取的时候 就加一个地方存选中的值的  单选
							if (i.options) {
								this.customList[index].options = Array.from(i.options, ({
									str
								}) => str);
							}
						});
						console.log(this.customList, 'customListcustomListcustomList')
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getCustomerSchedule() {
				getCustomerSchedule(this.query.companyId).then(res => {
					if (res.code == 200) {
						this.customerScheduleList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getCustomerSource() {
				if (this.query.companyId) {
					getCustomerSource(this.query.companyId).then(res => {
						if (res.code == 200) {
							this.ustomerSourceList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					getCustomerSource(this.agents[0].value).then(res => {
						if (res.code == 200) {
							this.ustomerSourceList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},

			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;

				this.getwebListCustomize();
				this.getCustomerSchedule();
				this.getCustomerSource();
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
					this.getColumn();
				});
			},
			getColumn() {
				let data = {
					userId: localStorage.getItem('user'),
					companyId: this.query.companyId
				}
				getColumn(data).then(res => {
					res.data.forEach(i => {
						i.width = 180
					})
					this.customData = res.data;
					console.log(this.query, '我是导航列表')
					if (this.query.company) {
						this.getData();
					}
				});
			},
			departmentChange(departmentId) {
				let data = {
					departmentId: departmentId,
					currentUserId: localStorage.getItem('user')
				};
				fetchUsersByDepartmentId(data).then(res => {
					this.users = res.data;
					this.query.normalName = '';
					this.form.normalName = '';
					// this.CRMform.normalName = '';
				});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.multipleSelectionlen = val.length;
			},
			getRemoveCrm(id) {
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let param = {
							ids: id,
							userId: localStorage.getItem('user')
						};
						removeCrm(param).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			getRemoveCrm2() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
						.then(() => {
							let param = {
								ids: str,
								userId: localStorage.getItem('user')
							};
							removeCrm(param).then(res => {
								if (res.code == 200) {
									this.$message.success('批量删除成功');
									this.getData();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
				}

				this.multipleSelection = [];
			},
			//点击当前行
			showData(row) {
				this.activeName = 'contactInformation';
				this.getfindByRecordId(row.id);
			},
			radioChange(e) {},
			//用户详情
			getfindByRecordId(id) {
				intentionCustomergetDetails(id).then(res => {
					// this.activeName = 'contactInformation';
					this.customerDetails = res.data;
					this.addressSelections = [];
					this.detail = '';
					this.operationLoglenght = res.data.operationLog.length;
					this.journallenght = res.data.journal.length;
					if (this.desensitization == true) {
						const start = Math.max(0, Math.floor((res.data.phoneNumber.length - 4) / 2));
						const end = Math.min(res.data.phoneNumber.length, start + 4);
						res.data.phoneNumber = res.data.phoneNumber.slice(0, start) + '****' + res.data.phoneNumber
							.slice(end);
					}
					// this.form.userId= this.customerDetails.normalName;
					this.form = res.data;
					console.log(res.data, '我是详情');
					this.form.customizeVo.forEach((i, index) => {
						this.customize[index] = '';
						i.multiSelectDropDownBox = []; //后台获取的时候 就加一个地方存选中的值的  多选的
						// i.textVal = ''; //后台获取的时候 就加一个地方存选中的值的  单选
						if (i.type == 'multi-select-drop-down-box') {
							if (i.integerList.length) {
								i.options.forEach((item, indexs) => {
									i.integerList.forEach(name => {
										if (item.index2 == name) {
											i.multiSelectDropDownBox.push(item.str);
											item.checked = true;
										}
									});
								});
							}
						} else if (i.type == 'region') {
							if (i.textVal) {
								let item = i.textVal.split('-');
								for (let i = 0; i < item.length; i++) {
									console.log(TextToCode[item[0]].code, '0000000');
									if (TextToCode[item[0]]) {
										if (i == 0) {
											this.province = item[i];
											this.addressSelections.push(TextToCode[item[0]].code);
										}
									}
									console.log(TextToCode[item[0]][item[1]].code, '11111');
									if (TextToCode[item[0]][item[1]]) {
										if (i == 1) {
											this.province = item[i];
											this.addressSelections.push(TextToCode[item[0]][item[1]].code);
										}
									}
									console.log(TextToCode[item[0]][item[1]][item[2]].code, '2222');
									if (TextToCode[item[0]][item[1]][item[2]]) {
										if (i == 2) {
											this.town = item[i];
											this.addressSelections.push(TextToCode[item[0]][item[1]][item[
												2]].code);
										}
									}
									if (i == 3) {
										this.detail = item[i];
									}
								}
								console.log(this.addressSelections, '是是是');
							}
						}
					});
					this.drawerInputDis = true;
					this.drawer = true;
				});
			},
			handleClick(e) {
				this.drawerInputDis = true;
				this.callRecordBox = false;
				if (e.props.label == '通话记录') {
					this.getfindCallRecord();
				}
			},
			getfindCallRecord() {
				getfindCallRecord(this.customerDetails.id).then(res => {
					if (res.code == 200) {
						this.followUpRecordData = res.data;
					}
				});
			},
			handleChangeAudioVolume() {
				if (this.$refs.audio) this.$refs.audio.audioHuds = false;
			},
			toObject(arr) {
				this.rv = {};
				for (let i = 0; i < arr.length; ++i) this.rv[i] = arr[i];
				return this.rv;
			},
			updateIntentionCustomer() {
				this.form.customize = [];
				this.form.customizeVo.forEach((item, index) => {
					console.log(item, '我是什么');
					if (item.type == 'multi-select-drop-down-box') {
						if (item.multiSelectDropDownBox) {
							this.form.customize[index] = item.multiSelectDropDownBox.toString();
						}
					} else if (item.type == 'region') {
						this.form.customize[index] = this.addressStr + '-' + this.detail;
					} else {
						this.form.customize[index] = item.textVal;
					}
				});
				this.form.userId = localStorage.getItem('user');
				this.form.customize = this.toObject(this.form.customize); //变成对象
				this.form.web = true;
				if (this.role != 'normal') {
					if (this.form.normalName == '') {
						this.$message.error('请选择跟进人员');
						return;
					}
				} else {
					this.form.normalName = localStorage.getItem('user');
				}
				this.form.customizeVo = null;
				// this.form.companyId = this.query.companyId;

				console.log(this.form, '2222222222222222');
				updateIntentionCustomer(JSON.stringify(this.form)).then(res => {
					if (res.code == 200) {
						this.getupdateSchedule();
					}
				});
			},
			getAddIntentionCustomer() {
				this.customList.forEach((item, index) => {
					if (item.type == 'multi-select-drop-down-box') {
						if (item.multiSelectDropDownBox) {
							this.form.customize[index] = item.multiSelectDropDownBox.toString();
						}
					} else {
						if (item.type == 'date') {
							this.form.customize[index] = item.textVal;
						} else {
							this.form.customize[index] = item.textVal;
						}
					}
				});

				this.form.userId = localStorage.getItem('user');
				this.form.customize = this.toObject(this.form.customize); //变成对象
				this.form.web = true;
				if (this.role == 'normal') {
					this.form.normalName = this.users[0].value;
				} else {
					if (this.form.normalName == '') {
						this.$message.error('请选择跟进人员');
						return;
					}
				}

				addIntentionCustomer(JSON.stringify(this.form)).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.addVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog(formName) {
				this.companyDis = false;
				this.userDis = false;
				this.$refs[formName].resetFields();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}
				return y + '-' + m + '-' + d;
			},
			getTime() {
				this.timeArr.push(this.query.startTime);
				this.timeArr.push(this.query.endTime);
				this.query.startTime = this.toDateTimeStr(this.timeArr[0]);
				this.query.endTime = this.toDateTimeStr(this.timeArr[1]);
			},
			getData() {
				this.loading = true;
				this.tableData = [];
				this.expansion = [];
				this.customList.forEach(i => {
					let str = ''; // 在顶层初始化 str  
					let obj = {}; // 在顶层初始化 obj  

					if (i.type == 'multi-select-drop-down-box') {
						i.multiSelectDropDownBox.forEach(j => {
							str += j + ',';
						});
						obj.label = i.label;
						obj.value = str.slice(0, -1); // 移除最后一个逗号（如果有）  
					} else if (i.type == "radio-drop-down-box") {
						// 假设有一个 multiSelectDropDownBox 属性  
						str = i.multiSelectDropDownBox || '';
						obj.label = i.label;
						obj.value = str;

					} else if (i.type == "date") {
						if (i.textVal === null || !i.textVal) {
							obj.label = i.label;
							obj.value = '';
						} else {
							obj.label = i.label;
							obj.value = this.timestampToDateString(i.textVal);
						}
					} else if (i.textVal) {
						obj.label = i.label;
						obj.value = i.textVal;
					}

					// 在每种类型处理完后，一次性将 obj 添加到 this.expansion  
					if (Object.keys(obj).length > 0) { // 确保 obj 不是空的  
						this.expansion.push(obj);
					}

					// 重置 str 和 obj 以供下一次迭代使用  
					str = '';
					obj = {};
				});
				this.query.expansion = JSON.stringify(this.expansion);
				this.query.userId = localStorage.getItem('user');
				this.query.company = localStorage.getItem('company');
				CRMpublicData(this.query).then(res => {
					this.loading = false;
					if (res.code == 200) {
						if (res.customData == true) {
							this.desensitization = true
						}
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			timestampToDateString(timestamp) {
				const date = new Date(timestamp);
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			handleDownload() {
				this.loading = true;
				this.query.company = localStorage.getItem('company');
				this.query.type = 2;
				if (this.role === 'department' || this.role === 'normal') {
					return this.$message.error('当前角色不能导出')
				} else {
					downloadIntentionCustomerList(this.query).then(res => {

						if (res.type == 'application/json') {
							return this.$message.error(res.message)
						}
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = 'CRM报表' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
						this.loading = false;
					});
				}
			},
			getSchedule(value) {
				this.query.schedule = this.query.schedule == value ? '' : value;
				this.scheduleKey = !this.scheduleKey;
			},
			getSource(value) {
				this.query.source = this.query.source == value ? '' : value;
				this.sourceKey = !this.sourceKey;
			},
			getSource2(value) {
				this.form.source = this.form.source == value ? '' : value;
			},
			getNotUpdated(value) {
				this.query.notUpdated = this.query.notUpdated == value ? '' : value;
				this.notUpdatedKey = !this.notUpdatedKey;
			},
			getType(value) {
				this.query.type = this.query.type == value ? '' : value;
				this.typeKey = !this.typeKey;
			},
			getGender(value) {
				this.query.gender = this.query.gender == value ? '' : value;
				this.genderKey = !this.genderKey;
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			handleEdit() {
				this.activeName = 'contactInformation';
				this.drawerInputDis = false;
			},

			getNoupdateIntentionCustomer() {
				this.drawerInputDis = true;
			},

			getCustomerReleases() {
				if (this.form.companyId) {
					getCustomerRelease(this.form.companyId).then(res => {
						if (res.code == 200) {
							this.shifangList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					getCustomerRelease(localStorage.getItem('company')).then(res => {
						if (res.code == 200) {
							this.shifangList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			shifangRadioChange(e) {
				this.shifangbox.shifangVal = e;
			},
			deleteIntentionCustomers() {
				let data = {
					id: this.shifangId,
					cause: this.shifangbox.shifangVal,
					userId: localStorage.getItem('user')
				};
				deleteIntentionCustomer(data).then(res => {
					if (res.code == 200) {
						this.$message.success('释放成功');
						this.shifangId = '';
						this.shifangbox.shifangVal = '';
						this.shifang = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeshifangDialog() {
				this.shifang = false;
				this.shifangbox.shifangVal = '';
				this.shifangId = '';
			},
			// 释放操作
			handleDelete(id) {
				this.shifang = true;
				this.getCustomerReleases();
				this.shifangId = id;
			},
			handleDelete2() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					this.shifang = true;
					this.getCustomerReleases();
					this.shifangId = str;
				}
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},

			getupdateSchedule() {
				let data = {
					id: this.customerDetails.id,
					schedule: this.form.scheduleIndex
				};
				updateSchedule(data).then(res => {
					if (res.code == 200) {
						this.drawerInputDis = true;
						this.getData();
						this.getfindByRecordId(this.customerDetails.id);
						this.$message.success(res.message);
					} else {
						this.drawerInputDis = false;
						this.$message.error(res.message);
					}
				});
			},
			tapNav(e) {
				this.tapnav = e;
				this.form.scheduleIndex = e;
				this.getupdateSchedule();
			},
			showcallRecordBox() {
				this.callRecordBox = true;
			},
			hidecallRecordBox() {
				this.callRecordBox = false;
			},
			GetIntentionCustomeraddJournals() {
				let data = {
					id: this.customerDetails.id,
					journal: this.journal
				};
				GetIntentionCustomeraddJournal(data).then(res => {
					if (res.code == 200) {
						this.callRecordBox = false;
						this.journal = '';
						this.getfindByRecordId(this.customerDetails.id);
					} else {
						this.getfindByRecordId(this.customerDetails.id);
						this.$message.error(res.message);
					}
				});
			},

			getPublicData() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					this.transferVisible2 = true;
				}
			},
			getPublicData2() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					let data = {
						userId: localStorage.getItem('user'),
						ids: str,
						normalId: this.form.userId
					};
					getPublicData(data).then(res => {
						if (res.code == 200) {
							this.getData();
							this.transferVisible2 = false;
							this.$message.success(res.data);
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			// 导入弹出
			uploadExcel() {
				if (this.role === 'department' || this.role === 'normal') {
					return this.$message.error('当前角色不能导入')
				}
				this.upload = true;
			},
			closeUpload() {
				this.upload = false;
				this.$refs.upload.clearFiles();
			},

			handleRemove(file, fileList) {
				console.log(file, fileList, 'handleRemove');
			},
			handlePreview(file) {
				console.log(file, 'handlePreview');
			},
			uploadSuccess(success) {
				if (success.code == 200) {
					this.$message.success('上传成功');
				} else {
					this.$message.error(success.message);
					this.filterBox = true;
					this.filterListData = success.customData;
				}
				this.loading = false;
				this.$refs.upload.clearFiles();
				this.upload = false;
				this.getData();
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			//下载磨板
			getdownloadIntentionTemplate() {
				if (this.query.companyId) {
					let data = {
						companyId: this.query.companyId,
					};
					downloadIntentionTemplate(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '公海客户模板' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				} else {
					let data = {
						companyId: localStorage.getItem('company')
					}
					downloadIntentionTemplate(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '公海客户模板' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				}
			},
		}
	};
</script>

<style scoped>
	.handle-select {
		width: 120px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 7px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;
		display: inline-block;
	}

	.user-box .el-upload {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
	}

	.drawer-box {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.drawer-box .header {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.drawer-box .header .user-box {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.drawer-box .header .user-box .user-name {
		display: flex;
		flex-direction: row;
	}

	.drawer-box .header .user-box .user-name img {
		width: 50px;
		height: 50px;
		margin-right: 20px;
	}

	.drawer-box .header .user-box .user-name .name div {
		color: #000;
		font-weight: bold;
	}

	.drawer-box .header .user-box .user-name .name .el-input.is-disabled .el-input__inner {
		color: #746f69;
		font-weight: bold;
	}

	.drawer-box .header .user-info {
		display: flex;
		flex-direction: row;
		margin: 10px 0;
	}

	.drawer-box .header .user-info .list {
		margin-right: 100px;
	}

	.drawer-box .header .user-info .list .name-label {
		color: #000;
		font-weight: 600;
	}

	.drawer-box .header .user-info .list .name-data {
		color: #000;
	}

	.drawer-box .nav-tab {
		border-top: 10px solid #f1f2f6;
		border-bottom: 10px solid #f1f2f6;
	}

	.drawer-box .nav-tab .ul-box {
		display: flex;
		flex-direction: row;

		width: 70%;
		margin: 20px 0;
		border-radius: 20px;
	}

	.drawer-box .nav-tab .ul-box div {
		height: 40px;
		text-align: center;
		line-height: 40px;
		width: 25%;
		margin-right: -10px;
		background-color: #f1f2f6;
		cursor: pointer;
	}

	.drawer-box .nav-tab .ul-box div:first-child {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		/* clip-path: circle(100% at 30% 10%); */
	}

	.drawer-box .nav-tab .ul-box div:last-child {
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		/* clip-path: circle(40% 0%, 40% 20%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 20%,100% at 30% 10%); */
	}

	.drawer-box .nav-tab .ul-box .active {
		border: 2px solid #28b87d;
		background-color: #fff;
		color: #28b87d;
		height: 36px;
		line-height: 36px;
		margin-right: 1px;
	}

	.drawer-box .drawer-box .drawer-content .title {
		margin-bottom: 6px;
		font-weight: 600;
	}

	.drawer-box .add-journal {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: #3f8afd;
		color: #fff;
		font-size: 30px;
		position: absolute;
		bottom: 30px;
		left: 30px;
		text-align: center;
		cursor: pointer;
	}

	.drawer-box .drawer-content .title span {
		width: 4px;
		height: 14px;
		position: relative;
		top: 2px;
		display: inline-block;
		background-color: #408bfe;
	}

	.drawer-box .drawer-content .call-record-box {
		display: flex;
		flex-direction: row;
	}

	.drawer-box .drawer-content .call-record-box .call-list .item-info {
		width: 50%;
		float: left;
		margin-bottom: 6px;
		line-height: 40px;
		min-height: 45px;
	}

	.drawer-box .drawer-content .call-record-box .call-list .item-info .el-input.is-disabled .el-input__inner {
		color: #373635;
	}

	.drawer-box .drawer-content .call-record-box .call-list .item-info .lat {
		display: inline-block;
		width: 100px;
		font-weight: 500;
		color: #000;
		line-height: 40px;
		float: left;
	}

	/* radio单选 变成正方形 */
	::v-deep .el-radio__inner {
		border-radius: 2px;
	}

	::v-deep .el-radio__input.is-checked .el-radio__inner::after {
		content: '';
		width: 10px;
		height: 5px;
		border: 1px solid #c0c4cc;
		border-top: transparent;
		border-right: transparent;
		text-align: center;
		display: block;
		position: absolute;
		top: 2px;
		left: 0px;
		vertical-align: middle;
		transform: rotate(-45deg);
		border-radius: 0px;
		background: none;
	}

	::v-deep .el-icon-time,
	::v-deep .el-icon-date {
		display: none;
	}

	.icon-style {
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 12px;
		color: #666;
		cursor: pointer;
		margin: 20px 10px;
		height: 40px;
		line-height: 40px;
	}

	.icon-style:hover {
		background-color: #e5e9f0;
	}

	.icon-style i {
		font-size: 16px;
		position: relative;
		height: 40px;
		line-height: 40px;
		left: -6px;
	}

	.query-list .el-form-item {
		padding: 6px 20px !important;
		margin-bottom: 0 !important;
	}

	.query-list .el-form-item:hover {
		background-color: #e5e9f0;
		cursor: pointer;
	}

	.loyo-folding-btn:hover {
		border-color: #2c9dfc;
	}

	.loyo-folding-btn {
		position: relative;
		color: #2c9dfc;
		height: 20px;
		text-align: center;
		border-top: 1px solid #e5e9f0;
		margin-top: 15px;
		cursor: pointer;
	}

	.loyo-folding-btn a {
		position: absolute;
		bottom: -1px;
		border-bottom: 1px solid #ddd;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-top: 3px solid #ddd;
		border-top-color: #fff;
		width: 60px;
		height: 20px;
		line-height: 20px;
	}

	.loyo-folding-btn a i {
		font-size: 18px;
		color: #ddd;
	}

	.loyo-folding-btn:hover i {
		color: #2c9dfc;
	}

	.loyo-folding-btn:hover a {
		/* border: 1px solid #2c9dfc; */
		border-bottom: 1px solid #2c9dfc;
		border-left: 1px solid #2c9dfc;
		border-right: 1px solid #2c9dfc;
		border-top: 3px solid #2c9dfc;
		border-top-color: #fff;
	}

	.custom-data {
		display: inline-block;
		margin-top: 10px;
		margin-right: 10px;

	}

	.custom-data .label-name {
		width: 90px;
		line-height: 35px;
		font-weight: 500;
		margin-right: 10px;

	}

	.custom-box {
		display: flex;
		flex-direction: row;
		line-height: 35px;
		margin-left: 10px;
	}

	::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
		cursor: move !important;
	}

	.form-items-wrapper {
		display: flex;
		flex-wrap: wrap;
		/* 允许子元素换行 */
		justify-content: flex-start;
		/* 水平对齐方式（可选） */
		align-items: center;

	}

	.form-items-wrapper .el-form-item {
		/* 根据需要调整 el-form-item 的样式 */
		flex: 0 0 auto;
		/* 不放大也不缩小，但会占据空间 */
		margin: 5px 10px 5px 0;
		display: flex;
		flex-direction: row;
		/* 如果使用 gap 属性，则不需要这个 */
	}

	.form-items-wrapper .el-form-item__label {
		/* 调整标签样式（可选） */
	}

	.data-pool-operation {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #f3f3f3;
		padding: 10px 20px;
	}
</style>
<style type="text/css">
	.el-drawer {
		/* height: calc(100% - 165px) !important; */
		padding: 16px !important;
		font-size: 12px !important;
		line-height: 1.5 !important;
		word-wrap: break-word !important;
		position: absolute !important;
		/* top: 165px !important; */
		overflow: auto !important;
	}

	.el-checkbox__input.is-disabled+span.el-checkbox__label,
	.el-radio__input.is-disabled+span.el-radio__label,
	.el-textarea.is-disabled .el-textarea__inner {
		color: #746f69 !important
	}

	.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after,
	.el-radio__input.is-checked .el-radio__inner::after {
		border-color: #746f69 !important
	}

	.input-with-select .el-input {
		width: 130px;
	}

	.input-with-select .el-input-group__prepend {
		background-color: #fff;

	}

	.drawer-box .bordis {
		border: none;
		background-color: #fff;
	}

	.drawer-box .el-input.is-disabled .el-input__inner {
		border: none;
		padding: 0;
		color: #746f69;
		background-color: #fff;
	}

	.user-box .el-upload {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
	}
</style>