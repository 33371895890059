<template>
	<div>
		<div class="container">
			<div class="handle-box" style="display: flex;flex-direction: row;">
				<div v-if="price" style="font-size: 22px;margin-right: 16px;">代理积分消耗:每个查询
					{{price}}元
				</div>
				<el-button size="small" type="primary" :class="[theme]" @click="showDialog">设置价格</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="addDialog">添加方案</el-button>
			</div>
			<el-row>
				<el-col :span="24">
					<div id="ai-tab">
						<el-table :data="tableData" border class="table AI-tab-box" ref="multipleTable"
							header-cell-class-name="table-header" :cell-style="{ cursor: 'pointer', padding: '6px' }"
							:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
							:row-class-name="tableRowClassName" highlight-current-row @row-click="handleRowClick">
							<el-table-column prop="quantity" label="购买次数" align="center"></el-table-column>
							<el-table-column prop="gift" label="赠送次数" align="center"></el-table-column>

							<el-table-column prop="dispose" label="操作" align="center">
								<template #default="scope">
									<el-button size="small" type="danger" icon="el-icon-delete"
										@click.stop="deleteOptions(scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-col>
			</el-row>


			<el-dialog title="设置标价价格" v-model="setagentMarkerPriceVisible" width="550px" @close="closeDialog()"
				:close-on-click-modal="false">
				<el-form :model="setagentMarkerPriceForm" ref="setagentMarkerPriceForm" label-width="100px">

					<el-form-item label="价格" prop="price">
						<el-input size="small" v-model="setagentMarkerPriceForm.price" placeholder="请输入价格"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="agentMarkerPrice">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="添加方案" v-model="addVisible" width="550px" @close="closeDialog()"
				:close-on-click-modal="false">
				<el-form :model="addForm" ref="addForm" label-width="100px">
					<el-form-item label="购买次数" prop="quantity">
						<el-input size="small" v-model="addForm.quantity" placeholder="请输入次数"></el-input>
					</el-form-item>
					<el-form-item label="赠送次数" prop="gift">
						<el-input size="small" v-model="addForm.gift" placeholder="请输入次数"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]"
							@click="getagentMarkerOption">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		getAgentMarkerPrice,
		setagentMarkerPrice,
		agentMarkerOption,
		agentOptionList,
		deleteOption
	} from '../api/index.js';
	export default {
		name: 'record',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				tableData: [],
				price: null,
				setagentMarkerPriceVisible: false,
				addVisible: false,
				addForm: {
					quantity: '',
					gift: '',
				},
				setagentMarkerPriceForm: {
					price: ''
				}
			};
		},

		created() {
			this.getAgentMarkerPrices();
			this.getagentOptionList();
		},

		methods: {
			deleteOptions(row) {
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						//删除
						let data = {
							id: row.id,
							userId: localStorage.getItem('user')
						};
						deleteOption(data).then(res => {
							if (res.code == 200) {
								this.$message.success(res.message);
								this.getagentOptionList();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			getagentOptionList() {
				agentOptionList(localStorage.getItem('user')).then(res => {
					if (res.code == 200) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			addDialog() {
				this.addVisible = true;
				this.addForm.quantity = "";
				this.addForm.gift = "";
			},
			getagentMarkerOption() {
				let data = {
					userId: localStorage.getItem('user'),
					quantity: this.addForm.quantity,
					gift: this.addForm.gift,
				}
				agentMarkerOption(data).then(res => {
					if (res.code == 200) {
						this.addVisible = false;
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getAgentMarkerPrices() {
				getAgentMarkerPrice().then(res => {
					if (res.code == 200) {
						this.price = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog() {
				this.setagentMarkerPriceVisible = false;
				this.addVisible = false;
			},
			showDialog() {
				this.setagentMarkerPriceVisible = true;
			},
			agentMarkerPrice() {
				let data = {
					userId: localStorage.getItem('user'),
					price: this.setagentMarkerPriceForm.price,
				}
				setagentMarkerPrice(data).then(res => {
					if (res.code == 200) {
						this.setagentMarkerPriceVisible = false;
						this.getAgentMarkerPrices();
						this.getagentOptionList();
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
				});
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.mr10 {
		margin-right: 10px;
	}
</style>