<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phoneNumber" clearable maxlength="12" placeholder="电话号码" class="handle-input mr10"></el-input>
				<el-select size="small" v-model="query.status" filterable clearable placeholder="状态" class="handle-select mr10">
					<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload" @click="importForm">导入</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-delete" @click="handleDelete">批量删除</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-delete" @click="fileDeletes">文件删除</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2  el-icon--right" @click="exportData">导出</el-button>
				<a :href="`${path}woniunumberPool.xlsx`" class="down m_l_10" download="蜗牛号码池模板.xlsx" :class="[theme]">
					<i class="el-icon-download"></i>
					下载模板
				</a>
			</div>
			<div class="red" style="margin-bottom: 20px;">*注意单个删除和批量删除会删除系统对应员工</div>
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }"
				@selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="电话号码" align="center"></el-table-column>
				<el-table-column prop="status" label="状态" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;" @click="handleDelete2(scope.$index, scope.row.phoneNumber)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[100, 1000, 2000, 5000]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>

		<div class="customer">
			<el-dialog title="文件导入" v-model="upload" width="500px" @close="closeUpload()" :close-on-click-modal="false">
				<el-upload
					ref="upload"
					action="/api/numberPool/snailsUpload"
					:headers="token"
					:limit="1"
					:on-success="uploadSuccess"
					:on-preview="handlePreview"
					:on-remove="handleRemove"
					:auto-upload="false"
					:data="uploadData2"
				>
					<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
					<div class="el-upload__tip">只能导入excel文件</div>
				</el-upload>
				<el-button size="small" style="margin: 10px 0 0 80%;" type="primary" :class="[theme]" v-loading.fullscreen.lock="loading" @click="submitUpload">确定上传</el-button>
			</el-dialog>
		</div>
		<div class="customer">
			<el-dialog title="文件删除" v-model="upload2" width="500px" @close="closeUpload2()" :close-on-click-modal="false">
				<el-upload
					ref="upload2"
					action="/api/numberPool/snailsDeleteUpload"
					:headers="token"
					:limit="1"
					:on-success="uploadSuccess2"
					:on-preview="handlePreview2"
					:on-remove="handleRemove2"
					:auto-upload="false"
					:data="uploadData"
				>
					<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
					<div class="el-upload__tip">只能删除excel文件</div>
				</el-upload>
				<el-button size="small" style="margin: 10px 0 0 80%;" type="primary" :class="[theme]" v-loading.fullscreen.lock="loading" @click="submitUpload2">
					确定上传
				</el-button>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { delAgent, updateAgent, fetchAgentById, fetchAgent } from '../api/agentIndex.js';
import { downloadSnails } from '../api/reportNumber.js';
import { fetchDefaultCompanies, fetchCompanyByAgentId, getCity, getProvince, numberList, numberPoolEdit, snailsDeletes, getSnailsList } from '../api/companyIndex.js';
import { getCardType } from '../api/cardType.js';
import { fetchDepartment } from '../api/index.js';
export default {
	name: 'agent',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				status: '',
				phoneNumber: '',
				pageIndex: 1,
				pageSize: 100
			},
			tableData: [],
			multipleSelection: [],
			delList: [],
			pageTotal: 0,
			path: process.env.BASE_URL,
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') },
			upload: false,
			upload2: false,
			isAdmin: false,
			str: '', //多选
			statusList: [
				{
					label: '单停数据',
					value: 1
				},
				{
					label: '冻结',
					value: 2
				},
				{
					label: '欠费停机',
					value: 3
				},
				{
					label: '强停',
					value: 4
				},
				{
					label: '死锁',
					value: 5
				},
				{
					label: '限制返档',
					value: 6
				},
				{
					label: '新死锁',
					value: 7
				},
				{
					label: '预配死卡未激活',
					value: 8
				},
				{
					label: '暂停',
					value: 9
				},
				{
					label: '正常在用',
					value: 10
				},
				{
					label: '正式拆机',
					value: 11
				}
			],
			uploadData: {
				isDelete: '',
				currentUserId: localStorage.getItem('user')
			},
			uploadData2: {
				currentUserId: localStorage.getItem('user')
			}
		};
	},
	created() {
		let role = localStorage.getItem('ms_role');
		if (role === 'normal') {
			this.isNormal = false;
		}
		if (role === 'admin') {
			this.isAdmin = true;
		}
		if (role === 'agent') {
			this.isAgent = true;
		}

		this.init();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			this.numberLists();
		},
		numberLists() {
			let data = {
				status: this.query.status,
				phoneNumber: this.query.phoneNumber.replaceAll(' ', ''),
				pageSize: this.query.pageSize,
				pageIndex: this.query.pageIndex
			};
			getSnailsList(data).then(res => {
				this.tableData = res.data.list;
				this.pageTotal = res.data.total;
			});
		},
		//代理商信息
		fetchAgents() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
				// this.query.agentId = this.agents[0].value;
				// this.fetchCompanyByAgentIds(this.query.agentId);
			});
		},
		//部门
		fetchDepartments(companyId) {
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
			});
		},
		//切换企业
		companyIdChange(companyId) {
			this.fetchDepartments(companyId);
		},
		//切换代理商
		agentChange(agentId) {
			this.query.agentId = agentId;
			if (agentId) {
				let data = {
					subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
					agentId: agentId
				};
				fetchCompanyByAgentId(data).then(res => {
					this.companys = res.data;
				});
			}
		}, //切换城市
		provinceListChange(e) {
			let data = {
				code: e
			};
			getCity(data).then(res => {
				this.cityList = res.data;
			});
		},
		//公司
		fetchDefaultCompanies() {
			let data = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data).then(res => {
				this.companys = res.data;
			});
		},
		//品牌
		getCardTypes() {
			getCardType().then(res => {
				if (res.code == 200) {
					this.cardTypeData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},

		//省份
		getProvinces() {
			getProvince().then(res => {
				if (res.code == 200) {
					this.provinceList = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		//导入
		importForm() {
			this.upload = true;
		},
		//导入上传
		uploadSuccess(success) {
			setTimeout(() => {
				this.loading = false;
				this.$refs.upload.clearFiles();
				this.upload = false;
			}, 2000);
			if (success.code == 200) {
				this.$message.success('上传成功');
				this.init();
			} else {
				// this.open(success.message);
				this.$message.error(success.message);
			}
		},
		closeUpload() {
			this.$refs.upload.clearFiles();
		},
		submitUpload() {
			if (this.$refs.upload.uploadFiles[0]) {
				this.loading = true;
				this.$refs.upload.submit();
			} else {
				this.$message.error('请选择上传文件');
			}
		},
		fileDeletes() {
			this.upload2 = true;
		},
		submitUpload2() {
			if (this.$refs.upload2.uploadFiles[0]) {
				this.$confirm('是否要删除此号码对应的报备号码？', '提示', {
					type: 'warning',
					confirmButtonText: '删除',
					cancelButtonText: '不删除',
					closeOnClickModal: false
				})
					.then(() => {
						this.uploadData.isDelete = true;
						this.loading = true;
						this.$refs.upload2.submit();
					})
					.catch(() => {
						this.uploadData.isDelete = false;
						this.loading = true;
						this.$refs.upload2.submit();
					});
			} else {
				this.$message.error('请选择上传文件');
			}
		},
		uploadSuccess2(success) {
			setTimeout(() => {
				this.loading = false;
				this.$refs.upload2.clearFiles();
				this.upload2 = false;
			}, 2000);
			if (success.code == 200) {
				this.$message.success('删除成功');
				this.init();
			} else {
				// this.open(success.message);
				this.$message.error(success.message);
			}
		},
		closeUpload2() {
			this.$refs.upload2.clearFiles();
		},
		handleRemove2(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview2(file) {
			console.log(file);
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		showForm() {
			this.editVisible = true;
			this.ids = this.str;
		},

		// 编辑操作
		handleEdit(index, id) {
			// this.idx = index;
			this.ids = id;
			this.editVisible = true;

			// 获取代理详情
			// fetchAgentById(id).then(res => {
			// 	this.form = res.data;
			// });
		},

		updateAgent(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true;
					this.form.ids = this.ids;
					this.form.userId = localStorage.getItem('user');
					numberPoolEdit(this.form).then(res => {
						if (res.code == 200) {
							this.$message.success('修改成功');
							this.numberLists();
						} else {
							this.$message.error(res.message);
						}
						this.editVisible = false;
					});
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.numberLists();
		},
		// 多选操作
		handleSelectionChange(val) {
			this.delList = [];
			this.str = [];
			this.multipleSelection = val;
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.str += this.multipleSelection[i].phoneNumber + ',';
			}
		},
		exportData() {
			this.loading = true;
			downloadSnails().then(res => {
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				let fileName = '蜗牛号码池' + '.xlsx';
				downloadElement.download = fileName;
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
				this.loading = false;
			});
		},
		// 删除操作
		handleDelete() {
			if (this.str === '') {
				this.$message.error(`请选择要删除的数据`);
				return;
			} else {
				this.$confirm('是否要删除此号码对应的报备号码？', '提示', {
					type: 'warning',
					confirmButtonText: '删除',
					cancelButtonText: '不删除',
					closeOnClickModal: false
				})
					.then(() => {
						// 二次确认删除
						this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
							.then(() => {
								let param = {
									ids: this.str,
									isDelete: true,
									currentUserId: localStorage.getItem('user')
								};
								snailsDeletes(param).then(res => {
									if (res.code == 200) {
										this.$message.success('删除成功');
										this.numberLists();
									} else {
										this.$message.error(res.message);
									}
								});
							})
							.catch(() => {});
						this.multipleSelection = [];
					})
					.catch(() => {
						// 二次确认删除
						this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
							.then(() => {
								let param = {
									ids: this.str,
									isDelete: false,
									currentUserId: localStorage.getItem('user')
								};
								snailsDeletes(param).then(res => {
									if (res.code == 200) {
										this.$message.success('删除成功');
										this.numberLists();
									} else {
										this.$message.error(res.message);
									}
								});
							})
							.catch(() => {});
						this.multipleSelection = [];
					});
			}
		},
		// 删除操作
		handleDelete2(index, id) {
			this.str = id;
			this.$confirm('是否要删除此号码对应的报备号码？', '提示', {
				type: 'warning',
				confirmButtonText: '删除',
				cancelButtonText: '不删除',
				closeOnClickModal: false
			})
				.then(() => {
					// 二次确认删除
					this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
						.then(() => {
							let param = {
								ids: this.str,
								isDelete: true,
								currentUserId: localStorage.getItem('user')
							};
							snailsDeletes(param).then(res => {
								if (res.code == 200) {
									this.$message.success('删除成功');
									this.numberLists();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
					this.multipleSelection = [];
				})
				.catch(() => {
					// 二次确认删除
					this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
						.then(() => {
							let param = {
								ids: this.str,
								isDelete: false,
								currentUserId: localStorage.getItem('user')
							};
							snailsDeletes(param).then(res => {
								if (res.code == 200) {
									this.$message.success('删除成功');
									this.numberLists();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
					this.multipleSelection = [];
				});
		},

		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.init();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.init();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		closeDialog(formName) {
			this.editVisible = false;
			(this.form = {
				companyId: '',
				agentId: '',
				addOrDelete: 1
			}),
				this.resetForm(formName);
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 12px;
	border-radius: 4px;
	margin-left: 10px;
	display: inline-block;
}
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.customer .el-upload {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	width: 100% !important;
	line-height: 50px !important;
}
.customer .btn {
	background-color: #4f7afd;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: none;
	margin-left: 80%;
}
</style>
<style>
.customer .el-upload {
	width: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
}
.customer .btn {
	background-color: #4f7afd;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: none;
	margin-left: 80%;
}
</style>
