<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phoneNumber" clearable maxlength="12" placeholder="电话号码" class="handle-input mr10"></el-input>
				<el-date-picker
					v-show="showForm"
					v-model="timeArr"
					type="daterange"
					style="width: 260px;margin-right: 10px;"
					unlink-panels
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="getTime()"
					size="small"
				></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload" @click="importForm">文件上传添加</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-delete" @click="handleDelete">批量删除</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-plus" @click="fileDeletes">添加</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-download" @click="exportData">导出</el-button>
				<a :href="`${path}prestore.xls`" class="down m_l_10" download="预存款模板.xls" :class="[theme]">
					<i class="el-icon-download"></i>
					下载模板
				</a>
			</div>
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }"
				@selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="电话号码" align="center"></el-table-column>
				<el-table-column prop="amount" label="预存金额" align="center"></el-table-column>
				<el-table-column prop="createdAt" label="导入时间" align="center"></el-table-column>
				<el-table-column prop="remark" label="备注" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit" @click="handleedits(scope.row)">修改</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;" @click="handleDelete2(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[100, 1000, 2000, 5000]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>

		<div class="customer">
			<el-dialog title="文件导入" v-model="upload" width="500px" @close="closeUpload()" :close-on-click-modal="false">
				<el-upload
					ref="upload"
					action="/api/numberPool/snailAdd"
					:headers="token"
					:limit="1"
					:on-success="uploadSuccess"
					:on-preview="handlePreview"
					:on-remove="handleRemove"
					:auto-upload="false"
					:data="uploadData2"
				>
					<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
					<div class="el-upload__tip">只能导入excel文件</div>
				</el-upload>
				<el-button size="small" style="margin: 10px 0 0 80%;" type="primary" :class="[theme]" v-loading.fullscreen.lock="loading" @click="submitUpload">确定上传</el-button>
			</el-dialog>
		</div>
		<div class="customer">
			<el-dialog title="添加" v-model="upload2" width="500px" @close="closeUpload2()" :close-on-click-modal="false">
				<div style="margin-bottom: 6px;">
					<p>示例</p>
					<p>17600131812 10</p>
					<p>17600131813 20</p>
				</div>
				<el-form-item><el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码空格加充值金额" v-model="phoneNumber"></el-input></el-form-item>
				<el-button size="small" style="margin: 10px 0 0 80%;" type="primary" :class="[theme]" @click="adddata">确定</el-button>
			</el-dialog>
		</div>
		<el-dialog title="修改" v-model="edit" width="500px" @close="closeedit()" :close-on-click-modal="false">
			<el-form-item><el-input size="small" placeholder="备注" v-model="remark"></el-input></el-form-item>
			<el-button size="small" style="margin: 10px 0 0 80%;" type="primary" :class="[theme]" @click="editsub">确定</el-button>
		</el-dialog>
	</div>
</template>

<script>
import { delAgent, updateAgent, fetchAgentById, fetchAgent } from '../api/agentIndex.js';
import { downloadSnailsprestore, snailList, snailManualAddition, snailUpdate, snailDelete } from '../api/reportNumber.js';
import { fetchDefaultCompanies, fetchCompanyByAgentId, getCity, getProvince, numberList, numberPoolEdit, getSnailsList } from '../api/companyIndex.js';
import { getCardType } from '../api/cardType.js';
import { fetchDepartment } from '../api/index.js';
export default {
	name: 'agent',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				status: '',
				phoneNumber: '',
				pageIndex: 1,
				pageSize: 100
			},
			tableData: [],
			multipleSelection: [],
			delList: [],
			pageTotal: 0,
			path: process.env.BASE_URL,
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') },
			upload: false,
			upload2: false,
			edit: false,
			isAdmin: false,
			str: '', //多选

			uploadData: {
				isDelete: '',
				currentUserId: localStorage.getItem('user')
			},
			uploadData2: {},
			phoneNumber: '',
			remark: '',
			editId: '',
			timeArr: [],
			showForm: true
		};
	},
	created() {
		let role = localStorage.getItem('ms_role');
		if (role === 'normal') {
			this.isNormal = false;
		}
		if (role === 'admin') {
			this.isAdmin = true;
		}
		if (role === 'agent') {
			this.isAgent = true;
		}
		// this.getTime();
		this.getSnailList();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},

		getSnailList() {
			if (this.timeArr[0]) {
				this.query.startTime = this.toDateTimeStr(this.timeArr[0]);
				this.query.endTime = this.toDateTimeStr(this.timeArr[1]);
			}
			let data = {
				phoneNumber: this.query.phoneNumber,
				pageSize: this.query.pageSize,
				pageIndex: this.query.pageIndex,
				endTime: this.query.endTime,
				startTime: this.query.startTime
			};
			snailList(data).then(res => {
				this.tableData = res.data.list;
				this.pageTotal = res.data.total;
			});
		},
		//代理商信息
		fetchAgents() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
				// this.query.agentId = this.agents[0].value;
				// this.fetchCompanyByAgentIds(this.query.agentId);
			});
		},
		//部门
		fetchDepartments(companyId) {
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
			});
		},
		//切换企业
		companyIdChange(companyId) {
			this.fetchDepartments(companyId);
		},
		//切换代理商
		agentChange(agentId) {
			this.query.agentId = agentId;
			if (agentId) {
				let data = {
					subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
					agentId: agentId
				};
				fetchCompanyByAgentId(data).then(res => {
					this.companys = res.data;
				});
			}
		}, //切换城市
		provinceListChange(e) {
			let data = {
				code: e
			};
			getCity(data).then(res => {
				this.cityList = res.data;
			});
		},
		//公司
		fetchDefaultCompanies() {
			let data = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data).then(res => {
				this.companys = res.data;
			});
		},
		//品牌
		getCardTypes() {
			getCardType().then(res => {
				if (res.code == 200) {
					this.cardTypeData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},

		//省份
		getProvinces() {
			getProvince().then(res => {
				if (res.code == 200) {
					this.provinceList = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		getTime() {
			let startTime = new Date(new Date());
			let endTime = new Date();
			this.timeArr.push(startTime);
			this.timeArr.push(endTime);
		},
		toDateTimeStr(date) {
			let y = date.getFullYear();
			let m = date.getMonth() + 1;
			let d = date.getDate();
			if (m < 10) {
				m = '0' + m;
			}
			if (d < 10) {
				d = '0' + d;
			}

			return y + '-' + m + '-' + d;
		},
		//导入
		importForm() {
			this.upload = true;
		},
		//导入上传
		uploadSuccess(success) {
			setTimeout(() => {
				this.loading = false;
				this.$refs.upload.clearFiles();
				this.upload = false;
			}, 2000);
			if (success.code == 200) {
				this.$message.success('上传成功');
				this.getSnailList();
			} else {
				// this.open(success.message);
				this.$message.error(success.message);
			}
		},
		closeUpload() {
			this.$refs.upload.clearFiles();
		},
		submitUpload() {
			if (this.$refs.upload.uploadFiles[0]) {
				this.loading = true;
				this.$refs.upload.submit();
			} else {
				this.$message.error('请选择上传文件');
			}
		},
		fileDeletes() {
			this.upload2 = true;
		},
		submitUpload2() {
			if (this.$refs.upload2.uploadFiles[0]) {
				this.$confirm('是否要删除此号码对应的报备号码？', '提示', {
					type: 'warning',
					confirmButtonText: '删除',
					cancelButtonText: '不删除',
					closeOnClickModal: false
				})
					.then(() => {
						this.uploadData.isDelete = true;
						this.loading = true;
						this.$refs.upload2.submit();
					})
					.catch(() => {
						this.uploadData.isDelete = false;
						this.loading = true;
						this.$refs.upload2.submit();
					});
			} else {
				this.$message.error('请选择上传文件');
			}
		},
		uploadSuccess2(success) {
			setTimeout(() => {
				this.loading = false;
				this.$refs.upload2.clearFiles();
				this.upload2 = false;
			}, 2000);
			if (success.code == 200) {
				this.$message.success('删除成功');
				this.getSnailList();
			} else {
				// this.open(success.message);
				this.$message.error(success.message);
			}
		},
		closeUpload2() {
			this.$refs.upload2.clearFiles();
		},
		handleRemove2(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview2(file) {
			console.log(file);
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		showForm() {
			this.editVisible = true;
			this.ids = this.str;
		},

		// 编辑操作
		handleedits(row) {
			this.editId = row.id;
			this.remark = row.remark;
			this.edit = true;
		},

		updateAgent(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true;
					this.form.ids = this.ids;
					this.form.userId = localStorage.getItem('user');
					numberPoolEdit(this.form).then(res => {
						if (res.code == 200) {
							this.$message.success('修改成功');
							this.getSnailList();
						} else {
							this.$message.error(res.message);
						}
						this.editVisible = false;
					});
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getSnailList();
		},
		// 多选操作
		handleSelectionChange(val) {
			this.delList = [];
			this.str = [];
			this.multipleSelection = val;
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.str += this.multipleSelection[i].id + ',';
			}
		},
		exportData() {
			this.loading = true;
			this.query.startTime = this.toDateTimeStr(this.timeArr[0]);
			this.query.endTime = this.toDateTimeStr(this.timeArr[1]);
			let data = {
				phoneNumber: this.query.phoneNumber,
				endTime: this.query.endTime,
				startTime: this.query.startTime
			};
			downloadSnailsprestore(data).then(res => {
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				let fileName = '蜗牛预存款' + '.xlsx';
				downloadElement.download = fileName;
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
				this.loading = false;
			});
		},
		// 删除操作
		handleDelete() {
			if (this.str === '') {
				this.$message.error(`请选择要删除的数据`);
				return;
			} else {
				this.$confirm('是否要删除此号码？', '提示', {
					type: 'warning',
					confirmButtonText: '删除',
					cancelButtonText: '不删除',
					closeOnClickModal: false
				})
					.then(() => {
						let param = {
							ids: this.str
						};
						snailDelete(param).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getSnailList();
							} else {
								this.$message.error(res.message);
							}
						});
						this.multipleSelection = [];
					})
					.catch(() => {
						let param = {
							ids: this.str
						};
						snailDelete(param).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getSnailList();
							} else {
								this.$message.error(res.message);
							}
						});
						this.multipleSelection = [];
					});
			}
		},
		// 删除操作
		handleDelete2(id) {
			this.str = id;
			this.$confirm('是否要删除此号码？', '提示', {
				type: 'warning',
				confirmButtonText: '删除',
				cancelButtonText: '不删除',
				closeOnClickModal: false
			})
				.then(() => {
					let param = {
						ids: this.str
					};
					snailDelete(param).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.getSnailList();
						} else {
							this.$message.error(res.message);
						}
					});
					this.multipleSelection = [];
				})
				.catch(() => {
					let param = {
						ids: this.str
					};
					snailDelete(param).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.getSnailList();
						} else {
							this.$message.error(res.message);
						}
					});
					this.multipleSelection = [];
				});
		},

		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getSnailList();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getSnailList();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},

		adddata() {
			let data = {
				content: this.phoneNumber
			};
			snailManualAddition(data).then(res => {
				if (res.code == 200) {
					this.$message.success(res.message);
					this.upload2 = false;
					this.getSnailList();
				} else {
					this.$message.error(res.message);
					this.loading = false;
				}
			});
		},
		closeUpload2() {
			this.upload2 = false;
		},

		closeedit() {
			this.edit = false;
			this.editId = '';
		},
		editsub() {
			let data = {
				id: this.editId,
				remark: this.remark
			};
			snailUpdate(data).then(res => {
				if (res.code == 200) {
					this.$message.success(res.message);
					this.edit = false;
					this.editId = '';
					this.getSnailList();
				} else {
					this.$message.error(res.message);
					this.loading = false;
					this.editId = '';
				}
			});
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 12px;
	border-radius: 4px;
	margin-left: 10px;
	display: inline-block;
}
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.customer .el-upload {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	width: 100% !important;
	line-height: 50px !important;
}
.customer .btn {
	background-color: #4f7afd;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: none;
	margin-left: 80%;
}
</style>
<style>
.customer .el-upload {
	width: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
}
.customer .btn {
	background-color: #4f7afd;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: none;
	margin-left: 80%;
}
</style>
