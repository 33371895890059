<template>
	<div>
		<el-row :gutter="20">
			<el-col :span="20">
				<el-row :gutter="20" class="mgb20">
					<el-col :span="8" v-if="role == 'admin'">
						<el-select size="small" v-model="query.agentId" filterable clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
							<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
						<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="getuseRecord">搜索</el-button>
					</el-col>
				</el-row>
			</el-col>
			<el-col :span="4">
				<el-button size="small" type="primary" :class="[theme]" @click="buyExcel" v-if="role == 'admin' || role == 'agent'">购买月份</el-button>
				<el-button size="small" v-if="role == 'admin'" type="primary" :class="[theme]" @click="addMonthData">添加月数</el-button>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="20">
				<el-row :gutter="20" class="mgb20">
					<el-col :span="8">
						<el-card shadow="hover" :body-style="{ padding: '0px' }">
							<div class="grid-content grid-con-2">
								<i class="el-icon-receiving grid-con-icon"></i>
								<div class="grid-cont-right">
									<div class="grid-num" :class="[theme3]">{{ remainingTime.toFixed(2) }}月</div>
									<div>剩余量</div>
								</div>
							</div>
						</el-card>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
		<div class="container">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="使用记录" name="usesData">
					<el-table
						v-loading="loading"
						:data="tableUseData"
						border
						class="table m_t_20"
						header-cell-class-name="table-header"
						:row-style="{ height: '20px' }"
						:header-cell-style="{ 'text-align': 'center' }"
						:cell-style="{ padding: '6px' }"
						:row-class-name="tableRowClassName"
					>
						<el-table-column prop="userPhone" label="用户名称" align="center"></el-table-column>
						<el-table-column prop="phoneNumber" label="用户号码" align="center"></el-table-column>
						<el-table-column prop="month" label="时间" align="center">
							<template #default="scope">
								{{ scope.row.month }}月
							</template>
						</el-table-column>
						<el-table-column prop="createdAt" label="分配时间" align="center"></el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination
							background
							layout="total, sizes, prev, pager, next, jumper"
							:current-page="query.pageIndex"
							:page-sizes="[10, 30, 90, 200]"
							:page-size="query.pageSize"
							:total="pageTotal"
							@size-change="handleSizeChange"
							@current-change="handlePageChange"
						></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="支付记录" name="payData">
					<el-table
						v-loading="loading"
						:data="tablePayData"
						border
						class="table m_t_20"
						header-cell-class-name="table-header"
						:row-style="{ height: '20px' }"
						:header-cell-style="{ 'text-align': 'center' }"
						:cell-style="{ padding: '6px' }"
						:row-class-name="tableRowClassName"
					>
						<el-table-column prop="userPhone" label="用户名称" align="center"></el-table-column>
						<el-table-column prop="payType" label="支付类型" align="center"></el-table-column>
						<el-table-column prop="month" label="购买时长" align="center">
							<template #default="scope">
								{{ scope.row.month }}月
							</template>
						</el-table-column>
						<el-table-column prop="createdAt" label="下单时间" align="center"></el-table-column>
						<el-table-column prop="total" label="支付金额" align="center"></el-table-column>
						<el-table-column prop="bon" label="支付状态" align="center">
							<template #default="scope">
								<el-tag :type="scope.row.bon === '已支付' ? 'success' : scope.row.bon === '未支付' ? 'danger' : 'warning'">{{ scope.row.bon }}</el-tag>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination
							background
							layout="total, sizes, prev, pager, next, jumper"
							:current-page="query2.pageIndex"
							:page-sizes="[10, 30, 90, 200]"
							:page-size="query2.pageSize"
							:total="pageTotal2"
							@size-change="handleSizeChange2"
							@current-change="handlePageChange2"
						></el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="validate2">
			<el-dialog title="购买月份" v-model="upload" width="450px" @close="closeUpload()" :close-on-click-modal="false">
				<el-form :model="form" :rules="rules" ref="form" label-width="100px">
					<el-form-item label="支付方式">
						<el-radio-group v-model="form.type" @change="typeChange">
							<el-radio label="wxpay">微信</el-radio>
							<el-radio label="alipay">支付宝</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="购买时长">
						<el-input size="small" style="width: 250px;" placeholder="请输入购买时长" v-model="form.month"></el-input>
						<span class="m_l_10">月</span>
					</el-form-item>
					<el-form-item label="扫码支付" v-show="weixinUrl">
						<div id="qrcode" ref="qrcode" style="position: relative;"></div>
						<div>
							<img src="../assets/img/wxpay.png" alt="" style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" />
							<span style="font-size: 18px;color: #000;">金额：{{ total_fee / 100 }}元</span>
						</div>
					</el-form-item>

					<div style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" class="btn" @click="closeUpload()">取消</el-button>
						<el-button size="small" class="btn" style="border: none;" type="success" :class="[theme]" v-loading.fullscreen.lock="loading" @click="submitUpload">
							确定
						</el-button>
					</div>
				</el-form>
			</el-dialog>
		</div>
		<el-dialog title="添加月数" v-model="addMonthPop" width="450px" @close="closeUpload2()" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="购买时长">
					<el-input size="small" style="width: 250px;" placeholder="请输入购买时长" v-model="form.num"></el-input>
					<span class="m_l_10">月</span>
				</el-form-item>
				<div style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" class="btn" @click="closeUpload2()">取消</el-button>
					<el-button size="small" class="btn" :class="[theme]" style="border: none;" type="success" v-loading.fullscreen.lock="loading" @click="getbuyMonths">
						确定
					</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { busitypes, exportData, recordList } from '../api/httpOperate.js';
import { fetchAgent, useRecord, buyRecord, agentbuyMonths, addValidCallTime } from '../api/agentIndex';
import { buyMonths, queryPaymentRecords, getAdminSerial, addAdminMonths, queryPayStatus } from '../api/assignLeads';
import qs from 'qs';
import QRCode from 'qrcodejs2';
export default {
	name: 'validate',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			theme3: localStorage.getItem('theme3'),
			query: {
				userId: localStorage.getItem('user'),
				pageIndex: 1,
				pageSize: 10,
				agentId: ''
			},
			agents: [],
			query2: {
				pageIndex: 1,
				pageSize: 10,
				type: '',
				status: ''
			},
			tableUseData: [],
			tablePayData: [],
			activeName: 'usesData', //使用记录
			loading: false,

			pageTotal: 0,
			pageTotal2: 0,
			form: {
				type: 'wxpay',
				month: 1000,
				num: ''
			},
			id: -1,
			addMonthPop: false,
			upload: false,

			url: '',
			qr: '',
			weixinUrl: false,
			remainingTime: 0,
			role: '',
			total_fee: '',
			timer: '',
			S: 60
		};
	},
	created() {
		this.role = localStorage.getItem('ms_role');

		if (this.role === 'admin') {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
				this.query.agentId = res.data[0].value;
				this.getuseRecord();
			});
		} else {
			this.getuseRecord();
		}
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getuseRecord() {
			let data = {
				userId: localStorage.getItem('user'),
				pageIndex: this.query.pageIndex,
				pageSize: this.query.pageSize,
				agentId: this.query.agentId
			};
			useRecord(data).then(res => {
				if (res.code == 200) {
					this.tableUseData = res.data.list;
					this.pageTotal = res.data.total;
					this.remainingTime = res.customData;
				} else {
					this.$message.error('请求失败');
				}
			});
		},
		getbuyRecord() {
			let data = {
				pageIndex: this.query2.pageIndex,
				pageSize: this.query2.pageSize,
				agentId: this.query.agentId,
				userId: localStorage.getItem('user')
			};
			buyRecord(data).then(res => {
				this.tablePayData = res.data.list;
				this.pageTotal2 = res.data.total;
			});
		},
		handleClick(tab, event) {
			if (tab.props.label == '使用记录') {
				this.getuseRecord();
			} else if (tab.props.label == '支付记录') {
				this.getbuyRecord();
			}
		},

		// 获取数据
		buyExcel() {
			this.weixinUrl = false;
			this.url = '';
			this.upload = true;
		},
		addMonthData() {
			this.addMonthPop = true;
		},
		getbuyMonths() {
			let data = {
				userId: localStorage.getItem('user'),
				agentId: this.query.agentId,
				month: this.form.num
			};
			addValidCallTime(data).then(res => {
				this.$message.success(res.message);
				this.form.num = '';
				this.addMonthPop = false;
				this.getuseRecord();
			});
		},
		submitUpload() {
			this.$refs.qrcode.innerHTML = '';
			let data = {
				userId: localStorage.getItem('user'),
				agentId: this.query.agentId,
				type: this.form.type,
				month: this.form.month
			};
			agentbuyMonths(data).then(res => {
				if (res.code == 200) {
					if (this.form.type == 'wxpay') {
						this.weixinUrl = true;
						this.url = res.data.url;
						this.total_fee = res.data.total_fee;
						this.qr = new QRCode('qrcode', {
							width: 130,
							height: 130, // 高度
							text: this.url, // 二维码内容
							colorDark: '#000000',
							colorLight: '#ffffff'
						});

						// this.getqueryPayStatus(res.data.out_trade_no);
					} else if (this.form.type == 'alipay') {
						let divForm = document.getElementsByTagName('divform');
						if (divForm.length) {
							document.body.removeChild(divForm[0]);
						}
						const div = document.createElement('divform');
						div.innerHTML = res.data.orderForm;
						document.body.appendChild(div);
						document.forms[1].setAttribute('target', '_blank');
						document.forms[1].submit();
						// this.getqueryPayStatus(res.data.out_trade_no);
					}
				} else {
					this.$message.error(res.message);
				}
			});
		},
		getqueryPayStatus(e) {
			let data = {
				out_trade_no: e,
				type: this.form.type
			};
			queryPayStatus(data).then(res => {
				if (res.code == 200) {
					if (res.data == '交易成功') {
						this.upload = false;
						this.activeName = 'payData';
						this.getbuyRecord();
						this.$message.success(res.data);
					} else {
						setTimeout(() => {
							this.outtime(e);
						}, 1000);
					}
				} else {
					this.$message.error(res.message);
				}
			});
		},
		outtime(e) {
			if (this.S == 0) {
				this.S = 60;
				this.$message.error('付款码失效，请重新发启支付申请');
				this.upload = false;
				this.activeName = 'payData';
				this.form.time = '';
				this.$refs.qrcode.innerHTML = '';
				this.getbuyRecord();
				return;
			} else {
				this.S--;
				this.getqueryPayStatus(e);
			}
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getuseRecord();
		},
		// 分页导航
		handlePageChange(val) {
			console.log(val, '登录的看到了');
			this.query.pageIndex = val;
			// this.getuseRecord();
		},

		handleSizeChange2(val) {
			this.query2.pageSize = val;
			this.getbuyRecord();
		},
		// 分页导航
		handlePageChange2(val) {
			this.query2.pageIndex = val;
			this.getbuyRecord();
		},

		closeUpload() {
			this.upload = false;
			this.form.time = '';
			this.$refs.qrcode.innerHTML = '';
		},
		closeUpload2() {
			this.addMonthPop = false;
			this.form.num = '';
		},
		typeChange(e) {
			this.form.type = e;
		}
	}
};
</script>

<style scoped>
/* a:hover {
	background: #66b1ff;
} */
.handle-select {
	width: 120px;
}

.table {
	width: 100%;
	font-size: 12px;
}

.mr10 {
	width: 400px;
	margin-right: 10px;
}
.grid-content {
	display: flex;
	align-items: center;
	height: 100px;
}
/deep/ .el-col-16 {
	flex: 0 0 59.9%;
}
.grid-cont-right {
	flex: 1;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #999;
}
.grid-num {
	font-size: 30px;
	font-weight: bold;
}

.grid-con-icon {
	font-size: 50px;
	width: 100px;
	height: 100px;
	text-align: center;
	line-height: 100px;
	color: #fff;
}
.grid-con-1 .grid-con-icon {
	background: linear-gradient(#ffb199, #ff0844);
}
.new {
	color: #b5b4b4;
	font-size: 12px;
}
.grid-con-1 .grid-num {
	color: #4f7afd;
}

.grid-con-2 .grid-con-icon {
	background: linear-gradient(#48c6ef, #6f86d6);
}

.grid-con-2 .grid-num {
	color: #4f7afd;
}

.grid-con-3 .grid-con-icon {
	background: linear-gradient(#e5b2ca, #7028e4);
}

.grid-con-3 .grid-num {
	color: #4f7afd;
}
.grid-con-4 .grid-con-icon {
	background: linear-gradient(#00c6fb, #005bea);
}

.grid-con-4 .grid-num {
	color: #4f7afd;
}
.grid-con-5 .grid-con-icon {
	background: linear-gradient(#89f7fe, #66a6ff);
}

.grid-con-5 .grid-num {
	color: #4f7afd;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 12px;
	border-radius: 4px;
	margin-left: 10px;
	display: inline-block;
}
</style>
<style scoped>
.validate2 .btn {
	margin-top: 20px;

	/* background-color: #4f7afd;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	 */
}
</style>
