<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phone" clearable maxlength="12" placeholder="电话号码"
					class="handle-input mr10"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleAdd">添加</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="batchDel">批量删除</el-button>

			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="phone" label="电话号码" align="center"></el-table-column>
				<el-table-column prop="city" label="城市" align="center"></el-table-column>
				<el-table-column prop="time" label="时间" align="center"></el-table-column>
				<el-table-column prop="userPhone" label="添加人" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="separateDelete(scope.row)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 1000, 2000, 5000]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>

			<el-dialog title="添加" v-model="addVisible" width="650px" @close="addVisibleClose">
				<el-form :model="addForm" ref="addForm" label-width="120px">
					<el-form-item label="" prop="">
						<span style="color: red;font-size: 12px;">*注释:收件地址地市与漫游地，不一致，将会漫游地异常停机</span>
					</el-form-item>
					<el-form-item label="收货地市">
						<el-input size="small" v-model="addForm.city" placeholder="请输入收货地市"></el-input>
					</el-form-item>
					<el-form-item label="添加号码">
						<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码,一次最多添加100个号码"
							v-model="addForm.phones"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="addVisibleClose">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getAdd">添加</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="批量删除" v-model="deleteVisible" width="800px" @close="closeDialog"
				:close-on-click-modal="false">
				<el-form :model="deleteForm" :rules="rules" ref="deleteForm" label-width="100px">
					<el-form-item label="删除号码" prop="phones">
						<el-input size="small" type="textarea" :rows="5" placeholder="一行一个删除单号码,一次最多删除100个号码"
							v-model="deleteForm.phones"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" type="primary" :class="[theme]" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="batchDel2">确认删除</el-button>
					</el-form-item>
				</el-form>

			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		openControl,
		openControlList,
		openControlDelete
	} from '../api/index.js';
	export default {
		name: 'agent',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					phone: '',
					pageIndex: 1,
					pageSize: 30,
					userId: localStorage.getItem('user')
				},
				tableData: [],
				pageTotal: 0,
				isAdmin: false,
				addVisible: false,
				cardTypeList: [{
						label: '蜗牛',
						value: '蜗牛'
					},
					{
						label: '迪加',
						value: '迪加'
					}
				],
				addForm: {
					phones: '',
					city: '',
					userId: localStorage.getItem('user')
				},
				multipleSelection: [],
				delList: [],
				deleteVisible: false, //批量删除
				deleteForm: {
					ids: "",
					phones: ""
				}

			};
		},
		created() {
			let role = localStorage.getItem('ms_role');
			if (role === 'normal') {
				this.isNormal = false;
			}
			if (role === 'admin') {
				this.isAdmin = true;
			}
			if (role === 'agent') {
				this.isAgent = true;
			}

			this.getdata()
		},
		methods: {

			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getAdd() {
				openControl(this.addForm).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.addVisible = false;
						this.getdata();
					} else {
						this.$message.error(res.message);
					}
				});
			},

			getdata() {
				openControlList(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleAdd() {
				this.addForm.city = '';
				this.addForm.phones = '';
				this.addVisible = true;
			},
			addVisibleClose() {
				this.addForm.city = '';
				this.addForm.phones = '';
				this.addVisible = false;
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getdata();
			},

			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchDel() {
				let str = '';
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.deleteForm.phones = "";
					this.deleteVisible = true;
				} else {
					console.log(str, 'str')
					this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
						.then(() => {
							let param = {
								ids: str,
								userId: localStorage.getItem('user')
							};
							openControlDelete(param).then(res => {
								if (res.code == 200) {
									this.$message.success('删除成功');
									this.getdata();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
				}
				this.multipleSelection = [];
			},
			batchDel2() {
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let param = {
							phones: this.deleteForm.phones,
							userId: localStorage.getItem('user')
						};
						openControlDelete(param).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.deleteForm.phones = '';
								this.deleteVisible = false;
								this.getdata();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			separateDelete(row) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let param = {
							ids: row.id,
							userId: localStorage.getItem('user')
						};
						openControlDelete(param).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getdata();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getdata();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getdata();
			},
			closeDialog() {
				this.deleteVisible = false;
				this.deleteForm.phones = "";
			}



		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;
		display: inline-block;
	}

	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.customer .el-upload {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
		line-height: 50px !important;
	}

	.customer .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}
</style>
<style>
	.customer .el-upload {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}

	.customer .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}
</style>