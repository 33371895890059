<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" @change="agentChange" v-model="query.agentId" v-if="isAdmin" filterable
					clearable placeholder="代理商" class="handle-select m_r_10">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.companyId" v-if="isAdmin||isAgent" filterable clearable
					placeholder="企业" class="handle-select m_r_10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" type="text" placeholder="请输入中间号" v-model="query.telX" class="m_r_10"
					style="width: 180px;" filterable clearable></el-input>
				<el-button size="small" type="primary" :class="[theme]" @click="getData()">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="handleAdd">添加</el-button>
			</div>
			<div class="city-box">
				<div v-for="item in cityList" :key="item.label" style="cursor: pointer;" @click="tabCity(item)">
					{{ item.label }}
					<span>{{ item.value }}</span>
					<i :class="[theme3]" class="el-icon-shopping-cart-full"></i>
				</div>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="dayUse" label="日外呼量" align="center"></el-table-column>
				<el-table-column prop="telX" label="中间号" align="center"></el-table-column>
				<el-table-column prop="id" label="编码" align="center"></el-table-column>

				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[10, 30, 90, 200]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<el-dialog title="添加" v-model="addVisible" width="520px" @close="closeDialog">
			<el-form label-width="120px" :model="addForm" ref="addForm">
				<el-form-item label="代理" prop="agentId" v-if="isAdmin">
					<el-select size="small" @change="agentChange" v-model="addForm.agentId" filterable clearable
						placeholder="代理商" class="handle-select mr10" style="width: 100%">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业" prop="companyId" v-if="isAdmin||isAgent">
					<el-select size="small" v-model="addForm.companyId" filterable clearable placeholder="企业"
						class="handle-select mr10" style="width: 100%">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="中间号" prop="telX">
					<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码,一次最多添加100个号码"
						v-model="addForm.telX"></el-input>
				</el-form-item>

				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="submitAdd">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

	</div>
</template>

<script>
	import {
		fetchDefaultCompanies,
		fetchCompanyByAgentId
	} from '../api/companyIndex';
	import {
		fetchAgent
	} from '../api/agentIndex';
	import {
		XNumberPool,
		addXNumber,
		deleteXNumber,
	} from '../api/setMeal.js';
	export default {
		name: 'company',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				theme3: localStorage.getItem('theme3'),
				query: {
					companyId: '',
					telX: '',
					pageIndex: 1,
					pageSize: 10
				},
				tableData: [],
				pageTotal: 0,
				cityList: [],
				addForm: {
					companyId: '',
					telX: '',
				},
				addVisible: false,

				multipleSelection: [],
				agents: [],
				companys: [],

				isAdmin: false,
				isAgent: false,
				role: '',

			};
		},

		created() {
			this.role = localStorage.getItem('ms_role');

			this.init();
			if (this.role === 'admin') {
				this.isAdmin = true;
			}

			if (this.role === 'agent') {
				this.isAgent = true;
			}
		},
		methods: {
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					//代理
					this.agents = res.data;
					this.options = res.data;
				});
				let data1 = {
					currentUserId: localStorage.getItem('user')
				};
				fetchDefaultCompanies(data1).then(res => {
					//默认企业
					this.companys = res.data;
				});
			},
			getData() {
				let data = {
					companyId: this.query.companyId,
					telX: this.query.telX,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize
				};
				XNumberPool(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			agentChange(agentId) {
				//代理商下拉
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.optionalForm.companyId = companyId;
				this.optionalForm.userId = '';
				//企业下拉
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},



			handleAdd() {
				this.addVisible = true;
				this.addForm = {};
			},

			submitAdd() {
				let data = {
					companyId: this.addForm.companyId,
					telX: this.addForm.telX,
				};
				addXNumber(data).then(res => {
					if (res.code == 200) {
						this.$message.success('添加成功');
						this.query.agentId = this.addForm.agentId;
						this.query.companyId = this.addForm.companyId;
						this.getData();
						this.addForm = {};
					} else {
						this.$message.error(res.message);
					}
					this.addVisible = false;
				});
			},

			closeDialog() {
				this.addVisible = false;
				this.addForm.phones = '';
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: id,
							userId: localStorage.getItem('user')
						};
						deleteXNumber(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.city-box {
		display: flex;
		margin: 20px 0;
	}

	.city-box div {
		float: left;
		margin-right: 20px;
	}

	.city-box div span {
		margin-right: 6px;
	}

	.customer .el-upload {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
		line-height: 50px !important;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;

		display: inline-block;
	}
</style>