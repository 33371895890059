import request from '../utils/request';
import qs from "qs";

// AXB列表查询
export const setMealList = id => {
	return request.get('/api/setMeal/list')
};
// AXB添加
export const setMealAdd = param => {
	return request.post('/api/setMeal/add', qs.stringify(param))
};
// AXB修改
export const setMealUpdate = param => {
	return request.post('/api/setMeal/update', qs.stringify(param))
};
//AXB删除
export const setMealDelete = param => {
	return request.post('/api/setMeal/delete', qs.stringify(param))
};


// 虚商AXB套餐列表
export const setMealListX = id => {
	return request.get('/api/xsAxb/setMeal/list')
};
// 虚商AXB添加
export const setMealAddX = param => {
	return request.post('/api/xsAxb/setMeal/add', qs.stringify(param))
};
// 虚商AXB修改
export const setMealUpdateX = param => {
	return request.post('/api/xsAxb/setMeal/update', qs.stringify(param))
};
//虚商AXB删除
export const setMealDeleteX = param => {
	return request.post('/api/xsAxb/setMeal/delete', qs.stringify(param))
};

//虚商AXB绑定关系查询
export const Bingding = param => {
	return request.post('/api/xsAxb/numberRelationship', qs.stringify(param))
};


//虚商AXB =  AX绑定
export const numberInit = param => {
	return request.post('/api/xsAxb/numberInit', qs.stringify(param))
};

//虚商AXB =  购买套餐
export const recharge = param => {
	return request.post('/api/xsAxb/recharge', qs.stringify(param))
};


//退卡
export const returnCard = param => {
	return request.post('/api/SJSZInventory/returnCard', qs.stringify(param))
};

//库存列表
export const inventoryList = param => {
	return request.post('/api/inventory/list', qs.stringify(param))
};

// 添加库存
export const inventoryUpload = param => {
	return request.post('/api/inventory/upload', qs.stringify(param), {
		responseType: 'blob'
	})
};

// 划拨号码给企业
export const inventoryOptionalNumber = param => {
	return request.post('/api/inventory/optionalNumber', qs.stringify(param))
};
// 查看已划拨号码
export const inventoryFindAssigned = param => {
	return request.post('/api/inventory/findAssigned', qs.stringify(param))
};

// 选购套餐
export const inventoryChoosePackage = param => {
	return request.post('/api/inventory/choosePackage', qs.stringify(param))
};

// 绑定
export const axbBind = param => {
	return request.post('/api/axb/bind', qs.stringify(param))
};
// 坐席续费
export const addDuration = param => {
	return request.post('/api/inventory/addDuration', qs.stringify(param))
};
// 解绑
export const axbUnbind = param => {
	return request.post('/api/axb/unbind', qs.stringify(param))
};
// 支付反馈
export const inventoryCheckPay = param => {
	return request.post('/api/inventory/checkPay', qs.stringify(param))
};


//AXB消费明细
export const inventoryExpenseDetail = param => {
	return request.post('/api/inventory/expenseDetail', qs.stringify(param))
};




// 所有地区号码
export const inventoryStatistics = id => {
	return request.get('/api/inventory/statistics')
};



// 导出回收客户
export const downloadCustomer = param => {
	return request.post('/api/customer/downloadCustomer', qs.stringify(param), {
		responseType: 'blob'
	})
};
// 分配回收客户
export const allotCustomers = param => {
	return request.post('/api/customer/allotCustomers', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
//世纪通信库存
export const SJSZInventoryList = param => {
	return request.post('/api/SJSZInventory/list', qs.stringify(param))
};

//世纪通信库存添加
export const SJSZInventoryAdd = param => {
	return request.post('/api/SJSZInventory/add', qs.stringify(param))
};
//世纪通信库存  出库
export const SJSZInventoryDeliveryStorage = param => {
	return request.post('/api/SJSZInventory/DeliveryStorage', qs.stringify(param))
};
//世纪通信库存  出库记录
export const SJSZInventoryDeliveryRecord = param => {
	return request.post('/api/SJSZInventory/DeliveryRecord', qs.stringify(param))
};
//世纪通信库存  查询所有品牌
export const SJSZInventoryCardType = param => {
	return request.get('/api/SJSZInventory/cardType')
};
//世纪通信库存  查询所有城市
export const SJSZInventorystatistics = param => {
	return request.get('/api/SJSZInventory/statistics?cardType=' + param.cardType + '&&province=' + param.province +
		'&&cityAlphabet=' + param.cityAlphabet)
};

//世纪通信库存  查询所有省份
export const SJSZInventoryprovince = card => {
	return request.get('/api/SJSZInventory/province?cardType=' + card)
};
//世纪通信库存 查询所有出库代理
export const getSalesStaffList = param => {
	return request.get('/api/SJSZInventory/salesStaff')
};
//世纪通信库存 查询所有出库企业
export const getCompanyList = param => {
	return request.get('/api/SJSZInventory/company')
};

//世纪通信库存 导出
export const SJSZInventoryExportRecord = param => {
	return request.post('/api/SJSZInventory/exportRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};
//回拨中间号列表
export const XNumberPool = param => {
	return request.post('/api/callBack/XNumberPool', qs.stringify(param))
};
//添加回拨中间号
export const addXNumber = param => {
	return request.post('/api/callBack/addXNumber', qs.stringify(param))
};
//删除外呼中间号
export const deleteXNumber = param => {
	return request.post('/api/callBack/deleteXNumber', qs.stringify(param))
};


// 回拨套餐列表查询
export const callBackSetMealList = id => {
	return request.get('/api/callBackSetMeal/list')
};
// 回拨套餐添加
export const callBackSetMealAdd = param => {
	return request.post('/api/callBackSetMeal/add', qs.stringify(param))
};
// 回拨套餐修改
export const callBackSetMealUpdate = param => {
	return request.post('/api/callBackSetMeal/update', qs.stringify(param))
};
//回拨套餐删除
export const callBackSetMealDelete = param => {
	return request.post('/api/callBackSetMeal/delete', qs.stringify(param))
};

//回拨消费明细
export const callBackSetMealExpenseDetail = param => {
	return request.post('/api/telA/expenseDetail', qs.stringify(param))
};

//私人号码列表
export const telAList = param => {
	return request.post('/api/telA/list', qs.stringify(param))
};
//添加私人号码
export const telAAdd = param => {
	return request.post('/api/telA/add', qs.stringify(param))
};
//admin添加时长
export const administratorAddPackage = param => {
	return request.post('/api/telA/administratorAddPackage', qs.stringify(param))
};
//购买套餐
export const telABuyMinutes = param => {
	return request.post('/api/telA/buyMinutes', qs.stringify(param))
};
//查询是否支付2S一次
export const telACheckPay = param => {
	return request.post('/api/telA/checkPay', qs.stringify(param))
};