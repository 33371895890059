<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable
					placeholder="请选择代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-model="query.companyId" @change="companyChange"
					filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.phone" clearable placeholder="请输入内容"
					style="width: 200px;margin-right: 10px;"></el-input>
				<el-date-picker size="small" type="date" placeholder="选择日期" v-model="time1"
					style="width: 200px;margin-right: 10px;"></el-date-picker>
				<el-select size="small" style="width: 140px" v-if="time1" v-model="time2" filterable clearable
					placeholder="请选择时段" class="handle-select mr10">
					<el-option v-for="item in dateList" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">查询</el-button>
			</div>
			<el-row>
				<el-col :span="24">
					<el-table :data="tableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :cell-style="{ cursor: 'pointer', padding: '6px' }"
						:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName">
						<el-table-column prop="phoneNumber" label="被叫号码" align="center"></el-table-column>
						<el-table-column prop="userPhone" label="主叫号码" align="center"></el-table-column>
						<el-table-column prop="time" label="时间" align="center"></el-table-column>
					</el-table>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import {
		fetchDepartment,
		AbnormalCallCheck,
	} from '../api/index.js';
	import {
		fetchCompanyByAgentId,
	} from '../api/companyIndex';
	import {
		fetchAgent
	} from '../api/agentIndex';
	export default {
		name: 'record',

		data() {
			return {
				timeArr: [],
				theme: localStorage.getItem('theme'),
				query: {
					phone: '',
					companyId: '',
					pageIndex: 1,
					pageSize: 30,
				},
				time1: new Date(),
				time2: '00',
				tableData: [],
				agents: [],
				companys: [],
				role: '',
				pageTotal: 0,
				isAdmin: false,
				isAgent: false,
				dateList: [{
					lable: 0,
					value: '00'
				}, {
					lable: 1,
					value: '01'
				}, {
					lable: 2,
					value: '02'
				}, {
					lable: 3,
					value: '03'
				}, {
					lable: 4,
					value: '04'
				}, {
					lable: 5,
					value: '05'
				}, {
					lable: 6,
					value: '06'
				}, {
					lable: 7,
					value: '07'
				}, {
					lable: 8,
					value: '08'
				}, {
					lable: 9,
					value: '09'
				}, {
					lable: 10,
					value: '10'
				}, {
					lable: 11,
					value: '11'
				}, {
					lable: 12,
					value: '12'
				}, {
					lable: 13,
					value: '13'
				}, {
					lable: 14,
					value: '14'
				}, {
					lable: 15,
					value: '15'
				}, {
					lable: 16,
					value: '16'
				}, {
					lable: 17,
					value: '17'
				}, {
					lable: 18,
					value: '18'
				}, {
					lable: 19,
					value: '19'
				}, {
					lable: 20,
					value: '20'
				}, {
					lable: 21,
					value: '21'
				}, {
					lable: 22,
					value: '22'
				}, {
					lable: 23,
					value: '23'
				}]
			};
		},

		created() {
			this.role = localStorage.getItem('ms_role');
			if (this.role === 'agent') {
				this.isAgent = true;
				this.isNotAdmin = false;

			}
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
				this.isNotAdmin = false;
			}
			this.init();
		},

		methods: {

			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},

			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});

			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			/* 切换部门 */
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
				});
			},
			getData() {
				let data = {
					companyId: this.query.companyId,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
					startTime: this.toDateTimeStr(this.time1) + " " + this.time2 + ":00:00",
					phone: this.query.phone
				};
				AbnormalCallCheck(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.message);
					}
				});

			},

			toDateTimeStr(nS) {
				let date = new Date(nS);
				let YY = date.getFullYear() + '-';
				let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return YY + MM + DD;
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},

		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.mr10 {
		margin-right: 10px;
	}

	.red {
		color: #f56c6c;
	}
</style>