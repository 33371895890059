<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phone" clearable maxlength="12" placeholder="号码" style="width: 180px;" class="handle-input mr10"></el-input>
				<el-select size="small" style="width: 260px" v-model="query.status" filterable clearable placeholder="充值类型" class="handle-select mr10">
					<el-option v-for="item in rechargeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getFinish">批量处理</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="copytext">批量导出</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="closeRecharge">APP充值</el-button>
			</div>
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="agent" label="代理商" align="center"></el-table-column>
				<el-table-column prop="company" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="phone" label="充值用户" align="center"></el-table-column>
				<el-table-column prop="amount" label="充值金额" width="80" align="center"></el-table-column>
				<el-table-column prop="createdAt" label="充值时间" align="center"></el-table-column>
				<el-table-column prop="type" label="支付方式" width="100" align="center"></el-table-column>
				<el-table-column prop="orderId" label="订单编号" align="center"></el-table-column>
				<el-table-column prop="status" label="充值类型" align="center" width="100">
					<template #default="scope">
						<el-tag v-if="scope.row.status == true">已处理</el-tag>
						<el-tag type="danger" v-if="scope.row.status == false">未处理</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="操作" align="center" width="100">
					<template #default="scope">
						<el-tag type="danger" v-if="scope.row.status == false">退款</el-tag>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, prev, pager, next"
					:current-page="query.pageIndex"
					:page-size="query.pageSize"
					:total="pageTotal"
					@current-change="handlePageChange"
				></el-pagination>
			</div>

			<el-dialog v-model="dialogCloseRecharge" width="450px" @close="dialogCloseRecharges">
				<el-form :model="form" ref="form" label-width="100px">
					<el-form-item label="APP充值:">
						<el-radio-group v-model="form.bon">
							<el-radio :label="true">开启</el-radio>
							<el-radio :label="false">关闭</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="dialogCloseRecharges">取消</el-button>
						<el-button type="primary" :class="[theme]" size="small" @click="getStops">确认</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { woniuDataList, finish, download, getStop, dxtEnabled } from '../api/tag.js';

export default {
	name: 'company',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				companyId: '',
				userId: localStorage.getItem('user'),
				status: -1,
				phone: '',
				pageIndex: 1,
				pageSize: 30
			},
			tableData: [],
			pageTotal: 0,
			form: {
				bon: ''
			},
			rechargeList: [{ value: 0, label: '全部' }, { value: 1, label: '已处理' }, { value: -1, label: '未处理' }],
			addVisible: false,
			multipleSelection: [],
			delList: [],
			dialogCloseRecharge: false,
			isRemarkKey: false
		};
	},

	created() {
		this.getData();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},

		getData() {
			this.query.userId = localStorage.getItem('user');
			woniuDataList(this.query).then(res => {
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},
		closeRecharge() {
			this.dialogCloseRecharge = true;
			dxtEnabled;
			dxtEnabled().then(res => {
				if (res.code == 200) {
					this.form.bon = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		dialogCloseRecharges() {
			this.dialogCloseRecharge = false;
		},

		getStops() {
			let data = {
				userId: localStorage.getItem('user'),
				bon: this.form.bon
			};
			getStop(data).then(res => {
				if (res.code == 200) {
					this.dialogCloseRecharge = false;
					this.$message.success(res.data);
				} else {
					this.$message.error(res.message);
				}
			});
		},

		// 多选操作
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//批量处理
		getFinish() {
			let ids = '';
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				ids += this.multipleSelection[i].id + ',';
			}
			if (ids === '') {
				this.$message.error(`请选择数据`);
				return;
			} else {
				let data = {
					ids: ids,
					userId: localStorage.getItem('user')
				};
				finish(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			}
		},
		//批量导出
		copytext() {
			let ids = '';
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				ids += this.multipleSelection[i].id + ',';
			}
			if (ids === '') {
				this.$message.error(`请选择数据`);
				return;
			} else {
				let data = {
					ids: ids
				};
				download(data).then(res => {
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '充值订单' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			}
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		// 点击取消
		closeDialog(formName) {
			this.addVisible = false;
			(this.form = {
				template4: '',
				template5: ''
			}),
				this.resetForm(formName);
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover {
	background: #66b1ff;
} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.recharge-box .el-radio__input {
	display: none;
}
.recharge-box .el-radio.is-bordered {
	padding: 8px 14px 0 5px;
	border-radius: 4px;
	border: 1px solid #dcdfe6;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	height: 30px;
}
</style>
