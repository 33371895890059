<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select" style="width: 240px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select m_l_10" style="width: 240px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-if="isNormal || isAgent" v-model="query.companyId" @change="companyChange"
					filterable clearable placeholder="企业" class="handle-select m_l_10" style="width: 240px">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.phone" clearable placeholder="请输入手机号码" class="handle-input m_l_10"
					style="width: 180px"></el-input>
				<el-button size="mini" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch"
					class="m_l_10">搜索</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				@selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="phone" label="号码" align="center"></el-table-column>
				<el-table-column prop="setMeal" width="340" label="套餐" align="center"></el-table-column>
				<el-table-column prop="monthUseMinutes" width="340" label="已使用分钟" align="center"></el-table-column>
				<el-table-column prop="totalMinutes" label="总时间" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" v-if="scope.row.setMeal==''"
							@click="handleadd(scope.$index, scope.row.id)">购买套餐</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 50, 100, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>



		</div>
	</div>
</template>

<script>
	import {
		fetchUserById,
		xsAxbuserList,
	} from '../api/userIndex';
	import {
		fetchDepartment
	} from '../api/index.js';
	import {
		fetchAgent,
		fetchInitAgent
	} from '../api/agentIndex.js';
	import {
		fetchCompanyByAgentId,
		fetchDefaultCompanies
	} from '../api/companyIndex';
	import {
		querySubAgent
	} from '../api/serialIndex.js';
	export default {
		name: 'user',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					phone: '',
					pageIndex: 1,
					pageSize: 30,
				},
				agents: [],
				companys: [],
				departments: [],
				tableData: [],
				multipleSelection: [],
				isAdmin: false,
				isSubAgent: false,
				isAgent: false,
				isNormal: false,
				pageTotal: 0,
				loading: false,
				role: '',
				addVisible: false,
			};
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			this.init();
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.agentDis = true;
				this.companyDis = true;
				this.isNormal = true;
			}

			if (this.role == 'agent') {
				this.isAgent = true;
				this.companyDis = true;
				this.isNormal = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (this.role === 'subAgent') {
				this.isAgent = true;
			}
		},
		methods: {

			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				fetchInitAgent(data).then(res => {
					if (res.data) {
						this.invalidateDate = res.data.validCallTime;
					}
				});
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}

			},
			getData() {
				let data = {
					companyId: this.query.companyId,
					phone: this.query.phone,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
				};

				xsAxbuserList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},

			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},


			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},


			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			handleadd() {},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}
</style>