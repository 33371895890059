<template>
	<div>
		<el-row :gutter="20" type="flex" class="row-bg" justify="space-between"
			style="padding-top: 10px;padding-bottom: 10px;">
			<el-col :span="5"
				style="background-color:#fff; border-radius: 8px;padding: 15px;margin-right: 1%; max-width: 19.833333%">
				<div>
					<el-select size="small" style="width: 100%" v-if="isAdmin" @change="agentChange"
						v-model="query.agentId" filterable clearable placeholder="代理商" class="handle-select mb10">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
					<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId"
						@change="getfetchDefaultCompanies" filterable clearable placeholder="子代理"
						class="handle-select mb10" style="width: 100%">
						<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
					<el-select size="small" style="width: 100%" v-if="isAgent" v-model="query.companyId"
						@change="fileChange" filterable clearable placeholder="企业" v-show="isNormal"
						class="handle-select">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
					<el-input size="small" v-model="query.fileName" placeholder="请输入线索名" style="width: 100%"
						class="handle-input mr10 mb10 m_t_10"></el-input>

					<div style="display: flex;flex-direction: column;justify-content: space-between;">
						<el-button size="small" type="primary" :class="[theme]" style="width: 100%;"
							icon="el-icon-search" @click="getcustomerFile">搜索</el-button>
						<el-button size="small" type="primary" :class="[theme]"
							style="width: 100%;margin-top: 10px;margin-left: 0;" icon="el-icon-plus"
							@click="openaddPoolVisible">
							新建线索池
						</el-button>
					</div>
				</div>
				<div class="tree" id="my">
					<ul style="height: 800px;">
						<li class="item-menuList" v-for="item in menuList" :key="item.id">
							<div @click="curShow(item)" :class="[item.flag == true ? theme4 : '']"
								style="position: relative;">
								<div class="tip" :class="[theme]">线索池</div>
								<div class="radio-box" :class="[theme6]">
									<div class="radio-tit">名称：{{ item.fileName }}</div>
									<div class="radio-price">数量：共{{ item.count || 0 }}条数据</div>
									<div>导入时间:{{ item.time }}</div>
									<div class="radio-tip">
										<i class="el-icon-edit ml10" :class="[theme3]" v-if="item.flag == true"
											@click.stop="getEdit(item)"></i>
										<i class="el-icon-delete ml10" :class="[theme3]" v-if="item.flag == true"
											@click.stop="deletePool(item)"></i>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<el-dialog title="编辑名称" v-model="editName" width="450px" @close="closeDialog('form')"
					:close-on-click-modal="false">
					<el-form :model="editNameForm" :rules="rules" ref="form" label-width="100px">
						<el-form-item label="线索池名称" prop="name"><el-input size="small"
								v-model="editNameForm.name"></el-input></el-form-item>
						<el-form-item label="可见范围">
							<el-checkbox-group v-model="departmentsIds" @change="changedepartmentList">
								<el-checkbox v-for="(item, index) in departmentList" :label="item.departmentId"
									:key="index" border style="margin: 0;margin-right: 10px;margin-bottom: 10px;">
									{{ item.name }}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
							<el-button size="small" @click="editName = false">取消</el-button>
							<el-button size="small" type="primary" :class="[theme]"
								@click="getUpdateCustomerFile('editNameForm')">确定</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
			</el-col>
			<el-col :span="19"
				style="padding-left: 10px;padding-right: 10px;background-color: rgb(255, 255, 255);border-radius: 8px;">
				<div class="container" style="margin-top: 20px;">
					<div class="handle-box">
						<el-input size="small" v-model="query.phoneNumber" placeholder="客户联系方式" style="width: 120px"
							class="handle-input mr10"></el-input>
						<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
							@click="findByQuerys">搜索</el-button>
						<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload"
							class="handle-del ml10" @click="uploadExcel">导入线索</el-button>
						<el-button size="small" type="primary" :class="[theme]" icon="el-icon-download"
							class="handle-del ml10" @click="getdownloadCustomerTemplate">
							下载模板
						</el-button>
						<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2"
							@click="handleExport">导出</el-button>
						<el-button size="small" type="primary" :class="[theme]" class="handle-del ml10"
							@click="getfileNameVisible">分配数据</el-button>
						<el-button size="small" type="primary" :class="[theme]" class="handle-del ml10"
							@click="batchDel">批量删除</el-button>
					</div>
					<el-table :data="tableData" border class="table" height="800" ref="multipleTable"
						header-cell-class-name="table-header" :header-cell-style="{ 'text-align': 'center' }"
						@selection-change="handleSelectionChange" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column prop="contactName" label="客户姓名" align="center"></el-table-column>
						<el-table-column prop="phoneNumber" label="联系电话" align="center">
							<template #default="scope" v-if="desensitization == true">
								{{ scope.row.phoneNumber ? scope.row.phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3') : '' }}
							</template>
						</el-table-column>
						<el-table-column prop="remark" label="备注" align="center"></el-table-column>
						<el-table-column label="操作" width="240" align="center">
							<template #default="scope">
								<el-button size="mini" type="text" icon="el-icon-delete" style="color: #f56c6c;"
									@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>

					<div class="pagination">
						<el-pagination background layout="total, sizes, prev, pager, next, jumper"
							:current-page="query2.pageIndex" :page-sizes="[20, 50, 100, 200, 500]"
							:page-size="query2.pageSize" :total="pageTotal" @size-change="handleSizeChange"
							@current-change="handlePageChange"></el-pagination>
					</div>
				</div>
				<div class="clue">
					<el-dialog title="导入线索" v-model="upload" width="650px" :close-on-click-modal="false">
						<el-upload ref="upload" action="/api/customer/uploadDataCluePool" :headers="token" :limit="1"
							:on-success="uploadSuccess" :on-preview="handlePreview" :on-remove="handleRemove"
							:auto-upload="false" :data="uploadData">
							<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
							<div class="el-upload__tip">1、只能上传Excel格式文件</div>
						</el-upload>

						<div style="max-height: 400px;overflow: auto;">
							<h3 style="margin: 10px 0;">可导入的线索池</h3>
							<el-radio-group v-model="form.nameList" @change="handleCheckedCitiesChange3"
								style="width: 100%;">
								<el-radio v-for="item in menuList" :label="item.id" :key="item.id" border
									style="width: 30%;margin:0;margin-bottom:10px;margin-right: 1.5%;">
									{{ item.fileName }}
								</el-radio>
							</el-radio-group>
						</div>
						<h3 style="margin: 10px 0;">线索导入规则:</h3>
						<div>线索数据导入选中的数据池。</div>
						<el-button size="small" :class="[theme]" type="primary" style="margin-left: 80%;"
							v-loading.fullscreen.lock="loading" @click="submitUpload">
							确定上传
						</el-button>
					</el-dialog>
				</div>
				<div class="clue">
					<el-dialog title="新建线索池" v-model="addPoolVisible" width="650px" :close-on-click-modal="false">
						<el-form :model="formPool" ref="formPool" label-width="100px">
							<el-form-item label="线索池名称" prop="name"><el-input size="small"
									v-model="formPool.name"></el-input></el-form-item>
							<el-form-item label="可见范围">
								<el-checkbox-group v-model="departmentsIds" @change="changeDepartments">
									<el-checkbox :label="item.value" v-for="(item, index) in departments" :key="index"
										border style="margin: 0;margin-right: 10px;margin-bottom: 10px;">
										{{ item.label }}
									</el-checkbox>
								</el-checkbox-group>
							</el-form-item>
							<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
								<el-button size="small" @click="addPoolVisible = false">取消</el-button>
								<el-button type="primary" :class="[theme]" size="small"
									@click="subAddPool">确认</el-button>
							</el-form-item>
						</el-form>
					</el-dialog>
				</div>
			</el-col>
		</el-row>

		<el-dialog v-model="isrepeatBox" width="450px" :show-close="false" :close-on-click-modal="false">
			<div>
				<div style="text-align: center;">
					<i class="el-icon-warning-outline" style="color: #F56C6C;font-size: 76px;margin-bottom: 35px;"></i>
					<div style="margin-left: 20px;text-align: left;font-size: 16px;">
						关闭去重功能，会出现同一个号码重复分配给不同员工，极有可能出现撞单和频繁骚扰情况，请谨慎使用！
					</div>
				</div>
				<div style="display: flex;flex-direction: row;justify-content: end;margin-top: 50px;">
					<el-button size="small" @click="quchong">去重</el-button>
					<el-button size="small" type="danger" @click="buquchong">不去重</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog v-model="filterBox" width="450px">
			<div class="filter-box">
				<div>
					<i class="el-icon-success" style="color: #40FF63;"></i>
					导入成功：{{ filterListData.success || 0 }}条
				</div>
				<div v-if="filterListData.format > 0" @click="seeError" class="icon-class">
					<i class="el-icon-error" style="color: #FFB640;"></i>
					格式错误：
					<span>{{ filterListData.format }}</span>
					条
				</div>
				<div v-else>
					<i class="el-icon-error" style="color: #FFB640;"></i>
					格式错误：0 条
				</div>
				<div v-if="filterListData.repeats > 0" @click="seeFilesRepeating" class="icon-class">
					<i class="el-icon-warning" style="color: #FF6D40;"></i>
					文件数据重复：
					<span>{{ filterListData.repeats }}</span>
					条
				</div>
				<div v-else>
					<i class="el-icon-warning" style="color: #FF6D40;"></i>
					文件数据重复：{{ 0 }}条
				</div>
				<div v-if="filterListData.filter > 0" @click="seeEnterpriseDuplication" class="icon-class">
					<i class="el-icon-info" style="color: #FF40D6;"></i>
					企业数据重复：
					<span>{{ filterListData.filter }}</span>
					条
				</div>
				<div v-else>
					<i class="el-icon-info" style="color: #FF40D6;"></i>
					企业数据重复：{{ 0 }}条
				</div>
			</div>
		</el-dialog>

		<el-dialog v-model="errortab" width="450px">
			<div>
				<span class="tab-span" v-for="item in filterListData.formatList" :key="item">{{ item }}</span>
			</div>
		</el-dialog>
		<el-dialog v-model="filesRepeatingtab" width="450px">
			<div>
				<span class="tab-span" v-for="item in filterListData.repeatsList" :key="item">{{ item }}</span>
			</div>
		</el-dialog>
		<el-dialog v-model="enterpriseDuplicationtab" width="450px">
			<div>
				<span class="tab-span" v-for="item in filterListData.filterList" :key="item">{{ item }}</span>
			</div>
		</el-dialog>

		<el-dialog :title="fileName" v-model="fileNameVisible" width="600px" @close="closeFileNameDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="fileForm" ref="fileForm" label-width="120px">
				<el-form-item label="分配类型">
					<el-radio-group v-model="fileForm.type" @change="typeChange">
						<el-radio label="0">平均分配</el-radio>
						<el-radio label="1">手动分配</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="fileForm.type == 0" label="可分配线索数量">
					<span>{{ fileForm.totals }}条</span>
				</el-form-item>
				<el-form-item v-if="fileForm.type == 0" label="被分配线索数量">
					<el-input size="small" v-model="multipleSelection.length" disabled
						v-if="multipleSelection.length > 0"></el-input>
					<el-input size="small" v-model="fileForm.totals2" v-else></el-input>
				</el-form-item>
				<el-form-item v-if="fileForm.type == 1" label="可分配线索数量">
					<span>{{ fileForm.totals }}条</span>
				</el-form-item>
				<el-form-item label="部门">
					<el-checkbox-group v-model="fileForm.departmentList" @change="handleCheckedCitiesChange"
						size="small">
						<el-checkbox v-for="item in departmentList" :label="item.departmentId" :key="item.departmentId"
							border style="width: 180px;margin-bottom:10px;margin-right: 20px;">
							{{ item.name }}
						</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item v-if="nameList.length > 0">
					<el-button size="small" type="primary" :class="[theme]" @click="selectAll">全选</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="notAllselected"
						v-if="fileForm.nameList.length>0">取消选中</el-button>
				</el-form-item>
				<el-form-item label="员工" v-if="nameList.length > 0">
					<el-checkbox-group v-model="fileForm.nameList" @change="handleCheckedCitiesChange2" size="small">
						<el-checkbox v-for="(item, index) in nameList" :label="item.value" :key="item.value" border
							style="width: 180px;margin:0;margin-bottom:10px;display: flex;flex-direction: row;position: relative;">
							{{ item.label }}
							<el-input size="small" v-if="fileForm.type == 1" v-model="fileForm.number[index]"
								placeholder="分配条数" style="position: absolute;left: 200px;top: -2px;"
								@change="getNumber"></el-input>
						</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item>
					<el-button size="small" style="margin-left: 55%;" @click="closeFileNameDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="addAllotCustomers">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		fetchData,
		delCustomer,
		fetchDataById,
		batchDelete,
		getByCustomerFileId,
		getCustomer,
		deleteFile,
		updateCustomerFile,
		countCustomer,
		socketSend,
		socketIntention,
		getDevices,
		getWebAddress,
		computerSpeed,
		blackListAdd,
		getblackListVerify,
		getrecordUpload,
		deleteCompletedFile,
		setIntervals,
		downloadCustomerTemplate,
		findNormal,
		uploedriskNumber,
		webEndCall,
		customerRecycleData
	} from '../api/customerIndex';
	import { fetchDepartment, fetchUsersByDepartmentId, fetchDefaultUsers, fetchCompany } from '../api/index.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	import {
		recycieFile,
		uploadDataCluePool,
		creationCluePool,
		deleteHighSeasData,
		deleteRecycleFile,
		customerAllocation,
		ModifyPool,
		getUserByDepartmentId,
		downloadCustomer
	} from '../api/assignLeads';
	import { addIntentionCustomer } from '../api/recordIndex';
	import { querySubAgent } from '../api/serialIndex.js';
	import md5 from '../api/md5.js'; //用于md5加密
	import axios from 'axios';
	export default {
		name: 'baseTable',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				theme2: localStorage.getItem('theme2'),
				theme3: localStorage.getItem('theme3'),
				theme4: localStorage.getItem('theme4'),
				theme5: localStorage.getItem('theme5'),
				editName: false, //修改线索的名字
				fileNameVisible: false, //修改线索的名字
				desensitization: false, //表格里的手机号是否隐藏  true是隐藏
				editNameForm: {
					fileName: '',
					customerFileId: ''
				},
				query: {
					status: -1,
					companyId: '',
					departmentId: '',
					userId: '',
					pageIndex: 1,
					pageSize: 10,
					fileName: '',
					blackListValue: false,
					subAgentId: ''
				},
				menu: {
					pageIndex: 1,
					pageSize: 20
				},
				menupageTotal: 0,
				num: '',
				activeName: 'numberList', //默认显示的导航号码清单和线索统计clueEcharts  numberList
				query2: {
					pageIndex: 1,
					pageSize: 20,
					customerFileId: '',
					normalFileId: ''
				},
				menuList: [],
				defaultProps: {
					children: 'children',
					label: 'fileName'
				},
				fileList: [],
				agents: [],
				qyName: '',
				companys: [],
				departments: [],
				users: [],
				recordStatus: [{ label: '全部', value: -1 }, { label: '未拨打', value: 0 }, { label: '已拨打', value: 1 }],
				tableData: [],
				multipleSelection: [],
				delList: [],
				editVisible: false,
				addVisible: false,
				addButton: false,
				userDis: false,
				updateButton: false,
				isNormal: true,
				isSubAgent: false,
				isAdmin: false,
				isAgent: false,
				pageTotal: 0,
				form: {
					companyId: '',
					contactName: '',
					phoneNumber: '',
					userId: '',
					status: '未打',
					nameList: [],
					repeat: true
				},
				rules: {
					contactName: [{ required: true, message: '请输入客户姓名', trigger: 'blur' }],
					phoneNumber: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
					userId: [{ required: true, message: '请选择员工', trigger: 'change' }]
				},
				idx: -1,
				id: -1,
				show: false,
				upload: false,
				loading: false,
				companyDis: false,
				token: { Authorization: localStorage.getItem('token') },
				uploadData: {
					highSeasDataId: ''
				},
				path: process.env.BASE_URL,
				statisticsData: '',
				dialogIntention: false,
				formIntentionPhoneNumber: '',
				formIntention: {
					name: '',
					intention: 'C',
					remark: '',
					isRemark: ''
				},

				blackremark: '其他',
				arrI: 0,
				bodadisabled: false,
				str2: [],
				str: [],
				zidongbohao: false,
				AppInfo: '',
				AppBtn: '关联APP',
				deviceName: null,
				wait_timer: 7,
				timer: null,
				setSecondstimer: null,
				timer_interval: '',
				manualDialing: false, //手动拨号盘是否显示
				manualDialingTab: 'dial', //默认显示的导航号码清单和线索统计clueEcharts  numberList
				phoneNumberSearch: '', //搜索号码
				phone: '',
				callPopup5: false,
				speedsList: [], //手动拨打记录
				nameList: [], //可分配员工
				nameId: '', //已经分配员工id
				repeatText: '去  重',
				isrepeat: false,
				isRemarkKey: false,
				isrepeatBox: false,
				filterBox: false, //导入后的弹出
				filterListData: {},
				callDuration: 0,
				callStatus: false,
				setIntervals: false,
				imgbox: true,
				setSeconds: localStorage.getItem('setSeconds'),
				errortab: false,
				filesRepeatingtab: false,
				enterpriseDuplicationtab: false,
				time: Date.parse(new Date()),

				addPoolVisible: false,
				formPool: {
					name: '',
					departments: ''
				},
				departmentsIds: [],
				editNameFormdepartmentsIds: [],
				fileForm: {
					nameList: [], //可分配员工
					departmentList: [], //可分配部门
					type: '0',
					list: [],
					number: [],
					totals: '',
					totals2: ''
				},
				labelArr: [],
				valueArr: []
			};
		},

		created() {
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (role === 'normal') {
				this.isNormal = false;
			}
			if (role === 'agent') {
				this.isNotAdmin = false;
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'subAgent') {
				this.isAgent = true;
			}
			if (role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
			}

			if (role == 'company') {
				this.isrepeat = true;
			}
			if (this.isAdmin) {
				this.init();
			} else if (this.isAgent) {
				this.init();
			} else {
				this.getcustomerFile();
				this.fetchDepartments();
			}
			setInterval(() => {
				this.deviceName = localStorage.getItem('deviceName');
			}, 1000);
		},

		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
					this.query.agentId = this.agents[0].value;
					this.fetchCompanyByAgentIds(this.query.agentId);
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
				fetchDefaultUsers(localStorage.getItem('user')).then(res => {
					this.users = res.data;
				});
			},
			toDateTimeStr(nS) {
				let date = new Date(parseInt(nS));
				let YY = date.getFullYear() + '-';
				let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return YY + MM + DD + ' ' + hh + mm + ss;
			},
			/* 查询部门 */
			fetchDepartments() {
				console;
				let data = {
					companyId: this.query.companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;

				});
			},

			/* 搜索  */
			getcustomerFile() {
				this.loading = true;
				let data = {
					userId: localStorage.getItem('user'),
					companyId: this.query.companyId,
					fileName: this.query.fileName
				};
				recycieFile(data).then(res => {
					if (res.code == 200) {
						this.loading = false;
						this.menuList = res.data;
						if (this.menuList.length > 0) {
							this.menuList[0].flag = true;
							this.query2.customerFileId = this.menuList[0].id;
							this.editNameForm.customerFileId = this.menuList[0].id;
							this.findByQuerys();
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},

			/* 切换左侧导航的主节点 */
			curShow(item) {
				this.query2.normalFileId = '';
				this.menuList.forEach(i => {
					if (item == i) {
						i.flag = true;
					} else {
						i.flag = false;
					}
				});
				this.query2.normalFileId = item.id;
				this.query.normalFileId = item.id;
				this.departmentList = item.department;
				this.query2.fileName = item.fileName;
				this.fileForm.totals = item.count;
				this.fileForm.totals2 = item.count;
				this.fileName = '分配' + item.fileName + '线索池';
				this.departmentList.forEach(res => {
					if (res.bon) {
						this.departmentsIds.push(res.departmentId);
					}
				});
				this.query2.customerFileId = item.id;
				this.query2.pageIndex = 1;
				this.findByQuerys();
			},
			changedepartmentList(e) {
				this.departmentsIds = e;
			},

			/* 修改主节点名字 */
			getEdit(item) {
				this.departmentsIds = [];
				this.editNameForm.name = item.fileName;
				this.editNameForm.customerFileId = item.id;
				this.departmentList = item.department;
				this.departmentList.forEach(res => {
					if (res.bon) {
						this.departmentsIds.push(res.departmentId);
					}
				});
				this.editName = true;
			},
			getUpdateCustomerFile() {
				let data = {
					highSeasDataId: this.editNameForm.customerFileId,
					userId: localStorage.getItem('user'),
					name: this.editNameForm.name,
					departments: this.departmentsIds.toString()
				};
				ModifyPool(data).then(res => {
					if (res.code == 200) {
						this.$message.success('修改成功');
						this.getcustomerFile();
					} else {
						this.$message.error(res.message);
					}
					this.editName = false;
				});
			},

			/* 切换左侧导航的子节点 */
			getCustomers(id) {
				this.tableData = [];
				this.query2.normalFileId = id;
				this.query.normalFileId = id;
				this.num = id;
				let data = {
					normalFileId: this.query2.normalFileId,
					pageIndex: this.query2.pageIndex,
					pageSize: this.query2.pageSize
				};
				getCustomer(data).then(res => {
					this.tableData = res.data.customers;
					this.pageTotal = res.data.total;
				});
			},
			uploadExcel() {
				this.upload = true;
				let role = localStorage.getItem('ms_role');
				if (role === 'normal' || role === 'admin' || role === 'agent') {
					this.getfindNormal();
				}
			},
			getfindNormal() {
				// 获取可分配数据
				let data = {
					departmentId: this.query.departmentId
				};
				findNormal(data).then(res => {
					if (res.code == 200) {
						this.nameList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getdownloadCustomerTemplate() {
				if (this.isAgent) {
					// 下载模板
					let data = {
						companyId: this.query.companyId
					};
					downloadCustomerTemplate(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '客户模板' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				} else {
					// 下载模板
					let data = {
						companyId: localStorage.getItem('company')
					};
					downloadCustomerTemplate(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '客户模板' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				}
			},

			//分配的员工id字符串化
			handleCheckedCitiesChange2(value) {
				this.labelArr = value;
				if (this.fileForm.type == 0) {
					this.valueArr.push('0');
				}
			},
			handleCheckedCitiesChange3(value) {
				this.uploadData.highSeasDataId = value.toString();
			},
			getData() {
				this.query.currentUserId = localStorage.getItem('user');
				fetchData(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},

			getWebAddress() {
				let data = {
					userName: localStorage.getItem('ms_username')
				};
				getWebAddress(data).then(res => {
					if (res.code == 500) {
						this.$message.error(res.message);
					} else if (res.code == 200) {
						localStorage.setItem('AppInfoIp', res.data.ip);
						let data = {
							userName: localStorage.getItem('ms_username')
						};
						getDevices(data).then(res => {
							if (res.code == 500) {
								this.$message.error(res.message);
							} else {
								this.AppInfo = res.data;
								localStorage.setItem('deviceName', this.AppInfo.deviceName);
								this.$router.push('/clue');
							}
						});
					}
				});
			},

			fn(arr) {
				return arr.filter((item, index, arr) => arr.indexOf(item) === index);
			},

			formatSeconds(result) {
				var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				var m = Math.floor((result / 60) % 60) < 10 ? '0' + Math.floor((result / 60) % 60) : Math.floor((result /
					60) % 60);
				var s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60);
				if (h > 0) {
					return (result = h + ':' + m + ':' + s);
				} else {
					return (result = m + ':' + s);
				}
			},

			/* 删除 */
			batchDel() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					let param = {
						ids: str,
						highSeasDataId: this.query.normalFileId
					};
					// 二次确认删除
					this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
						.then(() => {
							deleteHighSeasData(param).then(res => {
								if (res.code == 200) {
									this.$message.success('批量删除成功');
									this.findByQuerys();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
				}
				this.multipleSelection = [];
			},

			/* 代理商 */
			agentChange(agentId) {
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				this.fetchCompanyByAgentIds(agentId);
			},
			/* 切换代理商企业 */
			fileChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.fetchDepartments();
				this.getcustomerFile();
			},
			/* 代理商下的企业 */
			fetchCompanyByAgentIds(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
						this.query.companyId = this.companys[0].value;
						this.fetchDepartments();
						this.getcustomerFile();
					});
				}
			},
			/* 切换部门 */
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.userId = '';
				this.query2.companyId = companyId;
				this.query2.departmentId = '';
				this.query2.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},
			departmentChange(departmentId) {
				let data = {
					departmentId: departmentId,
					currentUserId: localStorage.getItem('user')
				};
				fetchUsersByDepartmentId(data).then(res => {
					this.users = res.data;
				});
			},
			departmentChange2() {
				this.getfindNormal();
			},
			/* 我是右边搜索 */
			findByQuerys() {
				let data = {
					pageSize: this.query2.pageSize,
					pageIndex: this.query2.pageIndex,
					highSeasDataId: this.query2.customerFileId,
					phoneNumber: this.query.phoneNumber,
					userId: localStorage.getItem('user')
				};
				this.tableData = [];
				customerRecycleData(data).then(res => {
					this.tableData = res.data.pageHelper.list;
					this.pageTotal = res.data.pageHelper.total;
					this.desensitization = res.data.desensitization;
				});
			},

			showForm() {
				this.addVisible = true;
				this.addButton = true;
				this.updateButton = false;
			},

			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							ids: id,
							highSeasDataId: this.query.normalFileId
						};
						deleteHighSeasData(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.tableData.splice(index, 1);
								this.findByQuerys();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},

			// 编辑操作
			handleEdit(index, row) {
				let data = {
					departmentId: row.departmentId,
					currentUserId: localStorage.getItem('user')
				};
				fetchUsersByDepartmentId(data).then(res => {
					this.users = res.data;
				});
				this.addVisible = true;
				this.idx = index;
				this.addButton = false;
				this.userDis = true;
				this.companyDis = true;
				this.updateButton = true;
				// 获取详情
				fetchDataById(row.id).then(res => {
					this.form = res.data;
				});
			},

			// 分页导航
			handlePageChange(val) {
				console.log(val, '我是右边的下一页或者点击页码的');
				this.query2.pageIndex = val;
				// if (this.query2.normalFileId) {
				// 	this.getCustomers(this.query2.normalFileId);
				// } else {
				// 	this.tableData = [];
				// 	this.arrI = 0;
				// 	this.findByQuerys();
				// }
				this.tableData = [];
				this.arrI = 0;
				this.findByQuerys();
			},
			handleSizeChange(val) {
				console.log(val, '我是右边的选择多少条一页的');
				this.query2.pageSize = val;
				// if (this.query2.normalFileId) {
				// 	this.getCustomers(this.query2.normalFileId);
				// } else {
				// 	this.tableData = [];
				// 	this.arrI = 0;
				// 	this.findByQuerys();
				// }
				this.tableData = [];
				this.arrI = 0;
				this.findByQuerys();
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				this.companyDis = false;
				this.addVisible = false;
				this.userDis = false;
				(this.form = {
					companyId: '',
					contactName: '',
					phoneNumber: '',
					userId: '',
					status: '未打'
				}),
				this.resetForm(formName);
			},
			closeUpload() {
				this.$refs.upload.clearFiles();
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					if (this.uploadData.ids == '') {
						this.$message.error('请选择分配的员工');
					} else {
						this.loading = true;
						this.$refs.upload.submit();
					}
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},
			uploadSuccess(success) {
				setTimeout(() => {
					this.loading = false;
					this.$refs.upload.clearFiles();
					this.upload = false;
				}, 2000);
				if (success.code == 200) {
					this.$message.success('上传成功');
					// 	导入成功 35条   success
					//	文件数据重复  2条  repeats
					// 	格式错误 2条   format
					//  企业数据重复 2条  filter
					this.filterBox = true;
					this.filterListData = success.data;
					this.getcustomerFile();
				} else {
					this.open(success.message);
				}
			},
			open(message) {
				this.$alert(message, '导入失败', {
					confirmButtonText: '确定'
				});
			},

			quchong() {
				this.repeatText = '去  重';
				this.isrepeatBox = false;
				this.uploadData.repeat = true;
				this.form.repeat = true;
			},
			buquchong() {
				this.isrepeatBox = false;
				this.repeatText = '不去重';
				this.uploadData.repeat = false;
				this.form.repeat = false;
			},

			seeError() {
				this.errortab = true;
			},
			seeFilesRepeating() {
				this.filesRepeatingtab = true;
			},
			seeEnterpriseDuplication() {
				this.enterpriseDuplicationtab = true;
			},
			changeDepartments(e) {
				console.log(e, 'woshi ,s')
				this.departmentsIds = e;
			},
			subAddPool() {
				let data = {
					name: this.formPool.name,
					companyId: this.query.companyId,
					departments: this.departmentsIds.toString(),
					userId: localStorage.getItem('user')
				};
				creationCluePool(data).then(res => {
					if (res.code == 200) {
						this.addPoolVisible = false;
						this.$message.success(res.data);
						this.getcustomerFile();
						this.formPool = {};
					} else {
						this.$message.error(res.message);
					}
				});
			},
			openaddPoolVisible() {
				this.departmentsIds = [];
				this.addPoolVisible = true;
				this.fetchDepartments();
			},
			//删除线索池
			deletePool(row) {
				this.$confirm('是否要删除此线索池，删除后数据无法恢复，请谨慎操作！！！', '提示', {
						type: 'warning',
						confirmButtonText: '删除',
						cancelButtonText: '不删除',
						closeOnClickModal: false
					})
					.then(() => {
						// 二次确认删除
						this.$confirm('确定要删除吗？', '提示', {
								type: 'warning'
							})
							.then(() => {
								console.log(row, '吧吧v');
								let param = {
									highSeasDataId: row.id
								};
								deleteRecycleFile(param).then(res => {
									if (res.code == 200) {
										this.$message.success('删除成功');
										this.getcustomerFile();
									} else {
										this.$message.error(res.message);
									}
								});
							})
							.catch(() => {});
					})
					.catch(() => {
						// 二次确认删除
						this.$confirm('确定要删除吗？', '提示', {
								type: 'warning'
							})
							.then(() => {
								console.log(row, 'vvvvvv');
								let param = {
									highSeasDataId: row.id
								};
								deleteRecycleFile(param).then(res => {
									if (res.code == 200) {
										this.$message.success('删除成功');
										this.getcustomerFile();
									} else {
										this.$message.error(res.message);
									}
								});
							})
							.catch(() => {});
					});
			},
			// handleCheckedCitiesChange(value) {
			// 				this.fileForm.departmentList = value.toString();
			// 			},
			handleCheckedCitiesChange(value) {
				this.fileForm.nameList = [];
				this.fileForm.number = [];
				if (value) {
					if (value.length > 0) {
						getUserByDepartmentId(value).then(res => {
							if (res.code == 200) {
								this.nameList = res.data;
							} else {
								this.$message.error(res.message);
							}
						});
					} else {
						this.nameList = [];
					}
				}
			},
			closeFileNameDialog() {
				this.fileNameVisible = false;
			},
			getNumber(e) {
				this.valueArr.push(e);
				console.log(this.valueArr, '我是valueArr');
			},

			selectAll() {
				this.valueArr = [];
				this.labelArr = [];
				this.fileForm.nameList = [];
				this.nameList.forEach(res => {
					this.labelArr.push(res.value);
					this.fileForm.nameList.push(res.value);
					if (this.fileForm.type == 0) {
						this.valueArr.push('0');
					}
				});

			},
			notAllselected() {
				this.valueArr = [];
				this.labelArr = [];
				this.fileForm.nameList = [];
			},


			typeChange(e) {
				this.fileForm.departmentList = [];
				this.fileForm.nameList = [];
				this.fileForm.type = e;
				if (e == 1) {
					this.fileForm.number = [];
					this.labelArr = [];
				}
				this.list = [];
				this.valueArr = [];
				this.$refs['fileForm'].resetFields();
			},
			addAllotCustomers() {
				this.list = [];
				for (let i = 0; i < this.labelArr.length; i++) {
					if (this.labelArr[i]) {
						let obj = {};
						obj.label = this.labelArr[i];
						obj.value = this.valueArr[i];
						this.list.push(obj);
					}
				}
				if (this.list.length > 0) {
					let str = '';
					for (let i = 0; i < this.multipleSelection.length; i++) {
						str += this.multipleSelection[i].id + ',';
					}
					let data = {
						highSeasDataId: this.query.normalFileId,
						ids: str,
						type: parseInt(this.fileForm.type),
						totals: this.fileForm.totals2,
						list: this.list
					};

					customerAllocation(JSON.stringify(data)).then(res => {
						this.list = [];
						this.valueArr = [];
						this.fileForm.number = [];
						console.log(data, '我是分配的传的值');
						if (res.code == 200) {
							this.nameList = [];
							this.fileForm.number = [];
							this.fileForm.list = [];
							this.valueArr = [];
							this.labelArr = [];
							this.fileNameVisible = false;
							this.query2.pageIndex = 1;
							this.fileForm.type = '0';
							this.$message.success(res.message);
							this.getcustomerFile();
							this.findByQuerys();
						} else {
							if (res.message) {
								this.$message.error(res.message);
							} else {
								this.$message.error('分配失败');
							}
						}
					});
				} else {
					this.$message.error('请选择分配的员工');
				}
			},
			//导出
			handleExport() {
				if (this.query.normalFileId) {
					let data = {
						highSeasDataId: this.query2.normalFileId,
						userId: localStorage.getItem('user')
					};
					downloadCustomer(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let date = new Date();
						let y = date.getFullYear(); //获取完整的年份(4位)
						let m = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
						let r = date.getDate(); //获取当前日(1-31)
						let fileName = this.query2.fileName + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				} else {
					this.$message.error('请选择线索池');
				}
			},
			getfileNameVisible() {
				if (this.query.normalFileId) {
					if (this.fileForm.totals2 > 0) {
						this.fileNameVisible = true;
						this.fileForm.departmentList = [];
						this.fileForm.nameList = [];
					} else {
						this.$message.error('线索池没有数据，请导入数据后再分配');
					}
				} else {
					this.$message.error('请选择线索池');
				}
			}
		}
	};
</script>
<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}

	.container {
		border: none;
		padding: 3px 15px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.ml10 {
		margin-left: 10px;
	}

	.mb10 {
		margin-bottom: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}

	#my {
		width: 99%;
		background-color: #ffffff;
		overflow-y: auto;
	}

	.tree .item-menuList {
		margin: 50px 10px 10px 10px;
		border: 1px solid #dcdee2;
	}

	.tree .item-menuList .title {
		display: block;
		padding: 10px;
		font-size: 12px;
		position: relative;
	}

	.title-name {
		display: inline-block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 70%;
		background: content-box;
		border: none;
		color: #515a6e;
	}

	.tree .p-item {
		width: 75%;
		margin-left: 40px;
	}

	.tree .p-item li {
		margin-bottom: 10px;
		font-size: 12px;
		position: relative;
		margin-top: 20px;
		border: 1px solid #dcdee2;
	}

	.tree .p-item .p-title {
		padding: 3px 0 0 10px;
		box-sizing: border-box;
		display: inline-block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 85%;
		display: block;
		cursor: pointer;
		color: #515a6e;
	}

	.tree .p-item .icon1 {
		display: inline-block;
		position: absolute;
		top: 28px;
		width: 25px;
		height: 2px;
		left: -30px;
		background-color: #ccc;
	}

	.tree .p-item .icon {
		display: inline-block;
		position: absolute;
		left: -30px;
		top: -15px;
		width: 2px;
		height: 45px;
		background-color: #ccc;
	}

	.tree .title.active1 {
		background-color: #e6f7ff;
		cursor: pointer;
		border: 1px solid #4f7afd;
	}

	.tree .p-item li:hover {
		cursor: pointer;
	}

	.tree .p-item li.blur {
		background-color: #e6f7ff;
		cursor: pointer;
		border: 1px solid #4f7afd;
	}

	.tree .p-item .el-progress {
		margin: 5px 10px !important;
		font-size: 12px !important;
	}

	.statistics-box .list {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #c2c2c2;
		padding-bottom: 16px;
	}

	.statistics-box .list .tit {
		color: rgba(0, 0, 0, 0.45);
		font-size: 12px;
		line-height: 22px;
		align-self: flex-start;
	}

	.statistics-box .list .num {
		color: rgba(0, 0, 0, 0.85);
		font-size: 24px;
		line-height: 38px;
		align-self: flex-start;
	}

	.dial-box {
		height: 450px;
		background: #ffffff;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.dial-box .dial_list {
		width: 60px;
		height: 60px;
		font-size: 32px;
		border-radius: 50%;
		color: #333333;
		line-height: 60px;
		text-align: center;
		overflow: hidden;
		margin-right: 20px;
		margin-left: 20px;
	}

	.search {
		outline: none;
		background-color: #fff;
		background-image: none;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		box-sizing: border-box;
		color: #606266;
		display: inline-block;
		height: 40px;
		line-height: 40px;
		outline: 0;
		padding: 0 15px 0 34px;
		transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		width: 100%;
	}

	.search:hover {
		border: 1px solid #c0c4cc;
	}

	.search:focus {
		border-color: #4f7afd;
		outline: 0;
	}

	.dial-box .input-box {
		width: 100%;
		text-align: center;
		display: flex;
		flex-direction: row;
	}

	.dial-box .input-box input {
		width: 98%;
		margin-top: 6px;
		outline: none;
		border: none;
		text-align: right;
		background-color: #ffffff;
	}

	.dial-box .input-box div {
		background-color: rgb(234, 245, 255);
		text-align: center;
		display: initial;
	}

	.dial-box .dial-btn {
		width: 100%;
		height: 40px;
		display: block;
		text-align: center;
	}

	.dial_list:active {
		color: #2c7ffb;
		background: #2c7ffb;
		background: rgba(44, 127, 251, 0.1);
	}

	.record-box {
		height: 400px;
		overflow-y: scroll;
		scrollbar-width: none;
		/* firefox */
		-ms-overflow-style: none;
		/* IE 10+ */
	}

	.record-box::-webkit-scrollbar {
		display: none;
		/* Chrome Safari */
	}

	.record-box li {
		border-bottom: 1px solid #ccc;
	}

	.record-box li .phone {
		font-size: 12px;
		margin-top: 10px;
	}

	.record-box li .info {
		display: flex;
		flex-direction: row;
		margin-top: 10px;
	}

	.record-box li .info .call-time {
		margin-left: 20px;
	}

	.record-box li .status-box {
		display: flex;
		flex-direction: row;
		font-size: 12px;
		margin-top: 10px;
	}

	.record-box li .status-box .call-status {
		width: 60px;
		height: 22px;
		font-size: 12px;
		line-height: 22px;
		text-align: center;
		margin-right: 16px;
		color: #fff;
		border-radius: 4px;
	}

	.record-box li .status-box .intention {
		width: 80px;
		height: 22px;
		font-size: 12px;
		line-height: 22px;
		text-align: center;
		margin-right: 16px;
		border-radius: 4px;
		color: #fff;
	}

	.record-box li .status-box .call-duration {
		width: 60px;
		height: 22px;
		font-size: 12px;
		line-height: 22px;
		text-align: center;
		margin-right: 16px;
		border-radius: 4px;
		background-color: #f3dec3;
		color: #cead82;
	}

	.record-box li .remark {
		font-size: 12px;
		margin: 10px 0;
	}

	.call-type5 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.call-type5 .input-box {
		height: 60px;
		border-bottom: 1px solid #ccc;
	}

	.call-type5 .black-radio {
		position: relative;
		top: -15px;
		border: 1px solid #4f7afd;
		padding: 10px;
	}

	.call-type5 .black-radio .title {
		font-size: 16px;
		color: #000;
	}

	.call-type5 .black-radio .el-radio-group {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}

	.call-type5 .black-radio .el-radio-group .el-radio {
		margin: 6px 2px;
	}

	.call-type5 .btn-box {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 30px;
	}

	.call-type5 .btn-box button {
		width: 84px;
		height: 34px;
		margin: 0;
		padding: 0;
		line-height: 34px;
		text-align: center;
		border-radius: 8px;
		color: #fff;
		font-size: 16px;
		background-color: #007bfe;
		border: none;
	}

	.filter-box div {
		display: flex;
		flex-direction: row;
		line-height: 50px;
		height: 50px;
	}

	::v-deep .el-radio__inner {
		border-radius: 2px;
	}

	::v-deep .el-radio__input.is-checked .el-radio__inner::after {
		content: '';
		width: 10px;
		height: 5px;
		border: 1px solid white;
		border-top: transparent;
		border-right: transparent;
		text-align: center;
		display: block;
		position: absolute;
		top: 2px;
		left: 0px;
		vertical-align: middle;
		transform: rotate(-45deg);
		border-radius: 0px;
		background: none;
	}

	.call-type6 .black-radio {
		display: flex;
		flex-direction: row;
	}

	.call-type6 .black-radio .title {
		font-size: 16px;
		color: #000;
		line-height: 40px;
		margin-right: 20px;
	}

	.call-type6 .black-radio .el-radio-group {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}

	.call-type6 .black-radio .el-radio-group .el-radio {
		margin: 6px 2px;
	}

	.call-type6 .btn-box {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 30px;
	}

	.tab-span {
		display: inline-block;
		display: flex;
		flex-direction: row;
		padding: 3px 6px;
		margin: 4px 6px;
	}

	.icon-class {
		cursor: pointer;
	}

	.icon-class span {
		color: #00ff00;
		font-weight: 600;
		text-decoration: underline;
	}

	.filter-box i {
		font-size: 30px;
		margin-right: 10px;
		line-height: 50px;
	}

	.img-box {
		display: flex;
		float: right;
		background: #4f7afd;
		color: #fff;
		border-radius: 4px;
		padding: 0 12px;
		height: 32px;
		position: relative;
		line-height: 32px;
		top: 4px;
		margin-left: 10px;
		cursor: pointer;
	}

	.img-box1 {
		height: 33px;
		position: relative;
		line-height: 33px;
		top: 0;
	}

	.img-box img {
		width: 26px;
		height: 26px;
		margin: 4px 6px;
	}

	.radio-box {
		display: flex;
		flex-direction: column;
		width: 96%;
		height: 200px;
		justify-content: space-between;
		padding-left: 20px;
	}

	.radio-box .radio-tit {
		font-size: 16px;
		margin-top: 20px;
	}

	.radio-box .radio-price {
		font-size: 16px;
	}

	.radio-box .radio-tip {
		margin-right: 40px;
		border-top: 1px dashed #ccc;
		display: flex;
		/* background-image: url(../assets/img/cluePool-bg.png); */
		background-repeat: no-repeat;
		background-size: 220px 40px;
		background-position: center;
		height: 40px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 10px 0;
	}

	.radio-box .radio-tip i {
		font-size: 16px;
		margin-top: 20px;
	}

	.tip {
		position: absolute;
		top: -33px;
		background-color: linear-gradient(to bottom right, #fb815d, #f93e13);
		width: 90px;
		height: 30px;
		border-radius: 25px 0px 25px 0px;
		text-align: center;
		line-height: 30px;
		color: #fff;
		left: -1px;
	}
</style>
<style>
	.statistics-box .el-progress__text {
		display: flex;
		flex-direction: column;
	}

	.statistics-box .el-progress__text .percentage-label {
		color: rgba(0, 0, 0, 0.85);
		margin-top: 16px;
	}

	.clue .el-upload {
		width: 100% !important;
		height: 140px !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}

	.clue .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}

	.el-progress-bar__inner {
		background-image: linear-gradient(to right, rgb(16, 142, 233) 0%, rgb(14, 237, 25) 100%) !important;
	}

	.el-dialog .el-dialog__body .dialog-title {
		display: flex !important;
		flex-direction: column !important;
		justify-content: center !important;
		text-align: center !important;
		position: relative !important;
		top: -34px !important;
	}

	.el-dialog .el-dialog__body .dialog-title i {
		font-size: 50px !important;
		color: #007bfe !important;
	}

	.el-dialog .el-dialog__body .dialog-title .title {
		font-size: 22px;
		font-weight: 500;
		margin: 6px 0;
	}

	.el-dialog .el-dialog__body .dialog-title .time {
		font-weight: 500;
		font-size: 20px;
		color: rgb(16, 142, 233);
	}

	.input-box .el-input__inner {
		height: 50px !important;
		line-height: 50px !important;
		font-size: 24px !important;
		color: #333 !important;
	}
</style>