<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable
					placeholder="请选择代理商" class="handle-select mb10 m_r_10" v-if="isAdmin">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select m_r_10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="query.companyId" @change="getinventoryFindAssigned()" filterable
					clearable placeholder="请选择企业" class="handle-select m_r_10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.consume" filterable clearable placeholder="请选择消费类型"
					class="handle-select m_r_10">
					<el-option v-for="item in consumeList" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.phone" filterable clearable placeholder="请选择号码"
					class="handle-select m_r_10">
					<el-option v-for="item in phoneList" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-date-picker v-model="timeArr" type="daterange" style="width: 260px;margin-right: 10px;"
					unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					@change="getTime()" size="small"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" @click="getData()">搜索</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column prop="companyName" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="被叫号码" align="center" width="130"></el-table-column>
				<el-table-column prop="type" label="消费类型" align="center"></el-table-column>
				<el-table-column prop="userPhone" label="主叫号码" align="center" width="130"></el-table-column>
				<el-table-column prop="variableMinutes" label="消费时长" align="center">
					<template #default="scope">
						{{ scope.row.variableMinutes }}分钟
					</template>
				</el-table-column>
				<el-table-column prop="minutes" label="剩余时长" align="center">
					<template #default="scope">
						{{ scope.row.minutes || 0 }}分钟
					</template>
				</el-table-column>
				<el-table-column prop="time" label="时间" align="center" width="180"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 90, 200]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		callBackSetMealExpenseDetail,
		inventoryFindAssigned
	} from '../api/setMeal.js';
	import {
		fetchDefaultCompanies,
		fetchCompanyByAgentId
	} from '../api/companyIndex';
	import {
		querySubAgent
	} from '../api/serialIndex.js';
	import {
		fetchAgent
	} from '../api/agentIndex';
	import qs from 'qs';
	import QRCode from 'qrcodejs2';
	export default {
		name: 'company',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					userId: localStorage.getItem('user'),
					phone: '',
					consume: '',
					pageIndex: 1,
					pageSize: 30,
					subAgentId: ''
				},
				timeArr: [],
				title: '购买套餐',
				phoneList: [],
				tableData: [],
				pageTotal: 0,
				agents: [],
				companys: [],
				purchaseForm: {
					phone: '',
					setMealId: ''
				},
				disabled: true,
				bindingVisible: false,
				purchaseVisible: false,
				setMealListData: [],
				weixinUrl: '',
				url: '',
				total_fee: '',
				out_trade_no: '',
				qr: '',
				payList: [{
						value: 'wxpay',
						label: '微信支付'
					},
					{
						value: 'alipay',
						label: '支付宝支付'
					}
				],
				consumeList: [{
						value: 2,
						label: '购买套餐'
					},
					{
						value: 3,
						label: '购买加量包'
					},
				],
				clearInterval: null,
				isAdmin: false,
				isAgent: false,
				isSubAgent: false
			};
		},

		created() {
			this.role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (this.role === 'agent') {
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (this.role === 'subAgent') {
				this.isAgent = true;
			}
			if (this.role === 'admin') {
				this.isAdmin = true;
			}

			this.init();
			this.getTime();
		},
		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				//代理商下拉
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.userId = '';
				//企业下拉
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},

			getData() {
				let data = {
					userId: localStorage.getItem('user'),
					agentId: this.query.agentId,
					companyId: this.query.companyId,
					phone: this.query.phone,
					time: this.toDateTimeStr(this.timeArr[0]),
					endTime: this.toDateTimeStr(this.timeArr[1]),
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
					consume: this.query.consume,
					type: 'web'
				};
				callBackSetMealExpenseDetail(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getinventoryFindAssigned() {
				let data = {
					companyId: this.query.companyId,
					phone: this.query.phone,
					userId: localStorage.getItem('user'),
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize
				};
				inventoryFindAssigned(data).then(res => {
					if (res.code == 200) {
						res.data.list.forEach(item => {
							let obj = {
								value: item.phone,
								label: item.phone
							};
							this.phoneList.push(obj);
						});
						this.getData()
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getTime() {
				let startTime = new Date(new Date());
				let endTime = new Date();
				this.timeArr.push(startTime);
				this.timeArr.push(endTime);
			},
			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}

				return y + '-' + m + '-' + d;
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}
</style>