<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="请选择代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-if="isAgent" v-model="query.companyId"
					@change="companyChange" filterable clearable v-show="isNormal" placeholder="企业"
					class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.departmentId" @change="departmentChange" filterable clearable
					v-show="isNormal" placeholder="部门" class="handle-select mr10"
					style="width: 180px;margin: 10px 10px 0 0">
					<el-option v-for="item in departments" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.userId" filterable clearable v-show="isNormal" placeholder="员工"
					class="handle-select mr10" style="width: 180px;margin: 10px 10px 0 0">
					<el-option v-for="item in users" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-show="isNormal" v-model="query.cardType" filterable clearable
					placeholder="运营商" class="handle-select mr10">
					<el-option v-for="item in cardTypeData" :key="item.value" :label="item.value"
						:value="item.label"></el-option>
				</el-select>
				<el-form label-width="120">
					<div class="form-items-wrapper">
						<el-form-item label="是否接通">
							<el-select size="small" v-model="query.through" filterable clearable placeholder="是否接通"
								class="mr10">
								<el-option label="已接通" :value="true">已接通</el-option>
								<el-option label="未接通" :value="false">未接通</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="拨打方式">
							<el-select size="small" v-model="query.callType" filterable clearable placeholder="拨打方式"
								class="mr10">
								<el-option label="APP快拨" :value="1">APP快拨</el-option>
								<el-option label="APP自动拨号" :value="2">APP自动拨号</el-option>
								<el-option label="小程序快拨" :value="3">小程序快拨</el-option>
								<el-option label="小程序自动拨号" :value="4">小程序自动拨号</el-option>
								<el-option label="电脑拨号" :value="5">电脑拨号</el-option>
								<el-option label="公海数据" :value="6">公海数据</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="是否备注">
							<el-select size="small" v-model="query.isRemark" filterable clearable placeholder="是否备注"
								class="mr10">
								<el-option label="是" :value="true">是</el-option>
								<el-option label="否" :value="false">否</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="客户意向">
							<el-select size="small" v-model="query.intention" filterable clearable placeholder="客户意向"
								class="mr10">
								<el-option label="A: 意向强" value="A: 意向强">A: 意向强</el-option>
								<el-option label="B: 意向一般" value="B: 意向一般">B: 意向一般</el-option>
								<el-option label="C: 无意向" value="C: 无意向">C: 无意向</el-option>
								<el-option label="D: 需再跟进" value="D: 需再跟进">D: 需再跟进</el-option>
								<el-option label="E: 无需跟进" value="E: 无需跟进">E: 无需跟进</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="通话时长">
							<el-select size="small" v-model="query.callDuration" filterable clearable placeholder="通话时长"
								class="mr10">
								<el-option label="大于10秒" :value="10">大于10秒</el-option>
								<el-option label="大于30秒" :value="30">大于30秒</el-option>
								<el-option label="大于1分钟" :value="60">大于1分钟</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="时间">
							<el-date-picker v-model="timeArr" type="daterange" unlink-panels range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime()" size="small"
								class="mr10" style="width: 260px;"></el-date-picker>
						</el-form-item>

						<div v-for="(item, index) in labelList" :key="index">
							<el-form-item :label="item.label">
								<el-select size="small" v-model="item.selected" @change="getLabel(item)" :key="labelKey"
									clearable :placeholder="'请选择'+item.label" class="mr10">
									<el-option v-for="(option, optionIndex) in item.value"
										:key="`radio-${index}-${optionIndex}`" :label="option.str"
										:value="option.str"></el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div>
					<el-input size="small" v-model="query.phone" clearable maxlength="12" placeholder="请输入客户联系方式"
						class="handle-input mr10" style="width: 180px;margin: 10px 10px 0 0;"></el-input>
				</div>
			</div>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" style="margin:0 20px 20px 0;"
				@click="handleSearch">搜索</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2  el-icon--right"
				style="margin:0 20px 20px 0;" @click="exportData">导出</el-button>
			<el-row>
				<el-col :span="24">
					<el-table :data="tableData" border class="table" ref="multipleTable" v-loading="loading"
						header-cell-class-name="table-header" :cell-style="{ cursor: 'pointer', padding: '6px' }"
						:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
						@row-click="showData" :row-class-name="tableRowClassName" height="420">
						<el-table-column prop="customerName" label="客户姓名" align="center"></el-table-column>
						<el-table-column prop="phoneNumber" label="客户手机号" align="center" width="130"></el-table-column>
						<el-table-column prop="callDate" label="拨打时间" align="center" width="190"></el-table-column>
						<el-table-column prop="userName" label="员工姓名" align="center" width="130"></el-table-column>
						<el-table-column prop="userPhone" label="员工号码" align="center" width="130"></el-table-column>
						<el-table-column prop="callDuration" label="通话时长(s)" align="center"
							width="100"></el-table-column>
						<el-table-column prop="recordPath" label="录音" align="center" width="450">
							<template #default="scope">
								<div style="display: flex;flex-direction: row;">
									<audio-player ref="audio" class="audio-box" :fileurl="scope.row.recordPath"
										:row="scope.row"></audio-player>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="cardType" label="运营商" align="center" width="100"></el-table-column>
						<el-table-column prop="label" label="标签" align="center" width="200">
							<template #default="scope">
								<el-tag v-for="item in scope.row.label" :key="item" type="success" size="small"
									style="margin-right: 3px;margin-bottom: 6px;">{{ item }}</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="remark" label="备注" align="center" width="100"
							show-overflow-tooltip></el-table-column>
						<el-table-column prop="intention" label="客户意向" align="center" width="140">
							<template #default="scope">
								<div v-if="scope.row.intention == 'A: 意向强'"
									style="color: #fff;background-color: #4FFD58;border-radius: 4px;width: 76px;text-align: center;margin: 0 auto;">
									意向强
								</div>
								<div v-if="scope.row.intention == 'B: 意向一般'"
									style="color: #fff;background-color: #fac858;border-radius: 4px;width: 76px;text-align: center;margin: 0 auto;">
									意向一般
								</div>
								<div v-if="scope.row.intention == 'C: 无意向'"
									style="color: #fff;background-color: #C1CFC2;border-radius: 4px;width: 76px;text-align: center;margin: 0 auto;">
									无意向
								</div>
								<div v-if="scope.row.intention == 'D: 需再跟进'"
									style="color: #fff;background-color: #73C0De;border-radius: 4px;width: 76px;text-align: center;margin: 0 auto;">
									需再跟进
								</div>
								<div v-if="scope.row.intention == 'E: 无需跟进'"
									style="color: #fff;background-color: #EE6666;border-radius: 4px;width: 76px;text-align: center;margin: 0 auto;">
									无需跟进
								</div>
								<div v-if="scope.row.intention == '风险号码'"
									style="color: #fff;background-color: #ff0000;border-radius: 4px;width: 76px;text-align: center;margin: 0 auto;">
									风险号码
								</div>
								<div v-if="scope.row.intention == '黑名单'"
									style="color: #fff;background-color: black;border-radius: 4px;width: 76px;text-align: center;margin: 0 auto;">
									黑名单
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="callSoftware" label="拨打方式" align="center" width="140"></el-table-column>
					</el-table>
				</el-col>
			</el-row>

			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 300, 500, 1000]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<div class="drawer-box" :modal-append-to-body="false" append-to-body>
			<el-drawer title="客户详情" v-model="drawer" direction="rtl" size="65%" :before-close="handleClose"
				modal="false" :with-header="false" :destroy-on-close="true">
				<div class="details-box">
					<div class="header">
						<i class="el-icon-right"
							style="font-size: 24px; color: rgba(0, 0, 0, 0.65);font-weight: 600;cursor:pointer;margin-left: 10px;"
							@click="drawer = false"></i>
						<div class="ivu-divider ivu-divider-vertical ivu-divider-default"></div>
						<span style="">客户详情</span>
					</div>
					<div class="user-info">
						<div class="ivu-card-body">
							<div class="customer-id">
								<i class="el-icon-s-custom"
									style="font-size: 30px; color:#4f7afd;font-weight: 600;margin-left: 10px;"></i>
								<div>
									<span class="id-font">归属地：{{ customerDetails.phoneModel }}</span>
								</div>
							</div>
							<div class="btn">
								<el-button type="primary" :class="[theme]" size="small"
									@click="showCRMVisible">转入CRM</el-button>
								<span class="id-font">所属人：{{ customerDetails.userName }}</span>
							</div>
						</div>
					</div>
					<div class="content">
						<div class="left">
							<div class="ivu-card">
								<div class="ivu-card-head">
									<p>客户意向</p>
								</div>
								<div class="ivu-card-body">{{ customerDetails.intention }}</div>
							</div>
							<div class="ivu-card">
								<div class="ivu-card-head">用户信息</div>
								<div class="ivu-card-extra"><i class="el-icon-edit"
										style="font-size: 16px; color: #4f7afd;" @click="addVisible = true"></i></div>
								<div class="ivu-card-body" style="height: 300px;flex-direction: column;">
									<div class="cell-item">
										<span class="cell-title">客户名称</span>
										<span class="cell-desc"
											@click="copyUrl(customerDetails.customerName)">{{ customerDetails.customerName }}</span>
									</div>
									<div class="cell-item">
										<span class="cell-title">客户手机号</span>
										<span class="cell-desc"
											@click="copyUrl(customerDetails.phoneNumber)">{{ customerDetails.phoneNumber }}</span>
									</div>
									<div class="cell-item">
										<span class="cell-title">备注</span>
										<span class="cell-desc"
											@click="copyUrl(customerDetails.remark)">{{ customerDetails.remark || '无' }}</span>
									</div>
								</div>
							</div>
							<div class="ivu-card">
								<div class="ivu-card-body" style="justify-content: space-between;">
									<span class="short-action" :class="{ disabledAction: prevDis == false }"
										@click="prev">
										<i class="el-icon-arrow-left"></i>
										上一条
									</span>
									<span class="short-action" :class="{ action: nextDis == false }" @click="next">
										下一条
										<i class="el-icon-arrow-right"></i>
									</span>
								</div>
							</div>
						</div>
						<div class="right">
							<el-tabs v-model="activeName" @tab-click="handleClick">
								<el-tab-pane label="通话记录" name="callRecord">
									<div class="call-record-box">
										<div class="call-left">
											<div class="item-info">
												<span class="lat">外呼坐席</span>
												<span>{{ customerDetails.userName }}</span>
											</div>
											<div class="item-info">
												<span class="lat">坐席号码</span>
												<span>{{ customerDetails.userPhone }}</span>
											</div>
											<div class="item-info">
												<span class="lat">任务名称</span>
												<span>{{ customerDetails.normalFileName }}</span>
											</div>
											<div class="item-info">
												<span class="lat">通话状态</span>
												<span>{{ customerDetails.callStatus }}</span>
											</div>
											<div class="item-info">
												<span class="lat">拨打方式</span>
												<span>{{ customerDetails.callSoftware }}</span>
											</div>
											<div class="item-info">
												<span class="lat">手机型号</span>
												<span>{{ customerDetails.deviceBrand }}</span>
											</div>
											<div class="item-info">
												<span class="lat">客户意向</span>
												<span>{{ customerDetails.intention }}</span>
											</div>
											<div class="item-info">
												<span class="lat">拨打时间</span>
												<span>{{ customerDetails.callDate }}</span>
											</div>
											<el-tag>通话{{ customerDetails.callDuration }}秒</el-tag>
										</div>
										<div class="call-right">
											<audio-player ref="audio" class="audio-box"
												:fileurl="customerDetails.recordPath"
												:row="customerDetails"></audio-player>
										</div>

									</div>
								</el-tab-pane>
								<el-tab-pane label="历史记录" name="followUpRecord">
									<el-row>
										<el-col :span="24">
											<el-table :data="followUpRecordData" border class="table"
												ref="multipleTable" v-loading="loading"
												header-cell-class-name="table-header" @row-click="showData2"
												:header-cell-style="{ 'text-align': 'center' }">
												<el-table-column prop="phoneNumber" label="跟进时间"
													align="center"></el-table-column>
												<el-table-column prop="callDate" label="跟进方式"
													align="center"></el-table-column>
												<el-table-column prop="userName" label="跟进记录内容"
													align="center"></el-table-column>
												<el-table-column prop="userPhone" label="添加人员"
													align="center"></el-table-column>
												<el-table-column label="操作" width="190" align="center">
													<template #default="scope">
														<el-button size="small" :disabled="isNotAdmin" type="text"
															icon="el-icon-delete" style="color: #f56c6c;"
															@click="handleDelete(scope.$index, scope.row.id)">
															删除
														</el-button>
													</template>
												</el-table-column>
											</el-table>
										</el-col>
									</el-row>
								</el-tab-pane>
								<el-tab-pane label="添加回访" name="explain"></el-tab-pane>
							</el-tabs>
						</div>
					</div>
				</div>
			</el-drawer>
		</div>

		<el-dialog title="编辑客户信息" v-model="addVisible" width="550px" @close="closeDialog('form2')"
			:close-on-click-modal="false">
			<el-form :model="form2" :rules="rules" ref="form2" label-width="100px">
				<el-form-item label="客户姓名" prop="customerName"><el-input size="small"
						v-model="form2.customerName"></el-input></el-form-item>
				<el-form-item label="客户手机号" prop="phoneNumber"><el-input size="small" v-model="form2.phoneNumber"
						:disabled="true"></el-input></el-form-item>
				<el-form-item label="客户意向" prop="intention">
					<el-select size="small" v-model="form2.intention" placeholder="请选择用户意向" style="width: 100%">
						<el-option v-for="item in intentionList" :key="item.label" :label="item.label"
							:value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<div v-if="labelList.length > 0">
					<div v-show="showForm">
						<el-form-item v-for="(item, index) in labelList" :key="index" :label="item.label">
							<el-checkbox-group v-model="form2.label" :key="labelKey">
								<el-checkbox v-for="(radio, radioIndex) in item.value" :key="radioIndex"
									:label="radio.str">{{ radio.str }}</el-checkbox>
							</el-checkbox-group>
						</el-form-item>

					</div>
				</div>
				<el-form-item label="备注"><el-input size="small" type="textarea"
						v-model="form2.remark"></el-input></el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
					<el-button size="small" @click="addVisible = false">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="getUpdateRecord('form2')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog title="转入CRM客户" v-model="CRMVisible" width="500px" @close="closeDialog('CRMform')"
			:close-on-click-modal="false">
			<el-form :model="CRMform" :rules="rules" ref="CRMform" label-width="100px">
				<el-form-item label="客户姓名" prop="customerName"><el-input size="small"
						v-model="CRMform.customerName"></el-input></el-form-item>
				<el-form-item label="电话号码" prop="phoneNumber"><el-input size="small"
						v-model="CRMform.phoneNumber"></el-input></el-form-item>
				<el-form-item label="性别" prop="gender">
					<el-select size="small" v-model="CRMform.gender" filterable clearable placeholder="请选择性别"
						style="width: 100%;" :disabled="roleDis">
						<el-option key="1" label="男" value="男"></el-option>
						<el-option key="2" label="女" value="女"></el-option>
					</el-select>
				</el-form-item>
				<div v-for="(item, index) in customList" :key="index">
					<el-form-item :label="item.label">
						<div v-if="item.type == 'multiline-text'"><el-input size="small" v-model="item.textVal"
								type="text" placeholder="我是文本"></el-input></div>
						<div v-if="item.type == 'date'"><el-date-picker size="small" v-model="item.textVal"
								value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker></div>
						<div v-if="item.type == 'time'">
							<el-time-select size="small" type="date" v-model="item.textVal" placeholder="选择时间"
								style="width: 100%"></el-time-select>
						</div>
						<div v-if="item.type == 'radio-drop-down-box'">
							<el-radio :label="items" v-for="items in item.options" :key="items.index"
								v-model="item.textVal"></el-radio>
						</div>
						<div v-if="item.type == 'multi-select-drop-down-box'">
							<el-checkbox-group v-model="item.multiSelectDropDownBox">
								<el-checkbox :label="items" v-for="items in item.options"
									:key="items.index"></el-checkbox>
							</el-checkbox-group>
						</div>
						<div v-if="item.type == 'region'">
							<el-cascader v-model="addressSelections" :options="addressData" @change="handleAddress"
								placeholder="请选择省市区" filterable />
							<el-input size="small" v-model="detail" placeholder="请输入地址"
								@change="handleChangedetail"></el-input>
						</div>
					</el-form-item>
				</div>
				<el-form-item label="备注"><el-input size="small" type="textarea"
						v-model="form.remark"></el-input></el-form-item>
				<el-form-item label="部门" v-show="isNormal">
					<el-select size="small" v-model="query.departmentId" @change="departmentChange" filterable clearable
						placeholder="部门" class="handle-select mr10" style="width: 100%">
						<el-option v-for="item in departments" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="跟进人" prop="normalName" v-show="isNormal">
					<el-select size="small" v-model="form.normalName" filterable clearable placeholder="员工"
						class="handle-select mr10" style="width: 100%">
						<el-option v-for="item in users" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button size="small" style="margin-left: 55%;" @click="CRMVisible = false">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="getAddIntentionCustomer('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		delRecord,
		list,
		download,
		findByRecordId,
		updateRecord,
		recorddownloadRecord,
		recordLabel,
		findRecordingPath,
		addIntentionCustomer
	} from '../api/recordIndex';
	import { fetchDepartment, fetchUsersByDepartmentId, fetchDefaultUsers } from '../api/index.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	import { getwebListCustomize } from '../api/tag.js';
	import AudioPlayer from '../components/audioPlayer.vue';
	import { querySubAgent } from '../api/serialIndex.js';
	import { getCardType } from '../api/cardType.js';
	export default {
		name: 'record',
		components: {
			AudioPlayer
		},
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					departmentId: '',
					userId: '',
					pageIndex: 1,
					pageSize: 10,
					through: '',
					callType: '',
					isRemark: '',
					label: '',
					subAgentId: '',
					cardType: ""
				},
				cardTypeData: [],
				labelList: [],
				fileList: [],
				tableData: [],
				agents: [],
				companys: [],
				departments: [],
				users: [],
				multipleSelection: [],
				delList: [],
				followUpRecordData: [], //跟进数据
				returnVisitData: [], //回访数据
				isSubAgent: false,
				editVisible: false,
				addVisible: false,
				addButton: false,
				updateButton: false,
				isNormal: true,
				isNotAdmin: true,
				isAdmin: false,
				drawer: false,
				isAgent: false,
				pageTotal: 0,
				form: {
					companyName: '',
					contactName: '',
					phoneNumber: '',
					userId: '',
					status: '未打'
				},
				form2: {
					customerName: '',
					phoneNumber: '',
					intention: '',
					remark: '',
					label: ''
				},
				intentionList: [{
						label: 'A: 意向强',
						value: 0
					},
					{
						label: 'B: 意向一般',
						value: 1
					},
					{
						label: 'C: 无意向',
						value: 2
					},
					{
						label: 'D: 需再跟进',
						value: 3
					},
					{
						label: 'E: 无需跟进',
						value: 4
					}
				],

				idx: -1,
				id: -1,
				loading: false,
				token: {
					Authorization: localStorage.getItem('token'),
					UserRole: localStorage.getItem('ms_role')
				},
				path: process.env.BASE_URL,
				timeArr: [],
				activeName: 'callRecord',
				customerDetails: '',
				idArr: '',
				rowIndex: '',
				lastId: '',
				nextId: '',
				prevDis: false,
				nextDis: false,
				showForm: true,
				CRMVisible: false,
				CRMform: {
					normalName: '',
					phoneNumber: '',
					gender: '',
					customerCompany: '',
					mailbox: '',
					customerIdentity: '',
					remark: '',
					customerName: '',
					source: '手动添加',
					userId: localStorage.getItem('user'),
					multilineText: '',
					customDate: '',
					customTime: '',
					radioDropDownBox: '',
					multiSelectDropDownBox: [],
					customize: []
				},
				customList: [],
				customize: {},
				role: ''
			};
		},

		created() {
			this.role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (this.role === 'normal') {
				this.isNormal = false;
				this.getrecordLabel();
			}
			if (this.role === 'agent') {
				this.isAgent = true;
				this.isNotAdmin = false;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (this.role === 'subAgent') {
				this.isAgent = true;
			}
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
				this.isNotAdmin = false;
			}
			if (this.role === 'company') {
				this.getrecordLabel();
			}
			this.getTime();
			this.init();
			this.getCardTypes();
		},

		methods: {
			//品牌
			getCardTypes() {
				getCardType().then(res => {
					if (res.code == 200) {
						this.cardTypeData = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
				fetchDefaultUsers(localStorage.getItem('user')).then(res => {
					this.users = res.data;
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.userId = '';
				this.form2.companyId = companyId;
				this.form2.departmentId = '';
				this.form2.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
				this.getrecordLabel(companyId);
			},
			getrecordLabel(companyId) {
				this.labelList = [];
				let data = {
					companyId: companyId,
					userId: localStorage.getItem('user')
				};
				recordLabel(data).then(res => {
					res.data.forEach(i => {
						i.selected = '';
						this.labelList.push(i)
					})
				});
			},
			getLabel(value) {
				this.query.label = this.labelList.map(item => {
						// 查找当前item的value数组中是否有与selected匹配的str  
						const match = item.value.find(val => val.str === item.selected);
						// 如果找到了匹配项（或者selected本身不为空），则返回selected值  
						return match ? item.selected : '';
					})
					.filter(selected => selected !== '') // 过滤掉空值  
					.join(','); // 使用逗号连接值 
			},
			getThrough(value) {
				this.query.through = this.query.through == value ? '' : value;
				this.throughKey = !this.throughKey;
			},

			getCallType(value) {
				this.query.callType = this.query.callType == value ? '' : value;
				this.callTypeKey = !this.callTypeKey;
				console.log(this.callTypeKey, 'this.callTypeKey');
				console.log(this.query.callType, 'this.query.callType');
			},

			getisRemark(value) {
				this.query.isRemark = this.query.isRemark == value ? '' : value;
				this.isRemarkKey = !this.isRemarkKey;
				console.log(this.isRemarkKey, 'this.isRemarkKey');
				console.log(this.query.isRemark, 'this.query.isRemark');
			},
			getIntention(value) {
				this.query.intention = this.query.intention == value ? '' : value;
				this.intentionKey = !this.intentionKey;
			},
			getCallDuration(value) {
				this.query.callDuration = this.query.callDuration == value ? '' : value;
				this.callDurationKey = !this.callDurationKey;
			},
			departmentChange(departmentId) {
				let data = {
					departmentId: departmentId,
					currentUserId: localStorage.getItem('user')
				};
				fetchUsersByDepartmentId(data).then(res => {
					this.users = res.data;
					this.query.userId = '';
					this.form.normalName = '';
					this.CRMform.normalName = '';
				});
			},

			copyUrl(item) {
				let cInput = document.createElement('input');
				cInput.value = item;
				document.body.appendChild(cInput);
				cInput.select();
				document.execCommand('copy');
				this.$message({
					type: 'succes',
					message: '复制成功'
				});
				document.body.removeChild(cInput);
			},
			//点击当前行
			showData(row) {
				this.getfindByRecordId(row.id);
			},
			handleClick(e) {
				console.log(e, '顶顶顶顶');
			},
			//用户详情
			getfindByRecordId(id) {
				let data = {
					id: id,
					currentUserId: localStorage.getItem('user')
				};
				findByRecordId(data).then(res => {
					this.drawer = true;
					this.customerDetails = res.data;
					this.form2 = res.data;
					console.log(this.form2, '我是什么');
					// this.form2.recordPath = res.data.recordPath.replace('/api/record/download?url=', 'https://yqt-record.oss-cn-beijing.aliyuncs.com/');
					// this.customerDetails.recordPath = res.data.recordPath.replace('/api/record/download?url=', 'https://yqt-record.oss-cn-beijing.aliyuncs.com/');
					this.CRMform.customerName = this.customerDetails.customerName;
					this.CRMform.phoneNumber = this.customerDetails.phoneNumber;
					this.activeName = 'callRecord';
					this.rowIndex = this.tableData.findIndex(item => item.id === this.customerDetails.id);
					if (this.rowIndex === 0) {
						this.prevDis = true;
						this.lastId = '';
						this.nextId = this.tableData[this.rowIndex].id;
					}
					if (this.rowIndex === this.tableData.length - 1) {
						this.nextDis = true;
						this.lastId = this.tableData[this.rowIndex].id;
						this.nextId = '';
					}
					if (this.tableData.length === 0) {
						this.prevDis = true;
						this.nextDis = true;
						this.lastId = '';
						this.nextId = '';
					}
				});
			},

			//转入crm
			showCRMVisible() {
				this.CRMVisible = true;

				if (this.query.companyId) {
					this.getwebListCustomize(this.query.companyId);
				} else {
					this.getwebListCustomize(localStorage.getItem('company'));
				}
			},
			//获取选择的
			getwebListCustomize(companyId) {
				let data = {
					companyId: companyId
				};
				getwebListCustomize(data).then(res => {
					if (res.code == 200) {
						this.customList = res.data;
						this.customList.forEach((i, index) => {
							console.log(this.customize, 'this.customize');
							this.customize[index] = '';
							i.multiSelectDropDownBox = []; //后台获取的时候 就加一个地方存选中的值的  多选的
							i.textVal = ''; //后台获取的时候 就加一个地方存选中的值的  单选
							if (i.options) {
								this.customList[index].options = Array.from(i.options, ({
									str
								}) => str);
							}
						});
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getAddIntentionCustomer() {
				this.customList.forEach((item, index) => {
					if (item.type == 'multi-select-drop-down-box') {
						if (item.multiSelectDropDownBox) {
							this.CRMform.customize[index] = item.multiSelectDropDownBox.toString();
						}
					} else {
						console.log(item, 'item');
						console.log(index, 'index');
						console.log(this.CRMform, 'this.CRMform');
						this.CRMform.customize[index] = item.textVal;
					}
				});

				this.CRMform.userId = localStorage.getItem('user');
				this.CRMform.customize = this.toObject(this.CRMform.customize); //变成对象
				this.CRMform.web = true;
				this.CRMform.normalName = this.form.normalName;
				if (this.role == 'normal') {
					this.CRMform.normalName = this.users[0].value;
				} else {
					if (this.form.normalName == '') {
						this.$message.error('请选择跟进人员');
						return;
					}
				}

				addIntentionCustomer(JSON.stringify(this.CRMform)).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.CRMVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			toObject(arr) {
				this.rv = {};
				for (let i = 0; i < arr.length; ++i) this.rv[i] = arr[i];
				return this.rv;
			},
			//上一个
			prev() {
				this.rowIndex--;
				this.lastId = this.tableData[this.rowIndex].id;

				this.getfindByRecordId(this.lastId);
				if (this.rowIndex-- === 0) {
					this.$message.warning('已经是第一条数据啦');
					this.prevDis = true;
				}
				this.nextDis = false;
			},
			// 下一个
			next() {
				this.rowIndex++;
				this.nextId = this.tableData[this.rowIndex].id;

				this.getfindByRecordId(this.nextId);

				if (this.rowIndex === this.tableData.length - 1) {
					this.$message.warning('已经是最后一条数据啦');
					this.nextDis = true;
				}
				this.prevDis = false;
			},

			getUpdateRecord() {
				let data = {
					id: this.form2.id,
					intention: this.form2.intention,
					customerName: this.form2.customerName,
					labels: this.form2.label.join(','),
					remark: this.form2.remark
				};
				console.log(this.query.label)
				updateRecord(data).then(res => {
					if (res.code == 200) {
						this.addVisible = false;
						this.getfindByRecordId(this.form2.id);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog(formName) {
				this.companyDis = false;
				this.userDis = false;
				(this.form = {
					id: '',
					intention: '',
					userName: '',
					remark: ''
				}),
				this.resetForm(formName);
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 获取数据
			uploadExcel() {
				this.upload = true;
			},

			getTime() {
				let startTime = new Date(new Date());
				let endTime = new Date();
				this.timeArr.push(startTime);
				this.timeArr.push(endTime);
			},
			getData() {
				this.loading = true;
				this.tableData = [];
				// 日期
				this.getLabel();
				if (this.timeArr === null) {
					this.$message.error('时间不能为空');
					return;
				}
				let beginDate = this.toDateTimeStr(this.timeArr[0]);
				let endDate = this.toDateTimeStr(this.timeArr[1]);

				this.query.beginDate = beginDate;
				this.query.endDate = endDate;
				this.query.currentUserId = localStorage.getItem('user');
				list(this.query).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
						this.query.label = '';
					} else {
						this.$message.error(res.message);
					}
				});
			},

			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}

				return y + '-' + m + '-' + d;
			},
			//下载
			downloadRecord() {
				console.log(this.customerDetails, '吾问无为谓无')
				let indexOf = this.customerDetails.recordPath.indexOf('=');
				let path = this.customerDetails.recordPath.substring(indexOf + 1);
				if (path === '') {
					this.$message.error('录音地址为空');
					return;
				}
				let date = new Date();
				let fileName = this.customerDetails.recordPath.substring(indexOf + 32);

				download(path).then(res => {
					let url = window.URL.createObjectURL(new Blob([res]), {
						type: 'application/mp3'
					});
					let link = document.createElement('a');
					link.style.display = 'none';
					link.href = url;
					link.setAttribute('download', fileName + '.mp3');
					document.body.appendChild(link);
					link.click();
				});
			},

			getcopy() {
				// console.log(this.customerDetails.recordPath.replace('/api/record/download?url=', 'https://yqt-record.oss-cn-beijing.aliyuncs.com/'), 'ddldl');
			},
			//修复
			repairRecord() {
				let data = {
					id: this.customerDetails.id
				};
				findRecordingPath(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.query.label = this.query.label.toString();

				this.getData();
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						delRecord(id).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.tableData.splice(index, 1);
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			exportData() {
				this.getLabel();
				this.query.currentUserId = localStorage.getItem('user');
				this.loading = true;
				recorddownloadRecord(this.query).then(res => {
					this.$message.success('导出中');
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '话务管理' + this.query.beginDate + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
					this.loading = false;
				});
				// } else {
				// this.$message.error('时间选择过多,请下载单日数据');
				// }
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 120px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.drawer-box .header {
		height: 45px;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #fff;
	}

	.drawer-box .header span {
		width: 60px;
		color: #000;
		font-weight: 500;
		font-size: 12px;
		line-height: 22px;
	}

	.drawer-box .user-info {
		display: block;
		background: #fff;
		border-radius: 4px;
		font-size: 12px;
		position: relative;
		margin: 16px;
	}

	.drawer-box .user-info .ivu-card-body {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.drawer-box .content .left .ivu-card .ivu-card-body {
		display: flex;
		padding: 16px;
	}

	.drawer-box .user-info .customer-id {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.drawer-box .user-info .id-font {
		font-size: 12px;
		margin-left: 8px;
		margin-right: 8px;
	}

	.drawer-box .content {
		display: flex;
		justify-content: space-between;
	}

	.drawer-box .content .left {
		width: 280px;
		display: flex;
		flex-direction: column;
	}

	.drawer-box .content .left .ivu-card {
		margin-bottom: 12px;
		display: block;
		background: #fff;
		border-radius: 4px;
		font-size: 12px;
		position: relative;
		transition: all 0.2s ease-in-out;
	}

	.drawer-box .content .left .ivu-card .ivu-card-head {
		border-bottom: 1px solid #e8eaec;
		padding: 14px 16px;
		line-height: 1;
	}

	.drawer-box .content .left .ivu-card .ivu-card-head p {
		display: inline-block;
		width: 100%;
		height: 20px;
		line-height: 20px;
		font-size: 16px;
		color: #17233d;
		font-weight: 500;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.drawer-box .content .left .ivu-card .ivu-card-extra {
		position: absolute;
		right: 16px;
		top: 14px;
		cursor: pointer;
	}

	.drawer-box .content .left .ivu-card .ivu-card-body .cell-item {
		display: flex;
		justify-content: space-between;
		padding: 7px 16px;
		font-size: 12px;
		cursor: pointer;
		user-select: none;
		transition: background-color 0.2s ease-in-out;
	}

	.drawer-box .content .left .ivu-card .ivu-card-body .cell-item:hover {
		background-color: #f4f4f4;
	}

	.drawer-box .content .left .ivu-card .ivu-card-body .cell-item .cell-title {
		min-width: 100px;
	}

	.drawer-box .content .left .ivu-card .ivu-card-body .action {
		transition: color 0.2s ease;
		font-size: 12px;
		line-height: 20px;
		user-select: none;
	}

	.drawer-box .content .left .ivu-card .ivu-card-body .disabledAction {
		color: #c5c8ce;
		transition: none;
		cursor: not-allowed;
		user-select: none;
	}

	.drawer-box .content .left .ivu-card .ivu-card-body .short-action {
		font-size: 12px;
		line-height: 20px;
		cursor: pointer;
		color: #2d8cf0;
	}

	.drawer-box .content .right {
		flex: 1;
		margin-left: 12px;
		background-color: #fff;
		padding-left: 12px;
	}

	.call-record-box {
		display: flex;
		flex-direction: row;
	}

	.call-record-box .call-left {
		margin-bottom: 12px;
		margin-right: 20px;
		padding: 12px 20px;
		border-radius: 3px;
		cursor: pointer;
		/* user-select: none; */
		font-size: 12px;
		color: #314659;
		line-height: 1.6;
		position: relative;
		width: 250px;

		overflow-y: auto;
		border-color: #2d8cf0;
		background-color: #e6f7ff;
	}

	.call-record-box .call-left .title {
		display: block;
		text-align: right;
		font-size: 12px;
	}

	.call-record-box .call-left .item-info {
		display: flex;
		margin-bottom: 6px;
	}

	.call-record-box .call-left .item-info .lat {
		min-width: 60px;
		margin-right: 16px;
	}

	.call-record-box .call-right {
		padding-left: 20px;
		border-left: 1px solid #dcdee2;
		display: flex;
		flex-direction: column;
		width: 500px;
	}

	.call-record-box .call-right audio {
		width: 500px;
	}

	.ivu-divider,
	.ivu-divider-vertical {
		margin: 0 8px;
		display: inline-block;
		height: 1em;
		width: 1px;
		vertical-align: middle;
		position: relative;
		top: -0.06em;
		background-color: #515a6e;
	}

	.icon-style {
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 12px;
		color: #666;
		cursor: pointer;
		margin: 20px 0;
	}

	.icon-style i {
		font-size: 16px;
		position: relative;
		top: 3px;
		left: -6px;
	}

	.query-list .el-form-item {
		margin-bottom: 10px !important;
	}

	.query-list .el-form-item:hover {
		background-color: #eff0f0;
		cursor: pointer;
	}

	.loyo-folding-btn:hover {
		border-color: #2c9dfc;
	}

	.loyo-folding-btn {
		position: relative;
		color: #4f7afd;
		height: 20px;
		text-align: center;
		border-top: 1px solid #e5e9f0;
		margin-top: 15px;
		cursor: pointer;
	}

	.loyo-folding-btn a {
		position: absolute;
		bottom: -1px;
		border: 1px solid #ddd;
		border-top-color: #fff;
		width: 60px;
		height: 20px;
		line-height: 20px;
	}

	.loyo-folding-btn a i {
		font-size: 18px;
		color: #ddd;
	}

	.loyo-folding-btn:hover i {
		color: #4f7afd;
	}

	.loyo-folding-btn:hover a {
		border: 1px solid #4f7afd;
		border-top-color: #fff;
	}


	.custom-data {
		display: inline-block;
		margin-top: 10px;
		margin-right: 10px;
	}

	.custom-data .label-name {
		width: 90px;
		line-height: 35px;
		font-weight: 500;
		margin-right: 10px;
	}

	.form-items-wrapper {
		display: flex;
		flex-wrap: wrap;
		/* 允许子元素换行 */
		justify-content: flex-start;
		/* 水平对齐方式（可选） */
		align-items: center;

	}

	.form-items-wrapper .el-form-item {
		/* 根据需要调整 el-form-item 的样式 */
		flex: 0 0 auto;
		/* 不放大也不缩小，但会占据空间 */
		margin: 5px 10px 5px 0;
		display: flex;
		flex-direction: row;
		/* 如果使用 gap 属性，则不需要这个 */
	}
</style>

<style scoped>
	.el-drawer {
		/* 	position: fixed !important;
	top: 165px !important;
	background-color: #f8f8f9; */

		height: calc(100% - 51px) !important;
		padding: 16px !important;
		font-size: 12px !important;
		line-height: 1.5 !important;
		word-wrap: break-word !important;
		position: absolute !important;
		top: 165px !important;
		overflow: auto !important;
	}
</style>