<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select
					size="small"
					v-if="isAdmin"
					@change="agentChange"
					v-model="query.agentId"
					filterable
					clearable
					placeholder="代理商"
					class="handle-select mr10"
					style="width: 260px"
					popper-class="eloption"
					:popper-append-to-body="true"
				>
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select
					size="small"
					v-if="isSubAgent"
					v-model="query.subAgentId"
					@change="getfetchDefaultCompanies"
					filterable
					clearable
					placeholder="子代理"
					class="handle-select mr10"
					style="width: 260px;"
				>
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select
					size="small"
					style="width: 260px"
					v-if="isAgent"
					v-model="query.companyId"
					@change="companyChange"
					filterable
					clearable
					placeholder="企业"
					class="handle-select mr10"
					popper-class="eloption"
					:popper-append-to-body="true"
				>
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch">搜索</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				header-cell-class-name="table-header"
				row-key="aid"
			>
				<el-table-column prop="" label="" width="80" align="left"></el-table-column>
				<el-table-column prop="companyName" width="360" label="公司"></el-table-column>
				<el-table-column prop="err" label="异常数量" align="center"></el-table-column>
				<el-table-column prop="total" label="总质检数" align="center"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[100, 300, 500, 1000]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchAgent } from '../api/agentIndex';
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
import { getCompanyStatistics, updateCompanyStatistics } from '../api/sumIndex';
import { querySubAgent } from '../api/serialIndex.js';
import { fetchDepartment, fetchUsersByDepartmentId, fetchDefaultUsers, riskList, riskRecordUpdate, qualityRecord } from '../api/index.js';
export default {
	name: 'report',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				userId: '',
				agentId: '',
				companyId: '',
				pageIndex: 1,
				pageSize: 30,
				subAgentId: ''
			},
			hasChildren: true,
			fileList: [],
			tableData: [],
			agents: [],
			companys: [],
			departments: [],
			users: [],
			multipleSelection: [],
			delList: [],
			showId: false,
			editVisible: false,
			addVisible: false,
			addButton: false,
			updateButton: false,
			isNotAdmin: true,
			isShow: false,
			isAgent: false,
			isSubAgent: false,
			isAdmin: false,
			isCompany: false,
			pageTotal: 0
		};
	},

	created() {
		let role = localStorage.getItem('ms_role');
		this.subAgent = localStorage.getItem('subAgent');
		if (role === 'admin') {
			this.isAdmin = true;
			this.isAgent = true;
			this.isNotAdmin = false;
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
		}

		if (role === 'agent') {
			this.isAgent = true;
			this.isNotAdmin = false;
			if (this.subAgent == 'true') {
				this.isSubAgent = true;
				this.getquerySubAgent();
			} else {
				this.isSubAgent = false;
			}
		}
		if (role === 'subAgent') {
			this.isAgent = true;
		}
		if (role === 'company') {
			this.isCompany = true;
			let data = {
				companyId: '',
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.getData();
			});
		}
		this.init();
	},
	methods: {
		getfetchDefaultCompanies() {
			this.query.companyId = '';
			let data = {
				currentUserId: localStorage.getItem('user'),
				subAgentId: this.query.subAgentId
			};
			fetchDefaultCompanies(data).then(res => {
				this.companys = res.data;
			});
		},
		getquerySubAgent() {
			let data = {
				agentId: this.query.agentId,
				userId: localStorage.getItem('user')
			};
			querySubAgent(data).then(res => {
				if (res.code == 200) {
					this.subAgentList = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
			if (this.subAgent == true) {
				this.isSubAgent = true;
				this.getquerySubAgent();
			}
			this.getfetchDefaultCompanies();
			fetchDefaultUsers(localStorage.getItem('user')).then(res => {
				this.users = res.data;
			});
		},
		agentChange(agentId) {
			this.query.agentId = agentId;
			this.query.companyId = '';
			this.query.userId = '';
			this.getData();
			if (agentId) {
				let data = {
					subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
					agentId: agentId
				};
				fetchCompanyByAgentId(data).then(res => {
					this.companys = res.data;
				});
			}
		},
		departmentChange(departmentId) {
			let data = {
				departmentId: departmentId,
				currentUserId: localStorage.getItem('user')
			};
			fetchUsersByDepartmentId(data).then(res => {
				this.users = res.data;
			});
		},
		/* 切换部门 */
		companyChange(companyId) {
			this.query.companyId = companyId;
			this.query.departmentId = '';
			this.query.userId = '';
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.query.pageIndex = 1;
				this.getData();
			});
		},
		getData() {
			this.query.userId = localStorage.getItem('user');
			qualityRecord(this.query).then(res => {
				console.log(res, 'sssssssssssss');
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},

		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},

		getUpdateCompanyStatistics() {
			updateCompanyStatistics(this.form).then(res => {
				if (res.code == 200) {
					this.$message.success('修改成功');
					this.editName = false;
					this.getData();
				} else {
					this.$message.error(res.message);
				}
				this.editName = false;
			});
		},

		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getData();
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover{background: #66b1ff} */
.handle-select {
	width: 120px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
